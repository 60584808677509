'use strict';

import {ILogService, IScope} from "angular";
import {AlarmMonitor, AlarmMonitorDefaultConfig, AlarmMonitorType} from "../../../../data/am4.data";
import RestService from "../../../../services/rest.service";
import {AMWEB_ENDPOINT} from './../../../../constants';
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import angular = require("angular");
import HelperService from "../../../../services/helper.service";
import { UserAccount } from "../../../../data/account.data";
import { Coords } from "../../../../data/objects.data";

require('./edit.AM4.modal.css');


/* @ngInject */
export default class EditAm4ModalController {
  public $scope: IScope;
  public $uibModalInstance: any;
  public restService: RestService;
  public helperService: HelperService;
  public $log: ILogService;
  public am: AlarmMonitor;
  public isLocal = false;
  public isEditMode = false;
  public isLoading = false;
  public isConnected = false;
  public copiedToClipboard = false;
  public listeners = [];
  public model: AlarmMonitor;
  public copyOfModel: any;
  public form: any;
  public mode: AlarmMonitorModes = AlarmMonitorModes.GENERAL;
  public randomPassword: string;

  public lat: number = 0;
  public lng: number = 0;
  public userAccount: UserAccount;

  public defaultConfig: AlarmMonitorDefaultConfig;
  public defaultConfigs: AlarmMonitorDefaultConfig[] = [];
  public priv: PrivilegeService;
  public hasAM4Edit:boolean= false;
  public hasAM4Create:boolean= false;
  public isEditingFieldsAllowed:boolean=false;

  public encryptionPasswordChanged: boolean = false;


  constructor(
    $scope: IScope,
    public $uibModal,
    $uibModalInstance, dataService, restService: RestService, helperService: HelperService, $log: ILogService, am: AlarmMonitor,
    privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.helperService = helperService;
    this.$log = $log;
    this.am = am;
    this.priv= privilegeService;

    // TODO (later): use default configs from server
    this.defaultConfigs.push({
      name: 'Standard',
      id: '1'
    });
    this.defaultConfig = this.defaultConfigs[0];

    this.userAccount = dataService.getAccount() as UserAccount;
    this.lat = this.userAccount.settings.lat;
    this.lng = this.userAccount.settings.lng;

    this.isEditMode = angular.isDefined(am);
    if (this.isEditMode) {
      if (am.mqtt) {
        this.isConnected = true;
      }
    }

    this.model = {
      mqtt: false,
      name: '',
      address: '',
      port: 5556,
      password: '',
      type: AlarmMonitorType.AMWEB,
      receiveStatus: false,
      receiveRoadBlocks: true,
      receiveMarker: false,
      lat: 0,
      lng: 0
    } as AlarmMonitor;

    if (!this.isEditMode) {
      this.isEditingFieldsAllowed= this.hasAM4Create;
      this.generatePassword(false);
    }

    this.initListeners();
  }
  checkEditAllowed(){
    this.hasAM4Edit= this.priv.has(RolePrivilege.Addressbook_AM4_Edit);
    this.hasAM4Create= this.priv.has(RolePrivilege.Addressbook_AM4_Create);
    if (this.isEditMode) {
      this.isEditingFieldsAllowed = this.hasAM4Edit;
    }else {
      this.isEditingFieldsAllowed = this.hasAM4Create;
    }
  }

  getAMwebLink(edit: boolean): string {
    if (edit) {
      return AMWEB_ENDPOINT + '/' + this.model.accessId + '/edit';
    } else {
      return AMWEB_ENDPOINT + '/' + this.model.accessId;
    }

  }


  initListeners() {
    this.checkEditAllowed()

    this.$scope.$watch("ctrl.am", (am: AlarmMonitor) => {
      // Init
      if (angular.isDefined(am)) {
        this.model = am;
        this.copyOfModel = angular.copy(this.model);
        this.isLocal = this.model.address === '127.0.0.1';
        this.setCoordinates();
        this.checkEditAllowed();
      }

    });
  }

  setCoordinates(): void {
    if (this.am.lat !== 0) {
      this.lat = this.am.lat;
      this.lng = this.am.lng;
    } else {
      this.lat = this.userAccount.settings.lat;
      this.lng = this.userAccount.settings.lng;
    }
  }

  cancel() {
    // Reset data
    if (angular.isDefined(this.copyOfModel)) {
      angular.forEach(this.copyOfModel, (value, key) => {
        this.model[key] = value;
      });
    }

    this.$uibModalInstance.close();
  }

  setLocal() {
    this.model.address = this.isLocal ? '127.0.0.1' : '';
    if (angular.isDefined(this.form)) {
      this.form.ip.$dirty = true;
      this.validateAddress();
    }
  }
  add() {
    // Custom validation
    if (!this.validateAddress()) {
      return;
    }

    if (this.model.type === AlarmMonitorType.AMWEB) {
      this.model.password = this.randomPassword;
    }

    if (this.lat === this.userAccount.settings.lat) {
      // Reset lat/lng to default to always use default settings if coordinates equals the Users home coordinates
      this.model.lat = 0;
      this.model.lng = 0;
    } else {
      this.model.lat = this.lat;
      this.model.lng = this.lng;
    }

    this.isLoading = true;
    this.restService.addAM4(this.model, this.defaultConfig,
      () => {
        this.isLoading = false;
        this.$uibModalInstance.close();
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });
  }

  save(closeWindow: boolean) {
    // Custom validation
    if (!this.validateAddress()) {
      return;
    }

    if (this.lat === this.userAccount.settings.lat) {
      // Reset lat/lng to default to always use default settings if coordinates equals the Users home coordinates
      this.model.lat = 0;
      this.model.lng = 0;
    } else {
      this.model.lat = this.lat;
      this.model.lng = this.lng;
    }


    if (this.model.type === AlarmMonitorType.AMWEB && this.randomPassword) {
      this.model.password = this.randomPassword;
    }

    this.isLoading = true;
    this.restService.editAM(this.model,
      () => {
        this.isLoading = false;
        if (closeWindow) {
          this.$uibModalInstance.close();
        }
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });
  }

  generatePassword(save: boolean) {
    this.randomPassword = this.helperService.generatePassword(20);
    if (save) {
      this.save(false);
      this.encryptionPasswordChanged = true;
      this.$scope.$applyAsync();
    }
  }

  /**
   * Copy the access ID to clipboard
   */
  copyToClipboard() {
    navigator.clipboard.writeText(this.model.accessId).then(() => {
      this.copiedToClipboard = true;
      this.$scope.$applyAsync();
    });
  }

  validateAddress() {
    let isValid = true;

    if (this.model.type === AlarmMonitorType.AMWEB) {
      return true;
    }

    if (this.model.address === '' && !this.model.mqtt) {
      this.form.ip.$setValidity('address', false);
      isValid = false;
    } else {
      this.form.ip.$setValidity('address', true);
    }
    return isValid;
  }

  /**
   * Choose a marker on the map
   */
  chooseOnMap() {
    //Update
    this.$uibModal.open({
      template: require('../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: this.lat,
            lng: this.lng,
            showOnMap: true
          } as Coords
        },
        position: () => {
          return undefined;
        },
        okFunction: () => {
          return (coords: Coords) => {
            this.lat = coords.lat;
            this.lng = coords.lng;
          }
        }
      }
    });
  }


}

export enum AlarmMonitorModes {
  GENERAL = 'GENERAL',
  AM4_SETTINGS = 'AM4_SETTINGS'
}
