import {IScope} from "angular";
import PrivilegeService from "../../../../services/privilege.service";

require('./mission.report.settings.component.scss')

export default class MissionReportSettingsComponent{
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'E'
    this.scope = {
      settings: "="
    }
    this.template = require('./mission.report.settings.component.html');

    this.controller = MissionReportSettingsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
class MissionReportSettingsComponentController{
  constructor(private $scope: IScope, public privilegeService: PrivilegeService) {
    
  }
}