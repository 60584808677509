import { IScope } from 'angular';
'use strict';

require('./calendar.view.component.css');
import { IRootScopeService } from 'angular';
import { RolePrivilege } from '../../../data/privileges.enum';
import PrivilegeService from '../../../services/privilege.service';

export default class CalendarController {
  public activeTab: number;
  private $rootScope: IRootScopeService;
  private $scope: IScope;
  public $state: any;
  public dataService: any;
  private listeners: any[] = [];

  constructor($rootScope: IRootScopeService, $scope: IScope, $state, dataService,
    public privilegeService: PrivilegeService) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.dataService = dataService;
    this.initListeners();
  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      this.init();
    }));
    this.listeners.push(this.$rootScope.$on('tab.change.calendar', (event, data) => {
      if (data >= 0 || data <= 1)
        this.activeTab = data;
    }));
  }

  init() {
    if (this.privilegeService.has(RolePrivilege.EventPlanning_Appointment)) {
      this.activeTab = 0;
    } else if (this.privilegeService.has(RolePrivilege.EventPlanning_Calendar)) {
      this.activeTab = 1;
    } else {
      this.$state.go('main.' + this.dataService.selectPageForPrivilege());
      return;
    }
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}