'use strict';

import { IScope } from "angular";
import { AAO } from "../../../../data/aao.data";
import { Keyword } from "../../../../data/alarm.data";
import RestService from "../../../../services/rest.service";

require('./add.keyword.to.aao.css');

/* @ngInject */
export default class AddKeywordToAAOModalController {
  public $scope: IScope;
  public $uibModal;
  public $uibModalInstance: any;
  public restService: RestService;
  public isLoading: boolean = false;
  private isProfiMode = false;

  public searchTerm = '';
  public keywordsAsString = '';
  public searchResult: Keyword[] = [];

  public selectedKeywords: Keyword[] = [];

  public aao: AAO;
  public okFunction;


  constructor($scope: IScope, $uibModalInstance, restService: RestService, aao: AAO, okFunction) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.aao = aao;
    this.okFunction = okFunction;
    this.keywordsAsString = aao.keywords.map(element => element.keyword).join('\n');
  }

  searchForKeyword() {
    this.isLoading = true;
    this.restService.getKeywords(this.searchTerm).then(result => {
      this.searchResult = result as Keyword[];
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  };

  save() {
    if (this.isProfiMode) {
      this.okFunction(this.keywordsAsString.split('\n').filter(el => el !== ''));
    } else {
      this.okFunction(this.selectedKeywords.map(entry => entry.ident));
    }
    this.$uibModalInstance.close();
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  /**
   * Add or deselect a keyword
   * @param keyword 
   */
  toggleKeyword(keyword: Keyword) {
    const isSelected = this.selectedKeywords.filter(k => k.ident === keyword.ident);
    if (isSelected.length === 0) {
      this.selectedKeywords.push(keyword);
    } else {
      this.selectedKeywords.splice(this.selectedKeywords.indexOf(isSelected[0]), 1);
    }
  }

  isSelected(keyword: Keyword): boolean {
    return this.selectedKeywords.filter(k => k.ident === keyword.ident).length > 0;
  }
}
