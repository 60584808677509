export interface DiaryEntry {
  creationTime: any;
  updateTime: any;
  payload: DiaryPayload;
  state: string;
  id: string;
  confidentiality: string;
  hasTask: boolean;
}

export interface DiaryPayload {
  message: string;
  confidentiality: EConfidentialState;
  type: ELagekarteDiaryType;
  priority: ELagekarteDiaryPriority;
  sourceId: string;
  sourceName: string;
  creationTime: string;
  id: string;
  data: DiaryPayloadData;
  system: string;
}

export interface LocationMapTask {
  id: string;
  state: ELocationMapTaskStatus;
  creationTime: any;
  updateTime: any;
  payload: LocationMapTaskPayload;
}

export interface LocationMapTaskPayload {
  status: ELocationMapTaskStatus;
  userId: string;
  locationMapId: string;
  externalId: string;
  userName: string;
  sourceId: string;
  sourceName: string;
  message: string;
  remark: string;
  sourceDiaryEntryId: string;
  inProgressDate: any;
  doneDate: any;
  declinedDate: any;
  archivedDate: any;
  /**
   * only in task list, not per queue entry
   */
  keyword: string;
  /**
   * only in task list, not per queue entry
   */
  location: string;
  withAutomaticFeedback: boolean;
}

export interface LocationMapTaskFilter {
  new: boolean;
  inProgress: boolean;
  done: boolean;
  archived: boolean;
  declined: boolean;
}

export enum ELocationMapTaskStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  DECLINED = 'DECLINED',
  DONE = 'DONE',
  ARCHIVED = 'ARCHIVED'
}

export interface DiaryPayloadData {
  filename: string;
}

export enum EConfidentialState {
  NONE = 'NONE',
  CONFIDENTIAL = 'CONFIDENTIAL'
}

export enum ELagekarteDiaryType {
  MESSAGE = 'MESSAGE',
  CLAIM = 'CLAIM',
  REPORT = 'REPORT',
  FINAL_REPORT = 'FINAL_REPORT',
  MAP_POINT = 'MAP_POINT',
  DANGEROUS_GOODS = 'DANGEROUS_GOODS',
  INTERNAL_MESSAGE = 'INTERNAL_MESSAGE',
  IMAGE_UPLOADED = 'IMAGE_UPLOADED',
  TASK_STATUS_CHANGE = 'TASK_STATUS_CHANGE'
}

export enum ELagekarteDiaryPriority {
  DEFAULT = 'DEFAULT',
  URGENT = 'URGENT'
}