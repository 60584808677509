'use strict';

/* @ngInject */
import { IRootScopeService, IScope } from "angular";
import { SimpleInputPluginInstance } from "../../../../data/input.data";
import AdminService from "../../../../services/admin.service";
require('./add.input.modal.scss')
export default class AddInputModalController {
  public $uibModalInstance: any;
  public okFunction: any;
  public isLoading = false;
  public isLoadingInputPlugins = false;
  public adminService: AdminService;

  private name: string;
  private inputPlugins: SimpleInputPluginInstance[] = [];
  private selectedInstance: SimpleInputPluginInstance;

  constructor($uibModalInstance, $scope: IScope, okFunction, adminService: AdminService) {
    this.$uibModalInstance = $uibModalInstance;
    this.adminService = adminService;
    this.okFunction = okFunction;

    // Load input plugins
    this.isLoadingInputPlugins = true;
    this.adminService.getAllAvailableInputPlugins().then(inputPlugins => {
      this.inputPlugins = inputPlugins;
      this.selectedInstance = this.inputPlugins[0];
    }).finally(() => {
      this.isLoadingInputPlugins = false;
      $scope.$applyAsync();
    });
  }

  getIcon(inputPlugin: SimpleInputPluginInstance) {
    if (!inputPlugin) return '';

    let iconCss = `icon-${inputPlugin.simpleInputClassName.replace(/.*\./, '')}`;
    return iconCss;
  }

  getHeaderIcon(): string {
    if (!this.selectedInstance) return '';
    const iconPath = `/img/static/input/${this.selectedInstance.simpleInputClassName.replace(/.*\./, '')}.png`;
    return iconPath;
  }

  ok() {
    this.isLoading = true;
    this.adminService.createInputPlugin(this.selectedInstance, this.name).then(() => {
      this.okFunction();
      this.$uibModalInstance.close();
    }).finally(() => {
      this.isLoading = false;
    });

  }
  cancel() {
    this.$uibModalInstance.close();
  }
}