'use strict';
import {BlockedAlarms, EBlockedReasonType} from "../../../../data/admin.data";
import {IScope} from "angular";

/* @ngInject */
export default class AdminBlockedAlarmsTableRowComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.blocked.alarms.tablerow.component.html');
    this.scope = {
      blocked:'='
    };
    this.controller = AdminBlockedAlarmsTableRowController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
require('./admin.blocked.alarms.tablerow.component.scss')
class AdminBlockedAlarmsTableRowController {
  public blocked: BlockedAlarms;
  public $scope: IScope;


  constructor($scope: IScope) {
    this.$scope = $scope;


  }
  isInfoLabel(){
    switch (this.blocked.blockedReasonType) {
      case EBlockedReasonType.DOUBLE_ALARM:
      case EBlockedReasonType.TOO_MANY_ALARMS:
        return true;
      default:
        return false;
    }

}
  isWarningLabel(): boolean{
    switch (this.blocked.blockedReasonType){
      case EBlockedReasonType.UNIT_DEACTIVATED:
      case EBlockedReasonType.NO_PIPELINE:
      case EBlockedReasonType.UNIT_NOT_FOUND:
        return true;
      default:
        return false;

    }
}

  isErrorLabel(): boolean{
    switch (this.blocked.blockedReasonType){
      case EBlockedReasonType.REVISION_MODE:
      case EBlockedReasonType.INVALID_LICENCE:
      case EBlockedReasonType.EXCEPTION:
        return true;
      default:
        return false;

    }
  }
  getDate(){
    if (this.blocked && this.blocked.date){
      return this.blocked.date;
    }
  }
}