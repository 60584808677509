'use strict';

import angular = require('angular');
export default class AvailabilityDayComponent {
	public restrict: any;
	public scope: any;
	public template: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        day: '=',
        threshold: '=',
        month: '=',
        kw: '='
      };
    this.template = require('./day.component.html');

    this.controller = AvailabilityDayController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityDay
/* @ngInject */
class AvailabilityDayController {
	public $scope: any;
	public $rootScope: any;
	public $log: any;
	public dataService: any;
	public isThisMonth: any;
	public day: any;
	public isSaturday: any;
	public month: any;
	public threshold: any;
	public isAvailable: any;

  constructor($scope, $rootScope, $log, dataService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.dataService = dataService;

    this.isThisMonth = true;

    this.$scope.$watch('ctrl.threshold', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
      //Watch for changes
      this.update();
    })

    this.$scope.$watch('ctrl.month', (month) => {
      if (angular.isDefined(this.day)) {
        var date = new Date(this.day.date);
        this.isSaturday = date.getDay() == 6;
        if (date.getMonth() !== (this.month - 1)) {
          this.isThisMonth = false;
        }
      }
    })

    if (angular.isUndefined(this.threshold)) {
      this.threshold = 50;
    }
  }

  update() {
    this.isAvailable = this.day.availabilityPercentage >= this.threshold;
  }
}