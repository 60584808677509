'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import PrivilegeService from "../../../../services/privilege.service";

require('./access.component.css');

export default class AvailabilityAccessComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '='
      }
    this.template = require('./access.component.html');

    this.controller = AvailabilityAccessController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityAccess
/* @ngInject */
class AvailabilityAccessController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $uibModal: any;
  public $log: ILogService;
  public dataService: any;
  public account: any;
  public listeners: any;
  public isLoading: any;
  public keys: any;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModal, $log: ILogService, dataService,
    public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.dataService = dataService;
    this.account = this.dataService.getAccount();

    this.listeners = [];

    this.initListeners();

    // Inital load
    this.load();

  }

  /**
   * Load api keys from server
   */
  load() {
    this.isLoading = true;
    //Init load
    this.dataService.getApiKeys(false, (resp) => {
      this.isLoading = false;
      this.keys = resp;
    }, (err) => {
      this.isLoading = false;
      this.$log.error(err);
    });
  };

  /**
   * Open Modal and show QR Code
   * @param {} apiKey 
   */
  showQrCode(apiKey) {
    this.$uibModal.open({
      template: require('../../../modals/availability/scan.qrcode.modal/scan.qrcode.modal.html'),
      controller: 'QrInstanceModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        apiKey: () => {
          return apiKey;
        }
      }
    });
  }

  /**
   * Edit existing api key
   */
  editApiKey(api) {
    this.$uibModal.open({
      template: require('../../../modals/availability/edit.api.key.modal/edit.api.key.modal.html'),
      controller: 'ApiModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        api: () => {
          return api;
        }
      }
    });
  }

  /**
   * Create api key
   */
  createApiKey(api) {
    this.$uibModal.open({
      template: require('../../../modals/availability/edit.api.key.modal/edit.api.key.modal.html'),
      controller: 'ApiModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        api: () => {
          return undefined;
        }
      }
    });
  }

  /**
   * Delete existing api key
   * @param {} api 
   */
  deleteApiKey(api) {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.dataService.deleteApiKey(api, (resp) => {
              this.isLoading = false;
              this.keys = resp;
            }, (err) => {
              this.isLoading = false;
            });
          }
        },
        additionalText: () => {
          return;
        }
      }
    });
  }

  initListeners() {
    this.listeners.push(this.$scope.$watch('ctrl.onlineservice', (nVal) => {
      if (!nVal) {
        return;
      }
    }));

    this.listeners.push(this.$rootScope.$on('new.account', (event, account) => {
      //Init controller
      this.account = this.dataService.getAccount();
    }))

    //Unregister listener
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}