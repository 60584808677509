import {IScope} from "angular";
import {PrivacyMappingResponse} from "../../../../data/admin.settings";

export default class AdminPrivacyReplacementTablerowComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      replacement: '='
    }
    this.template = require('./admin.privacy.replacement.tablerow.component.html');
    this.controller = AdminPrivacyReplacementTablerowController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class AdminPrivacyReplacementTablerowController{
  public $scope: angular.IScope;
  public replacement: PrivacyMappingResponse;

  constructor($scope:IScope) {
    this.$scope = $scope;
  }
}