'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { SmsUsage, SmsUsagePerUser } from "../../../../data/admin.data";
import RestService from "../../../../services/rest.service";

require('./admin.sms.component.css');

export default class AdminSMSComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./admin.sms.component.html');
    this.scope = {
    };
    this.controller = AdminSMSComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminSMSComponentController {
  public isLoading: boolean = false;
  public months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  public currentYear = (new Date()).getFullYear();
  public years: any;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public dataService: any;
  public restService: RestService;
  public $log: ILogService;
  public usage: SmsUsage;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, dataService, restService: RestService) {
    this.years = [this.currentYear, this.currentYear - 1];
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.dataService = dataService;
    this.restService = restService;
    this.$log = $log;
    this.load();
  }

  changeYear(year: number) {
    this.currentYear = year;
    this.load();
  }

  /**
   * Load all users
   */
  load() {
    this.isLoading = true;
    this.restService.loadSMSUsage(this.currentYear, (response) => {
      this.usage = response.data as SmsUsage;
      this.$log.debug(this.usage);
      this.isLoading = false;
    },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });
  };

  sumUp(user: SmsUsagePerUser) {
    var sum = 0;
    user.months.forEach(i => sum += i);
    return sum;
  }

}

