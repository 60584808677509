'use strict';

//startFrom
/* @ngInject */
export default class StartFromFilter {
  static filter(input, start) {
    if (angular.isUndefined(input)) {
      return input;
    }
    return input.slice(start);
  }
}
