import angular from 'angular';
import 'angular-ui-bootstrap'

import services from '../../../services'
import CalendarListController from './calendar.list.component/calendar.list.component';
import EventNotificationController from './events/modal/event.notification.component';
import CalendarController from './calendar.component/calendar.component';
import EventFeedbackController from './events/modal/event.feedback.component';

export default angular.module('FE2.components.calendar', ['ui.bootstrap', services])
  .directive('calendarList', () => new CalendarListController())
  .directive('calendar', () => new CalendarController())
  .directive('eventNotification', () => new EventNotificationController())
  .directive('eventFeedback', () => new EventFeedbackController())
  .name;
