'use strict';

import {IRootScopeService, IScope} from "angular";
import RestService from "../../../../services/rest.service";
import PrivilegeService from '../../../../services/privilege.service';
import {ELocationMapTaskStatus, LocationMapTask, LocationMapTaskFilter} from '../../../../data/locationmap.data';
import {RolePrivilege} from '../../../../data/privileges.enum';
import HelperService from '../../../../services/helper.service';

require('./queue.tasks.container.scss');

export default class QueueTasksContainerComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      queue: '='
    }
    this.template = require('./queue.tasks.container.html');

    this.controller = QueueTasksContainerComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class QueueTasksContainerComponentController {

  private listeners: any[] = [];
  public tasks: LocationMapTask[] = [];
  public selectedFilters: LocationMapTaskFilter = {} as LocationMapTaskFilter;
  public filterActive: boolean;
  public loadingTaskIds: string[] = [];

  constructor(public $scope: IScope,
    public $rootScope: IRootScopeService,
    public restService: RestService,
    public privilegeService: PrivilegeService,
    private helperService: HelperService, private Notification) {
      this.loadFromStorage();
      this.initListeners();
      this.getTasks();
  }

  loadFromStorage() {
    const v = this.helperService.getFromStorage('QUEUE.TASKS_STATUS_FILTER', {}) as LocationMapTaskFilter;
    this.selectedFilters = v;
    this.checkFilterActive();
  }

  initListeners() {

    this.listeners.push(this.$rootScope.$on('new.account', (event, account) => {
      this.getTasks();
    }));

    this.listeners.push(this.$rootScope.$on('queue.TASK_UPDATED', (event, data: LocationMapTask) => {
      if (this.tasks.length > 0) {
        const index = this.tasks.findIndex(task => task.id === data.id);
        this.tasks[index] = data;
        this.$scope.$applyAsync();
      }
    }));

    this.listeners.push(this.$rootScope.$on('LAGEKARTE_UPDATED', (event, data: LocationMapTask) => {
      this.getTasks();
    }));

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  saveStatusFilterOption(status: ELocationMapTaskStatus) {
    switch (status) {
      case ELocationMapTaskStatus.NEW:
        this.selectedFilters.new = !this.selectedFilters.new;
        break;
      case ELocationMapTaskStatus.IN_PROGRESS:
        this.selectedFilters.inProgress = !this.selectedFilters.inProgress;
        break;
      case ELocationMapTaskStatus.DONE:
        this.selectedFilters.done = !this.selectedFilters.done;
        break;
      case ELocationMapTaskStatus.DECLINED:
        this.selectedFilters.declined = !this.selectedFilters.declined;
        break;
      case ELocationMapTaskStatus.ARCHIVED:
        this.selectedFilters.archived = !this.selectedFilters.archived;
        break;

    }
    this.helperService.saveInStorage('QUEUE.TASKS_STATUS_FILTER', this.selectedFilters);

    this.checkFilterActive();

    this.getTasks();
  }

  private checkFilterActive() {
    const allActive =
      this.selectedFilters.new &&
      this.selectedFilters.inProgress &&
      this.selectedFilters.done &&
      this.selectedFilters.archived &&
      this.selectedFilters.declined;

    this.filterActive =
      !allActive && (this.selectedFilters.new ||
        this.selectedFilters.inProgress ||
        this.selectedFilters.done ||
        this.selectedFilters.archived ||
        this.selectedFilters.declined);
  }

  private getTasks() {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Tasks_Read)) {
      return;
    }

    let filter = '';

    if (this.selectedFilters.new) {
      filter += ELocationMapTaskStatus.NEW + ',';
    }
    if (this.selectedFilters.inProgress) {
      filter += ELocationMapTaskStatus.IN_PROGRESS + ',';
    }
    if (this.selectedFilters.done) {
      filter += ELocationMapTaskStatus.DONE + ',';
    }
    if (this.selectedFilters.declined) {
      filter += ELocationMapTaskStatus.DECLINED + ',';
    }
    if (this.selectedFilters.archived) {
      filter += ELocationMapTaskStatus.ARCHIVED + ',';
    }

    // strip last comma
    filter = filter.substring(0, filter.length - 1);

    this.restService.getAllTasksForQueues(filter).then(data => {
      this.tasks = data;
    }).finally(() => {
      this.loadingTaskIds = [];
      this.$scope.$applyAsync();
    });
  }

  updateTaskState(task: LocationMapTask, newState: ELocationMapTaskStatus) {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Tasks_Write)) {
      return;
    }
    if (this.loadingTaskIds.some(id => task.id === id)) {
      // entry is already being processed
      return;
    }
    this.loadingTaskIds.push(task.id);
    this.restService.updateTask(task.id, task.payload.locationMapId, newState).catch(error => {
      this.loadingTaskIds = [];
      this.$scope.$applyAsync();
      this.Notification.error({
        message: error
      });
    });
  }
}
