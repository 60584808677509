import angular = require("angular");
import { EAvailabilityRuleComparison, RuleValidationResult } from "../../../../data/availability.data";

require('./rule.entry.component.css');

export default class AvailabilityRuleEntryComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '=',
        rule: '=',
        calculate: '='
      }
    this.template = require('./rule.entry.component.html');

    this.controller = AvailabilityRuleEntryController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityRuleEntry
/* @ngInject */
class AvailabilityRuleEntryController {

  private listener = undefined;
  public status = {
    isWarning: false,
    isError: false,
    isInfo: false,
    isOk: false,
    isCalculated: false
  };

  public rule: RuleValidationResult;
  public onlineservice: any;
  public calculate: any;

  constructor(private $scope, private $rootScope, private $log, private $uibModal, private dataService) {

    this.$scope.$watch('ctrl.onlineservice', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
      //Watch for changes
      this.updateRule();
    });

    this.$scope.$watch('ctrl.rule', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }

      if (angular.isDefined(this.rule) && angular.isUndefined(this.listener)) {
        this.initListener();
      }

      //Watch for changes
      this.updateRule();
    });
  }

  //Register for rule change events
  initListener() {
    //Init 
    this.listener = this.$rootScope.$on('rule.updated.' + this.rule.id, (event, data) => {
      this.$log.debug('Rule changed - Rule Entry');
      this.rule = data;
      this.updateRule();
    });

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listener();
    });
  }

  /**
   * Check if rules are valid
   */
  updateRule() {
    if (this.calculate) {
      // Update UI flags

      this.status = {
        isWarning: !this.rule.valid && this.rule.rule.severity === 'WARNING',
        isError: !this.rule.valid && this.rule.rule.severity === 'CRITICAL',
        isInfo: !this.rule.valid && this.rule.rule.severity === 'INFO',
        isOk: this.rule.valid,
        isCalculated: true
      }

    } else {
      // No need for calculation
      this.status = {
        isWarning: false,
        isError: false,
        isInfo: false,
        isOk: false,
        isCalculated: false
      }
    }
  }

  public getGlobalGroupCheckGroups() {
    if (!this.rule.rule.globalGroupCheck) return [];
    return this.rule.rule.conditions
      .filter(condition => condition.comparison === EAvailabilityRuleComparison.GROUP)
      .map(condition => condition.comparisonSource);
  }
}