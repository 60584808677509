'use strict';

/* @ngInject */
export default class ConfirmClearDatabaseModalController {
  public $uibModalInstance: any;
  public okFunction: any;
  public selectedDayRange: string;

  constructor($uibModalInstance, okFunction, selectedDayRange: string) {
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
    this.selectedDayRange = selectedDayRange;
  }

  cancel() {
    this.$uibModalInstance.close();
  }
  ok() {
    this.$uibModalInstance.close();
    this.okFunction();
  }
}
