'use strict';

import { IRootScopeService, ILogService } from "angular";

require('./table.base.field.css');

export default class TableBaseFieldComponent {
  restrict: string;
  template: any;
  scope: any;
  controller: any;
  controllerAs: string;
  bindToController: boolean;
  constructor() {
    this.restrict = 'E';
    this.template = require('./table.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '='
    };
    this.controller = TableBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class TableBaseFieldController {
  $log: ILogService;
  $rootScope: IRootScopeService;
  constructor($rootScope: IRootScopeService, $log: ILogService) {
    this.$rootScope = $rootScope;
    this.$log = $log;
  }

  addParameter(param: string) {
    this.$log.info('Emitting add.parameter event with parameter ' + param);
    this.$rootScope.$broadcast('add.parameter.to.alarmtext', param);
  }
}
