'use strict';
import { MetaInformation } from './../../../../data/metaInformation.data';

import { IRootScopeService, IScope, ILogService } from "angular";
import RestService from "../../../../services/rest.service";
import { WacheTabs } from "../../../views/wache.view.component/wache.view.component";


require('./meta.component.css');

export default class MetaComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./meta.component.html');
    this.scope = {
    };
    this.controller = MetaComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class MetaComponentController {
  public restService: RestService;
  public $log: ILogService;
  public Notification;
  public listeners = [];
  public metaInformation: MetaInformation[] = [];

  public isLoading: boolean = false;
  public isAdmin: boolean = false;
  public $scope: IScope;


  constructor($log: ILogService, $scope: IScope, $rootScope: IRootScopeService, Notification, restService: RestService) {
    this.restService = restService;
    this.Notification = Notification;
    this.$log = $log;
    this.$scope = $scope;

    this.loadMeta();

    this.listeners.push($rootScope.$on('metainformation.change', (event, metaInformation: MetaInformation) => {
      const index = this.metaInformation.findIndex(value => value.id === metaInformation.id);
      if (index >= 0) {
        this.metaInformation[index] = metaInformation;
      } else {
        this.metaInformation.push(metaInformation);
      }
      this.metaInformation = this.metaInformation.sort((a, b) => a.source.localeCompare(b.source));
      this.$scope.$applyAsync();
    }));

    $scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  loadMeta() {
    this.isLoading = true;

    this.restService.getMeta().then(metaInformation => {
      this.isLoading = false;
      this.metaInformation = metaInformation.sort((a, b) => a.source.localeCompare(b.source));
    }).finally(() => {
      this.$scope.$apply();
    });
  };

  hasLocation(information: MetaInformation) {
    return information.lat !== null && information.lng !== null;
  }

  getStyle(information: MetaInformation) {
    if (information.color) {
      return {
        color: information.color
      }
    } else {
      return {
        color: 'transparent'
      }
    }
  }
}