'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import {AMobile, AMobileAddressbookResponse} from "../../../../data/amobile.data";
import RestService from "../../../../services/rest.service";
import {SortParams} from "../../../views/addressbook.view.component/addressbook.view.component";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./amobile.component.css');

export default class AmobileComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E',
      this.scope = {}
    this.template = require('./amobile.component.html');

    this.controller = AmobileController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//tableAmobile
/* @ngInject */
class AmobileController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public restService: RestService;
  public $log: ILogService;
  public $element: any;
  public $uibModal: any;
  public listeners: any;
  public isLoading = false;
  public data: AMobileAddressbookResponse;
  public priv:PrivilegeService;
  public hasAMob:boolean=false;
  public hasAMobCreate:boolean=false;

  public params: SortParams = {
    currentPage: 0,
    pageSize: 20
  } as SortParams;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $element, restService: RestService, $uibModal, privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$element = $element;
    this.restService = restService;
    this.$uibModal = $uibModal;
    this.priv= privilegeService;

    this.listeners = [];

    this.initListeners();
  }


  /**
   * Triggers an reload
   */
  reload() {
    this.params.currentPage = 0;
    this.pageChanged();
  }

  /**
   * Loads the entries paginated
   * @returns
   */
  pageChanged() {
    if (!this.hasAMob){
      return Promise.reject("Missing aMobile permission.");
    }
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadAMobiles(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.pageSize)
        .then((result: AMobileAddressbookResponse) => {
          this.data = result;
          this.params.totalPages = this.data.totalPages;
          this.params.totalElements = this.data.totalElements;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }

  /*
      Add a new AM instance to the addressbook
    */
  addAMobile() {
    this.$uibModal.open({
      template: require('../../../modals/addressbook/edit.amobile.modal/edit.amobile.modal.html'),
      controller: 'EditAmobileModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        aMobile: () => {
          return undefined;
        },
        okFunction: () => {
          return () => {
            this.params.currentPage = 0;
            this.pageChanged();
          };
        }
      }
    });
  };

  /*
    Select an AM to open the edit dialog
  */
  selectAMobile(aMobile: AMobile) {
    this.$uibModal.open({
      template: require('../../../modals/addressbook/edit.amobile.modal/edit.amobile.modal.html'),
      controller: 'EditAmobileModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        aMobile: () => {
          return aMobile;
        },
        okFunction: () => {
          return () => {
            this.params.currentPage = 0;
            this.pageChanged();
          };
        }
      }
    });
  };

  /*
    Delete an existing AM instance
  */
  deleteAMobile($event, aMobile: AMobile) {
    $event.stopPropagation();

    this.restService.deleteAMobileById(aMobile.id, () => {
      this.params.currentPage = 0;
      this.pageChanged();
    }, (errorResponse) => {
      this.$log.error(errorResponse);
    });
  };

  initListeners() {
    this.listeners.push(this.$rootScope.$on('addressbook.view.tab', (event, source) => {
      if (source === 3) {
        this.initPermissionBools();
        // Trigger reload
        this.params.currentPage = 0;
        this.pageChanged();
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
  initPermissionBools(){
    this.hasAMob= this.priv.has(RolePrivilege.Addressbook_aMobile);
    this.hasAMobCreate= this.priv.has(RolePrivilege.Addressbook_aMobile_Create);
  }
}
