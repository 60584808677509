import {Person} from "../../../../data/person.data";
import * as angular from "angular";
import {IRootScopeService, IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {AlarmGroupSimple} from "../../../../data/alarmgroup.data";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
require('./edit.person.alarm_groups.component.css');
export default class PersonAlarmGroupsComponent{
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./edit.person.alarm_groups.component.html');
    this.scope = {
      person: '='
    };
    this.controller = PersonAlarmGroupsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
class PersonAlarmGroupsController{

  public person:Person;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public restService:RestService;
  public isLoadingAllGroups: boolean;
  public isPersonsGroupsLoading:boolean;
  public isDirty:boolean;
  public isSaving:boolean;
  public allAlarmGroupsSimple: AlarmGroupSimple[];
  public alarmGroupsPersonSimple:AlarmGroupSimple[];
  public filteredGroups:AlarmGroupSimple[];
  public hasEditAlarmGr: boolean = false;
  public hasAlarmGr:boolean= false;
  public priv:PrivilegeService;
  public listeners=[];

  constructor($scope:IScope, $rootScope: IRootScopeService, restService:RestService, privilegeService:PrivilegeService) {
    this.$scope= $scope;
    this.$rootScope= $rootScope;
    this.restService = restService;
    this.isDirty = false;
    this.priv = privilegeService;

    this.listeners.push(this.$scope.$watch("ctrl.person", ((newValue:Person, oldValue) => {
      if (angular.isDefined(newValue)){
        this.person = newValue;
      }else if(angular.isDefined(oldValue)){
        this.person = oldValue;
      }
      if (angular.isDefined(this.person)){
        this.hasAlarmGr = this.priv.has(RolePrivilege.Addressbook_AlarmGroup);
        this.hasEditAlarmGr = this.priv.has(RolePrivilege.Addressbook_AlarmGroup_Edit);
        this.loadGroupsFromPerson(this.person.personID);
        this.loadAllGroups();
      }
    })));


    this.listeners.push(this.$scope.$on("person.saved", (event, person: Person) => {
      if (this.hasEditAlarmGr) {
        if (angular.isDefined(this.person) && angular.isDefined(person)) {
          if (this.person.personID === person.personID) {
            this.saveAlarmGroupsForPerson();
          }
        }
      }
    }))
    // Unregister
    this.$scope.$on('$destroy', () => {
      if (this.isDirty){
        this.$scope.$emit('alarmGroupsPersonChange')
      }
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

  }

  private loadAllGroups() {
    if (!this.hasAlarmGr){
      return;
    }
    this.isLoadingAllGroups = true;
    this.restService.loadAlarmGroupsSimple().then((alarmGroupsSimple)=>{
      this.allAlarmGroupsSimple = alarmGroupsSimple;
    }).finally(()=>{
      this.isLoadingAllGroups = false;
      this.$scope.$applyAsync();
    })

  }

  private loadGroupsFromPerson(personID:string) {
    if (!this.hasAlarmGr){
      return;
    }
    this.isPersonsGroupsLoading = true;
    this.restService.loadAlarmGroupsSimpleWithPerson(personID).then((alarmGroupsPerson)=>{
      this.alarmGroupsPersonSimple = alarmGroupsPerson;
    }).finally(()=>{
      this.isPersonsGroupsLoading = false;
      this.$scope.$applyAsync();
    });

  }

  isPersonInGroup(groupSimple:AlarmGroupSimple):boolean{
    if (angular.isUndefined(this.alarmGroupsPersonSimple)){
      return false;
    }
    return this.alarmGroupsPersonSimple.some(groupsPerson=>groupsPerson.id==groupSimple.id,1)
  }
  addGroupToPerson(groupSimple:AlarmGroupSimple){
    if (this.isPersonInGroup(groupSimple)){
      return;
    }
    this.alarmGroupsPersonSimple.push(groupSimple);
    this.isDirty=true;
  }

  removeGroupFromPerson(groupSimple:AlarmGroupSimple){
    const foundElement = this.alarmGroupsPersonSimple.filter(entry => entry.id === groupSimple.id);

    if (foundElement.length > 0) {
      this.alarmGroupsPersonSimple.splice(this.alarmGroupsPersonSimple.indexOf(foundElement[0]), 1);
      this.isDirty = true;

    }
  }

  saveAlarmGroupsForPerson(){
    if (angular.isUndefined(this.alarmGroupsPersonSimple)){
      return;
    }
    if(!this.isDirty){
      return;
    }
    this.isSaving = true;
    this.restService.saveAlarmGroupsForPerson(this.alarmGroupsPersonSimple, this.person.personID).then(()=>{
      this.isDirty = false;
      }
    ).finally(()=>{
      this.isSaving = false;
      this.$scope.$applyAsync();
    });
  }

  addAllVisible(){
    if (angular.isDefined(this.filteredGroups)){
      this.isPersonsGroupsLoading= true;
      this.filteredGroups.forEach(group=> this.addGroupToPerson(group));
      this.isPersonsGroupsLoading = false
    }

  }
}