'use strict';
import {IScope, IRootScopeService, ILogService} from "angular";
import RestService from "../../../../services/rest.service";
import {ProvisioningGeoFenceSelection} from "../../../../data/provisioning.data";
import * as angular from "angular";
import {ProvisioningGeoFenceEdit} from "../../../../data/geofence.data";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
require('./geofence.tablerow.component.scss')
export default class GeofenceTablerowComponent{
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.scope = {
      geofence: '='
    };
    this.template = require('./geofence.tablerow.component.html');

    this.controller = GeofenceTablerowController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
/* @ngInject */
class GeofenceTablerowController{
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public restService: RestService;
  public isLoading = false;
  public geofence: ProvisioningGeoFenceSelection;
  public $uibModal;
  public priv:PrivilegeService;
  public hasGeofenceDelete: boolean=false;

  constructor($scope: IScope,$rootScope: IRootScopeService, restService: RestService, $uibModal , $log:ILogService, privilegeService:PrivilegeService) {
    this.$scope = $scope;
    this.restService = restService;
    this.$rootScope = $rootScope;
    this.$uibModal= $uibModal;
    this.$log = $log;
    this.priv= privilegeService;


    this.$scope.$watch('ctrl.geofence', ((newValue:ProvisioningGeoFenceSelection, oldValue) => {
      if (angular.isDefined(newValue)){
        this.geofence = newValue;
        this.hasGeofenceDelete = this.priv.has(RolePrivilege.Addressbook_Geofences_Delete);
      }else if (angular.isDefined(oldValue)){
        this.geofence = oldValue;
        this.hasGeofenceDelete = this.priv.has(RolePrivilege.Addressbook_Geofences_Delete);
      }
    }));
  }

  deleteGeofence(){
    if (!this.hasGeofenceDelete){
      return;
    }
    this.isLoading= true;
    this.restService.deleteSingeleGeofence(this.geofence.id).then(()=>{
      this.$rootScope.$broadcast('delete.geofence', this.geofence);
    }).catch((error)=>{
      this.$log.debug(error);
    }).finally(()=>{
      this.isLoading = false;
    })

  }

  selectGeofence(){
    this.isLoading= true;
    this.restService.loadSingleGeofence(this.geofence.id).then((result)=>{
      this.isLoading= false;
      this.openModal(result);
    }).catch((error)=>{
      this.$log.debug(error);
    })

  }

  openModal(provisioningGeoFence: ProvisioningGeoFenceEdit){
    var instant = this.$uibModal.open({
      template: require('../../../modals/addressbook/edit.geofence.modal/edit.geofence.modal.html'),
      controller: 'EditGeofenceModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        provisioningGeofence: ()=> {
          return provisioningGeoFence;
        }
      }
    });

  }
}