import RestService from "../../../../../services/rest.service";
import {FeedbackUnitGroupSimple, FeedbackGroupsUnits} from "../../../../../data/admin.data";
import {IScope, IRootScopeService} from "angular";
import * as angular from "angular";
import {Unit} from "../../../../../data/unit.data";
require('./admin.feedbackGroups.settings.scss')
export default class AdminFeedbackGroupsSettings {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {}
    this.template = require('./admin.feedbackGroups.settings.html');
    this.controller = AdminFeedbackGroupsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
class AdminFeedbackGroupsComponentController {
  public isLoadingFeedbackGroups: boolean = false;
  public isLoadingFeedbackGroupDetails: boolean = false;
  public isLoadingUnits: boolean= false;
  public isDirty:boolean = false;
  public restService: RestService;
  public feedbackGroupsSimple: FeedbackUnitGroupSimple[];
  public unitsWithFeedback: Unit[];
  public feedbackGroupsUnits: FeedbackGroupsUnits;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $uibModal: any;
  public localSearchFilterAllUnits: string;

  constructor(restService: RestService,$scope: IScope, $rootScope: IRootScopeService,  $uibModal: any) {
    this.restService = restService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;

    this.loadFeedbackGroupsInitialList();

  }
  unitInFeedbackGroup(unit: string){
    if (angular.isUndefined(this.feedbackGroupsUnits)){
      return false;
    }
    return this.feedbackGroupsUnits.units.some(unitT=> unitT.id === unit);
  }

  addToFeedbackGroup(unit: Unit){
    if (!this.unitInFeedbackGroup(unit.id)){
      this.feedbackGroupsUnits.units.push(unit);
      this.isDirty= true;
    }
  }
  removeFromFeedbackGroup(unitID: string){
    const foundElement = this.feedbackGroupsUnits.units.filter(entry => entry.id=== unitID);

    if (foundElement.length > 0) {
      this.feedbackGroupsUnits.units.splice(this.feedbackGroupsUnits.units.indexOf(foundElement[0]), 1);
      this.isDirty = true

    }
  }
  deleteFeedbackGroup(feedbackUnitGroupSimple: FeedbackUnitGroupSimple){
    this.isLoadingFeedbackGroupDetails = true;
    this.restService.deleteFeedbackUnitGroup(feedbackUnitGroupSimple.id).then((result)=>{
      this.isLoadingFeedbackGroups = true;
      this.loadFeedbackGroupsInitialList();
    }).finally(()=>{
      this.isLoadingFeedbackGroupDetails = false;
      this.$scope.$applyAsync();
    })
  }

  isSearchFilterDefined(){
    if (angular.isDefined(this.localSearchFilterAllUnits)){
      return this.localSearchFilterAllUnits!==''
    }
  }

  loadUnitsWithFeedback() {
    if (angular.isDefined(this.localSearchFilterAllUnits) &&this.localSearchFilterAllUnits.length >= 3) {
      this.isLoadingUnits = true;
      this.restService.getAllUnitsWithFeedbackMatching(this.localSearchFilterAllUnits).then((response) => {
       this.unitsWithFeedback = response;
      }).finally(() => {
        this.isLoadingUnits = false;
        this.$scope.$applyAsync();
    })
  }else if(angular.isUndefined(this.localSearchFilterAllUnits)||this.localSearchFilterAllUnits.length === 0){
      this.unitsWithFeedback = undefined;
    }
  }
  addFeedbackGroup(){
    this.openFeedbackGroupModal(undefined);
  }
  openFeedbackGroupModal(feedbackGroupSimple: FeedbackUnitGroupSimple){
    this.$uibModal.open({
      template: require('../../../../modals/admin/create.edit.feedbackGroup.modal/create.edit.feedbackGroup.modal.html'),
      controller: 'SingleFeedbackGroupModalController',
      controllerAs: 'ctrl',
      size: 'md',
      backdrop: 'static',
      resolve: {
        feedbackGroupSimple: () => {
          return feedbackGroupSimple;
        },
        okFunction: () => {
          return (refresh) => {
            // do not reload if cancel was clicked (no changes to display)
            if (refresh) {
              // Refresh selection
              this.loadFeedbackGroupsList();
              this.loadAndSelectFeedbackGroup(feedbackGroupSimple)
            }
          }
        }
      }
    });
  }

  addAllVisible(){
    this.unitsWithFeedback.forEach(unit=> this.addToFeedbackGroup(unit));
  }
  loadAndSelectFeedbackGroup(feedbackGroupSimple: FeedbackUnitGroupSimple){
    if(angular.isDefined(feedbackGroupSimple) && angular.isDefined(feedbackGroupSimple.id)){
      // reload this group on edit existing
      this.selectFeedbackGroup(feedbackGroupSimple.id);
      this.loadUnitsWithFeedback();
    }else {
      // load initial on new created (e.g. first group) -> has as minor consequence every new group after will be selected as well
      this.loadFeedbackGroupsInitialList();
    }

  }

  selectFeedbackGroup(id:string){
    this.isLoadingFeedbackGroupDetails = true;
    this.feedbackGroupsUnits = undefined;
    this.restService.getUnitsForFeedbackGroup(id).then((result)=>{
      this.feedbackGroupsUnits = result;
    }).finally(()=>{
      this.isLoadingFeedbackGroupDetails= false;
      this.$scope.$applyAsync();
    })

  }

  saveFeedbackGroup(){
    if (angular.isUndefined(this.feedbackGroupsUnits)){
      return;
    }
    this.restService.updateFeedbackUnitGroup(this.feedbackGroupsUnits).then(()=>{
      this.isDirty= false;
      }
    ).finally(()=>{
      this.$scope.$applyAsync();
    })
  }
  loadFeedbackGroupsInitialList(){
    this.isLoadingFeedbackGroups = true;
    this.restService.loadAllFeedbackUnitGroups().then(result=>{
      this.feedbackGroupsSimple = result;
      if (angular.isDefined(this.feedbackGroupsSimple) && this.feedbackGroupsSimple.length > 0) {
        this.loadAndSelectFeedbackGroup(this.feedbackGroupsSimple[0]);
      }else {
        // all deleted-> reset unitFeedbackSelection (strange side effect otherwise on re-adding new group without reload)
        this.feedbackGroupsUnits = undefined;
      }
    }).finally(()=>{
      this.isLoadingFeedbackGroups = false;
      this.$scope.$applyAsync();
    })
  }

  loadFeedbackGroupsList(){
    this.isLoadingFeedbackGroups = true;
    this.restService.loadAllFeedbackUnitGroups().then(result=>{
      this.feedbackGroupsSimple = result;
    }).finally(()=>{
      this.isLoadingFeedbackGroups = false;
      this.$scope.$applyAsync();
    })
  }

}