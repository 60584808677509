'use strict';

import { IRootScopeService, IScope, IWindowService } from "angular";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import { EFinishMode, EMoveMode, EQueueEntryState, MoveToQueueRequest, QueueEntryResponse, SimpleQueueResponse, UpdateQueuePriorityRequest, UpdateQueueStateRequest } from "../../../../data/queue.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import {StatusEntryResponse} from "../../../../data/vehicles.data";
import angular = require("angular");

require('./queue.row.entry.scss');

export default class QueueRowEntryComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A';
    this.template = require('./queue.row.entry.html');
    this.scope = {
      entry: '=',
      queues: '='
    };
    this.controller = QueueRowEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  };
};
class QueueRowEntryComponentController {
  public entry: QueueEntryResponse;
  public queues: SimpleQueueResponse[] = [];
  public isLoading: boolean = false;
  public hasQueueEdit: boolean= false;
  private listeners = [];


  constructor(private $rootScope: IRootScopeService, private $uibModal: any, private $scope: IScope, private $http: any, private $window: IWindowService, private privilegeService: PrivilegeService, private restService: RestService) {
    this.$scope.$on('$destroy', () => {
      this.listeners.forEach((listener) => {
        listener();
      });
    });
    this.hasQueueEdit = privilegeService.has(RolePrivilege.Alarm_Queues_Edit);

    this.$scope.$watch('ctrl.entry', () => {
      // add listener after timeout because otherwise this.entry is undefined
      if (angular.isDefined(this.entry?.connectedVehicleId)) {
        this.listeners.push(this.$rootScope.$on(`status.change.${this.entry.connectedVehicleId}`, (event, data: StatusEntryResponse) => {
          if (this.entry) {
            this.entry.status = data.status;
            this.entry.statusColor = data.color;
            this.entry.statusTextColor = data.textColor;
            this.$scope.$applyAsync();
          }
        }));
      }
    });
  }

  changePriority(newPriority: number): void {
    this.restService.updateQueueEntryPriority(this.entry.id, {
      priority: newPriority
    } as UpdateQueuePriorityRequest).then(result => {
      this.entry.priority = result.priority;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  assign(): void {
    this.isLoading = true;
    this.restService.updateQueueEntryState(this.entry.id, {
      state: EQueueEntryState.IN_PROGRESS,
      queueId: this.entry.queueId
    } as UpdateQueueStateRequest);
  }

  export(): void {
    this.$window.open(
      this.restService.getBaseUrl() +
      `/queues/entries/${this.entry.id}/export?Authorization=${this.$http.defaults.headers.common.Authorization}`, '_blank');
  }

  done(mode: EFinishMode): void {
    this.isLoading = true;
    this.restService.updateQueueEntryState(this.entry.id, {
      state: EQueueEntryState.DONE,
      finishMode: mode,
      queueId: this.entry.queueId
    } as UpdateQueueStateRequest);
  }

  move(newQueueId: string): void {
    this.isLoading = true;
    this.restService.moveQueueEntryState(this.entry.id, {
      newQueueId: newQueueId,
      mode: EMoveMode.MOVE
    } as MoveToQueueRequest);
  }

  openMap() {
    if (!this.entry.alarmData.lat) {
      return;
    }

    this.$uibModal.open({
      template: require('../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: this.entry.alarmData.lat,
            lng: this.entry.alarmData.lng,
            accuracy: 0
          }
        },
        position: () => {
          return undefined;
        },
        okFunction: () => {
          return (coords) => {
            // Nothing to do
          }
        }
      }
    });
  }
}
