'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { AddressBookOverViewData } from "../../../../data/overview.data";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./overview.component.css');


//tableOverview
export default class OverviewComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./overview.component.html');
    this.scope = {};
    this.controller = OverviewComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class OverviewComponentController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public restService: RestService;
  public $log: ILogService;
  public listeners = [];
  public addressbookOverview: AddressBookOverViewData;
  private params = {
    sortType: 'displayName', // set the default sort type
    currentPage: 0,
    totalElements: 0,
    pageSize: 20
  } as SortParams;
  public isLoading = false;
  public priv:PrivilegeService;
  public hasOverview:boolean= false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, restService: RestService, privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.restService = restService;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.priv= privilegeService;

    this.listeners.push(this.$rootScope.$on('addressbook.view.tab', (event, source) => {
      if (source === 6) {
        this.hasOverview= this.priv.has(RolePrivilege.Addressbook_Overview);
        // Load persons
        this.pageChanged();
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
 * Change the page
 * @returns A promise which resolves after changing the page
 */
  pageChanged() {
    if (!this.hasOverview){
      return Promise.reject("No Overview permission.");
    }
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadOverview(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1)
        .then((result: AddressBookOverViewData) => {
          this.addressbookOverview = result;
          this.params.totalElements = this.addressbookOverview.totalElements;
          this.params.totalPages = this.addressbookOverview.totalPages;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }

}