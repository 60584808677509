'use strict';

import {IScope} from 'angular';
import {BaseField} from '../../../../data/basefield.data';

require('./user.base.field.css');

export default class UserBaseFieldComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./user.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '=',
      units: '='
    };
    this.controller = UserBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class UserBaseFieldController {
  public users: string[] = [];
  public field: BaseField;
  public isDerivedFromTemplate: boolean;

  constructor($scope: IScope) {
    this.users = [];
    $scope.$watch('ctrl.units', (newValue: string[], oldValue) => {
      this.users = [];
      if (newValue) {
        this.users = Object.keys(newValue).sort();
        this.users.unshift(...['---'])
      }
    });
  }

  /**
   * Returns true if the given unit is selected
   * @param {user} user
   */
  isSelected(user: string) {
    for (var i = 0; i < this.field.value.length; i++) {
      if (this.field.value[i] === user) {
        return true;
      }
    }
    return false;
  }

  /**
   * Select a given unit
   * @param {unit} unit
   */
  select(user: string) {
    if (this.isDerivedFromTemplate) {
      return;
    }
    if (this.isSelected(user)) {
      //Remove
      var indexToDelete = -1;
      for (var i = 0; i < this.field.value.length; i++) {
        if (this.field.value[i] === user) {
          indexToDelete = i;
          break;
        }
      }
      if (indexToDelete > -1) {
        this.field.value.splice(indexToDelete, 1);
      }
    } else {
      //Add
      this.field.value.push(user);
    }
  }
}
