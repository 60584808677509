import angular from 'angular';
import 'angular-ui-bootstrap'
import services from '../../../services'
import QueueContainerComponentController from './queue.container/queue.container';
import QueueFinishedContainerComponentController from './queue.finished.container/queue.finished.container';
import QueueEntryComponentController from './queue.entry/queue.entry';
import QueueRowEntryComponentController from './queue.row.entry/queue.row.entry';
import QueueFeedbackEntryComponentController from './queue.feedback.entry/queue.feedback.entry';
import QueueTaskEntryComponentController from './queue.task.entry/queue.task.entry';
import QueueDetailMenuComponentController from './queue.detail.menu/queue.detail.menu';
import QueueTasksContainerComponentController from './queue.tasks.container/queue.tasks.container';

export default angular.module('FE2.components.queue', ['ui.bootstrap', services])
  .directive('queueContainer', () => new QueueContainerComponentController)
  .directive('queueEntry', () => new QueueEntryComponentController)
  .directive('queueFinishedContainer', () => new QueueFinishedContainerComponentController)
  .directive('queueRowEntry', () => new QueueRowEntryComponentController)
  .directive('queueFeedbackEntry', () => new QueueFeedbackEntryComponentController)
  .directive('queueTaskEntry', () => new QueueTaskEntryComponentController)
  .directive('queueDetailMenu', () => new QueueDetailMenuComponentController)
  .directive('queueTasksContainer', () => new QueueTasksContainerComponentController)

  .name;
