'use strict';

import { IScope } from "angular";
import { BaseField } from "../../../../data/basefield.data";
import { EUnitType, Unit } from "../../../../data/unit.data";

require('./units.base.field.scss');

export default class UnitsBaseFieldComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./units.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '=',
      units: '='
    };
    this.controller = UnitsBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class UnitsBaseFieldController {
  public users: string[] = [];
  public field: BaseField;
  public searchFilter: string = '';
  public units: Map<string, Unit[]>;
  private unitTypeFilter: EUnitType;
  public selectedFilter: boolean;
  public isDerivedFromTemplate: boolean;

  constructor($scope: IScope) {
    this.users = [];
    $scope.$watch('ctrl.units', (newValue, oldValue) => {
      this.users = [];
      if (newValue) {
        this.users = Object.keys(newValue);
      }
    });

    $scope.$watch('ctrl.field', (newValue, oldValue) => {
      if (newValue) {
        if (this.field.filter) {
          this.unitTypeFilter = this.field.filter as EUnitType;
        }
      }
    });
  }

  resetSearchAndReload(): void {
    this.searchFilter = '';
  }

  toggleSelectedFilter() {
    this.selectedFilter = !this.selectedFilter;
  }

  shouldBeDisplayed(userName: string, unit: Unit): boolean {
    if (this.unitTypeFilter && unit.type !== this.unitTypeFilter) {
      // filter for unit type
      return false;
    }


    if (!this.unitTypeFilter) {
      switch (unit.type) {
        case EUnitType.AVAILABILITY_RULE:
        case EUnitType.QUEUE:
        case EUnitType.SAMMEL:
        case EUnitType.WACHALARM_POST:
        case EUnitType.WACHALARM_PRE:
        case EUnitType.STATUS:
          return false;
      }
    }

    if (this.selectedFilter && !this.isSelected(unit)) {
      return false;
    }


    if (!this.searchFilter) {
      return true;
    }
    const searchFilterLowercase = this.searchFilter.toLowerCase();
    if (userName.toLowerCase().includes(searchFilterLowercase)) return true;
    if (unit.name.toLowerCase().includes(searchFilterLowercase)) return true;
    if (unit.code.toLowerCase().includes(searchFilterLowercase)) return true;
    return false;
  }


  /**
   * Returns true if the given unit is selected
   * @param {unit} unit
   */
  isSelected(unit: Unit) {
    for (var i = 0; i < this.field.value.length; i++) {
      if (this.field.value[i] === unit.code) {
        return true;
      }
    }
    return false;
  }

  /**
   * Select a given unit
   * @param {unit} unit
   */
  select(unit: Unit) {
    if (this.isDerivedFromTemplate) {
      return;
    }
    if (this.isSelected(unit)) {
      //Remove
      var indexToDelete = -1;
      for (var i = 0; i < this.field.value.length; i++) {
        if (this.field.value[i] === unit.code) {
          indexToDelete = i;
          break;
        }
      }
      if (indexToDelete > -1) {
        this.field.value.splice(indexToDelete, 1);
      }
    } else {
      //Add
      this.field.value.push(unit.code);
    }
  }
}
