'use strict';

//humanReadableTime
/* @ngInject */
export default class HumanReadableTimeFilter {
  static filter(time) {
    if (time === undefined) {
      return 'Unbekannt';
    }
    var now = (new Date()).getTime();
    var diff = now - new Date(time).getTime();
    diff = diff / 1000;

    if (diff > (30 * 60)) {
      return (new Date(time)).toLocaleTimeString();
    } else {

      var sec = Math.round(diff);
      var min = Math.round(diff / 60);
      var hour = Math.round(min / 60);
      var days = Math.round(hour / 24);

      //Days
      if (days !== 0) {
        if (days === 1) {
          return 'Vor einem Tag';
        } else {
          if (days > 365) {
            return 'Vor einem Jahr oder länger';
          } else {
            return 'Vor ' + days + ' Tagen';
          }

        }
      }


      //Hours
      if (hour !== 0) {
        if (hour === 1) {
          return 'Vor einer Stunde';
        } else {
          return 'Vor ' + hour + ' Stunden';
        }
      }

      //Minutes
      if (min !== 0) {
        if (min === 1) {
          return 'Vor einer Minute';
        } else {
          return 'Vor ' + min + ' Minuten';
        }
      }

      //Seconds
      if (sec !== 0) {
        if (sec === 1) {
          return 'Vor einer Sekunde';
        } else {
          return 'Vor ' + sec + ' Sekunden';
        }
      }
    }
    return (new Date(time)).toLocaleTimeString();
  }
}