'use strict';

import {ILogService, IScope, IWindowService} from "angular";
import RestService from "../../../../../services/rest.service";
import { StatusDefinition } from "../../../../../data/status.data";
import { EVehicleStatus } from "../../../../../data/vehicles.data";
import ErrorService from "../../../../../services/error.service";
import { EMapDisplayMode } from "../../../../../data/amobile.data";
import {StatusSettings} from "../../../../../data/admin.data";

require("./admin.status.settings.scss");

export default class AdminStatusSettingsComponent {
    public restrict: string;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
        this.restrict = 'E';
        this.template = require('./admin.status.settings.html');
        this.scope = {};
        this.controller = Controller;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class Controller {

    public statusDefinitions: StatusDefinition[];
    public isLoadingImport = false;
    private translationMapping: Map<EVehicleStatus, string> = new Map<EVehicleStatus, string>();
    private colorMapping: Map<EVehicleStatus, string> = new Map<EVehicleStatus, string>();
    private textColorMapping: Map<EVehicleStatus, string> = new Map<EVehicleStatus, string>();
    private statusSettings: StatusSettings;
    private isLoadingSettings=false;

    constructor(private readonly $scope: IScope,
        private $log: ILogService,
        private readonly restService: RestService,
        private readonly $window: IWindowService,
        private readonly errorService: ErrorService,
        private readonly $uibModal: any,
        private readonly FileUploader) {

        this.loadData();
    }

    private async loadData() {
        try {
            this.translationMapping = await this.restService.getStatusTranslationMapping();
            this.colorMapping = await this.restService.getStatusColorMapping();
            this.textColorMapping = await this.restService.getStatusTextColorMapping();
            this.loadStatusSettings();

            this.statusDefinitions = await this.restService.getStatusDefinitions();
            this.patchData();
            this.sortDefinitions();
            this.$scope.$applyAsync();
        } catch (error) {
            this.errorService.notifyWithText(error.data.message);
        }
    }

    loadStatusSettings(){
      this.isLoadingSettings = true;
      this.restService.getStatusSettings().then(settings => {
        this.statusSettings = settings;
      }).catch(err => {
        this.$log.error(err);
      }).finally(() => {
        this.isLoadingSettings = false;
        this.$scope.$applyAsync();
      });
    }

  saveStatusSettings() {
    this.isLoadingSettings = true;
    this.restService.saveStatusSettings(this.statusSettings).then(settings => {
      this.statusSettings = settings;
    }).catch(err => {
      this.$log.error(err);
    }).finally(() => {
      this.isLoadingSettings = false;
      this.$scope.$applyAsync();
    });
  }

    private patchData() {
        this.statusDefinitions.forEach(definition => {
            if (!definition.color) {
                definition.color = this.colorMapping[definition.status];
            }
            if (!definition.textColor) {
                definition.textColor = this.textColorMapping[definition.status];
            }
            if (!definition.translation) {
                definition.translation = this.translationMapping[definition.status];
            }
        })
    }

    private sortDefinitions() {
        this.statusDefinitions.sort((a, b) => a.status.localeCompare(b.status));
    }

    public getStyle(statusDefinition: StatusDefinition) {
        return {
            "color": statusDefinition.textColor,
            "background-color": statusDefinition.color
        };
    }

    public export() {
        this.$window.open(this.restService.getStatusDefinitionExportUrl(), '_blank');
    }

    public async import() {
        var modalInstance = this.openImportModal('/admin/settings/status/csv');
        this.isLoadingImport = true;
        try {
            await modalInstance.result;
            this.loadData();
        } catch (e) {
            if (e !== "backdrop click") {
                throw e;
            }
        } finally {
            this.isLoadingImport = false;
        }
    }

    openImportModal(url: string): any {
        return this.$uibModal.open({
            template: require('./../../../../modals/misc/import.modal/import.modal.html'),
            controller: 'ImportModalController',
            controllerAs: 'ctrl',
            size: 'sm',
            resolve: {
                uploader: () => {
                    let fileUploader = new this.FileUploader();
                    return fileUploader;
                },
                url: () => {
                    return url;
                }
            }
        });
    }


}