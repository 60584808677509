'use strict';

import {IScope} from "angular";
import {AAOCondition, AAOConditionCheckMode, AAOEntryResponseElement} from "../../../../data/aao.data";
import PrivilegeService from "../../../../services/privilege.service";

require('./aao.conditions.css');

/* @ngInject */
export default class AAOConditionsModalController {
  public $uibModalInstance: any;
  public $scope: IScope;
  public entry: AAOEntryResponseElement;
  public okFunction: Function;

  // Validation
  public isEndInvalid = false;
  public isStartInvalid = false;


  constructor($scope: IScope, $uibModalInstance, entry: AAOEntryResponseElement, okFunction: Function, public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.entry = entry;
    this.okFunction = okFunction;
  }

  addCondition() {
    this.entry.conditions.push({
      value: '',
      field: this.entry.defaultConditionField,
      checkMode: AAOConditionCheckMode.EQUALS
    });
  }

  removeCondition(condition: AAOCondition) {
    const index = this.entry.conditions.indexOf(condition);
    if (index > -1) {
      this.entry.conditions.splice(index, 1);
    }
  }

  public setAaoCheckMode(condition: AAOCondition, checkMode: AAOConditionCheckMode) {
    condition.checkMode = checkMode;
    this.$scope.$applyAsync();
  }

  close() {
    this.$uibModalInstance.close();
  }

  save() {
    this.okFunction();
    this.$uibModalInstance.close();
  }

  isNotValid(): boolean {
    for (let i = 0; i < this.entry.conditions.length; i++) {
      let condition = this.entry.conditions[i];
      if (condition.value === '' || condition.field === '') return true;
    }
    return false;
  }


}
