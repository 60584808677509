import {IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {OnlineServiceMapping} from "../../../../data/admin.data";
import * as angular from "angular";
import {BaseField} from "../../../../data/basefield.data";

export default class OnlineServiceBaseFieldComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./onlineService.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '='
    };
    this.controller = OnlineServiceBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class OnlineServiceBaseFieldController {
  public restService: RestService;
  public $scope: IScope;
  public onlineService: any[];
  public selectedService: OnlineServiceMapping;
  public field:BaseField;

  constructor($scope: IScope, restService: RestService) {
    this.restService= restService;
    this.$scope= $scope;

    this.restService.loadOnlineServicesForBaseField().then((success)=> {
      this.onlineService = success
      this.findService(this.field.value);
    });


  }
  getDisplayValue(){
    if (angular.isUndefined(this.selectedService)){
      return '---';
    }
    return this.selectedService.name;
  }

  selectService(option: any){
    this.field.value= option.apiKeyReadOnly;
    this.selectedService = option;
  }

  findService(apiKeyReadOnly: any){
    this.selectedService= this.onlineService.find(service=> service.apiKeyReadOnly === apiKeyReadOnly);
  }
}