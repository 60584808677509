'use strict'

import { AlarmObject } from "../../../../data/objects.data";

require("./home.emergency.component.scss");

export default class HomeEmergencyComponent {
    public restrict: any;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: any;
    public bindToController: any;

    constructor() {
        this.restrict = 'E'
        this.template = require('./home.emergency.component.html');
        this.scope = {
            alarmObject: "=",
            isEditable: "="
        };
        this.controller = HomeEmergencyComponentController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class HomeEmergencyComponentController {

    public isEditable: boolean;
    public alarmObject: AlarmObject;
    
    constructor() {

    }
}