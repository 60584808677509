'use strict';

import { IDocumentService, ILogService, IScope } from "angular";
import { AlarmObject } from "../../../../data/objects.data";
import HelperService from "../../../../services/helper.service";
import RestService from "../../../../services/rest.service";
import * as L from 'leaflet';
import PrivilegeService from "../../../../services/privilege.service";
import { RolePrivilege } from "../../../../data/privileges.enum";

require('./edit.poi.modal.css');

/* @ngInject */
export default class EditPOIController {
  public $uibModalInstance: any;
  public $uibModal: any;
  public dataService: any;
  public $scope: IScope;
  public restService: RestService;
  public helperService: HelperService;
  public mapService: any;
  public $log: ILogService;
  public types = ['SOLID', 'DOTTED', 'DASHED', 'DOT_DASH'];
  public poi: any;
  public alarmObject: AlarmObject;
  public settings: any;
  public isReadOnly = false;
  public isPolygon = false;
  public hasStroke = false;
  public strokeOpacity: any;
  public fillOpacity: any;
  public selectedStartIcon: any;
  public selectedEndIcon: any;
  public pointList = [];
  public map: any;
  public currentRouteShape: any;
  public currentMarker: any;
  public drawnItems: any;
  public drawControl: any;
  public currentStartMarker: any;
  public currentEndMarker: any;
  public isLoading = false;
  public assets: any;
  public isLoadingImageAssets = false;


  constructor($uibModalInstance, $uibModal, $scope: IScope, $log: ILogService, $document: IDocumentService, dataService, mapService,
    helperService: HelperService, restService: RestService, poi, alarmObject: AlarmObject,
    public editable: boolean,
    public privilegeService: PrivilegeService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.$scope = $scope;
    this.restService = restService;
    this.helperService = helperService;
    this.mapService = mapService;
    this.$log = $log;
    this.poi = poi;
    this.alarmObject = alarmObject;
    this.settings = this.dataService.getAccount().settings;
    this.isReadOnly = poi.parent !== this.dataService.getAccount().username;
    this.strokeOpacity = poi.pathStrokeOpacity * 100;
    this.fillOpacity = poi.fillOpacity * 100;
    this.isPolygon = poi.pathType === 'POLYGON';
    this.selectedStartIcon = undefined;
    this.selectedEndIcon = undefined;



    if (this.privilegeService.has(RolePrivilege.Objects_Markers_Edit) || this.privilegeService.has(RolePrivilege.Objects_Database_Markers_Edit)) {
      this.isReadOnly = false;
    } else {
      this.isReadOnly = true;
    }

    if (!this.editable) {
      // Modal is opened in read only mode
      this.isReadOnly = true;
    }

    $document.ready(() => {

      L.Icon.Default.imagePath = '/img/static';

      // Center map
      if (this.alarmObject && this.alarmObject.address) {
        // Center map on alarm object
        var coords = this.alarmObject.address.coords;
        this.map = L.map('mapid').setView([coords.lat, coords.lng], 17);
      } else {
        this.map = L.map('mapid').setView([this.settings.lat, this.settings.lng], 13);
      }

      this.currentRouteShape = undefined;
      this.currentMarker = undefined;
      this.initLayers();


      // FeatureGroup is to store editable layers
      this.drawnItems = new L.FeatureGroup();
      this.map.addLayer(this.drawnItems);
      this.drawControl = new L.Control.Draw({
        draw: {
          polyline: {
            tooltip: {
              start: 'Klicken um eine Linie zu zeichnen',
              cont: 'Klicken um die Linie weiter zu zeichnen',
              end: 'Auf den letzten Punkt klicken um die Zeichnung zu beenden.'
            }
          },
          circle: false,
          rectangle: false,
          polygon: true,
          marker: true,
          circlemarker: false
        },
        edit: {
          featureGroup: this.drawnItems
        }
      });
      if (!this.isReadOnly) {
        this.map.addControl(this.drawControl);
      }

      // Map events
      // Line is created
      this.map.on('draw:created', (layer) => {
        if (layer.layerType === 'marker') {
          var geoJson = layer.layer.toGeoJSON();
          this.drawnItems.addLayer(layer.layer);
          var coor = geoJson.geometry.coordinates;
          this.poi.pathShape = [{
            lat: coor[1],
            lng: coor[0],
            showOnMap: true
          }];
          this.poi.pathType = 'POINT';
          this.$scope.$apply();
        } else if (layer.layerType === 'polygon') {
          var geoJson = layer.layer.toGeoJSON();
          this.drawnItems.addLayer(layer.layer);
          this.poi.pathShape = [];
          for (var i = 0; i < geoJson.geometry.coordinates.length; i++) {
            var coorArr = geoJson.geometry.coordinates[i];
            for (var ii = 0; ii < coorArr.length; ii++) {
              var coor = coorArr[ii];
              this.poi.pathShape.push({
                lat: coor[1],
                lng: coor[0],
                showOnMap: true
              });
            }
          }
          this.poi.pathType = 'POLYGON';
          this.$scope.$apply();
        } else {
          var geoJson = layer.layer.toGeoJSON();
          this.drawnItems.addLayer(layer.layer);
          this.poi.pathShape = [];
          for (var i = 0; i < geoJson.geometry.coordinates.length; i++) {
            var coor = geoJson.geometry.coordinates[i];
            this.poi.pathShape.push({
              lat: coor[1],
              lng: coor[0],
              showOnMap: true
            });
          }
          this.poi.pathType = 'LINE';
          this.$scope.$apply();
        }

      });

      // User starts drawing
      this.map.on('draw:drawstart ', (layer) => {
        // Remove current route shape
        if (this.currentRouteShape !== undefined) {
          this.drawnItems.removeLayer(this.currentRouteShape);
          this.currentRouteShape = undefined;
        }
        if (this.currentMarker !== undefined) {
          this.drawnItems.removeLayer(this.currentMarker);
          this.currentMarker = undefined;
        }
        this.map.removeLayer(this.drawnItems);
        this.drawnItems = new L.FeatureGroup();
        this.map.addLayer(this.drawnItems);

      });

      // User edits current shape
      this.map.on('draw:edited ', (e) => {
        var layers = e.layers;
        layers.eachLayer((layer) => {
          var geoJson = layer.toGeoJSON();
          this.poi.pathShape = [];
          for (var i = 0; i < geoJson.geometry.coordinates.length; i++) {
            var coor = geoJson.geometry.coordinates[i];
            this.poi.pathShape.push({
              lat: coor[1],
              lng: coor[0],
              showOnMap: true
            });
          }
          this.$scope.$apply();
        });

      });
      // User deletes shape
      this.map.on('draw:deleted', () => {
        this.poi.pathShape = [];
        this.$scope.$apply();
      });

      // Set alarm object icon on map
      if (this.alarmObject && this.alarmObject.address) {
        // Center map on alarm object
        var coords = this.alarmObject.address.coords;
        var p = new L.LatLng(coords.lat, coords.lng);
        L.marker(p).addTo(this.map);
      }

      // Update image asset and start inital drawing
      this.updateImageAssets();
    });
  }


  /**
   * Updating drawing
   */
  updateDrawing() {

    if (this.currentRouteShape !== undefined) {
      this.drawnItems.removeLayer(this.currentRouteShape);
      this.currentRouteShape = undefined;
    }
    if (this.currentMarker !== undefined) {
      this.drawnItems.removeLayer(this.currentMarker);
      this.currentMarker = undefined;
    }
    this.poi.pathStrokeOpacity = this.strokeOpacity / 100;
    this.poi.fillOpacity = this.fillOpacity / 100;
    this.hasStroke = this.poi.pathShape.length > 1;
    if (this.poi.pathType === 'POINT') {
      // Only a single marker
      var point = this.poi.pathShape[0];
      if (!point) {
        return;
      }
      var p = new L.LatLng(point.lat, point.lng);

      if (this.selectedStartIcon) {
        // Own icon
        var icon = L.icon({
          iconUrl: this.createImageUrl(this.selectedStartIcon),
          iconSize: this.poi.startIconDimension,
          iconAnchor: [this.poi.startIconDimension[0] / 2, this.poi.startIconDimension[1] / 2]
        });
        this.currentMarker = L.marker(p, {
          icon: icon
        }).addTo(this.map);
      } else {
        // Default icon
        this.currentMarker = L.marker(p).addTo(this.map);
      }

      this.drawnItems.addLayer(this.currentMarker);
    } else if (this.poi.pathType === 'LINE') {
      // Polyline
      this.pointList = [];
      for (var i = 0; i < this.poi.pathShape.length; i++) {
        var point = this.poi.pathShape[i];
        var p = new L.LatLng(point.lat, point.lng);
        this.pointList.push(p);
      }
      if (this.pointList.length > 0) {
        this.currentRouteShape = new L.polyline(this.pointList, {
          color: this.poi.pathStrokeColor,
          weight: this.poi.pathStrokeWeight,
          opacity: this.poi.pathStrokeOpacity,
          smoothFactor: this.poi.smoothFactor,
          dashArray: this.helperService.getDashArray(this.poi.pathStyle, this.poi.pathStrokeWeight)
        });
        this.drawnItems.addLayer(this.currentRouteShape);
        this.map.fitBounds(L.latLngBounds(this.pointList));
      }
      this.updateStartAndEndMarker();
    } else if (this.poi.pathType === 'POLYGON') {
      // Polygon
      this.pointList = [];
      for (var i = 0; i < this.poi.pathShape.length; i++) {
        var point = this.poi.pathShape[i];
        var p = new L.LatLng(point.lat, point.lng);
        this.pointList.push(p);
      }
      if (this.pointList.length > 0) {
        this.currentRouteShape = new L.polygon(this.pointList, {
          color: this.poi.pathStrokeColor,
          weight: this.poi.pathStrokeWeight,
          opacity: this.poi.pathStrokeOpacity,
          fillColor: this.poi.fillColor,
          fillOpacity: this.poi.fillOpacity,
          fillRule: 'evenodd',
          smoothFactor: this.poi.smoothFactor,
          dashArray: this.helperService.getDashArray(this.poi.pathStyle, this.poi.pathStrokeWeight)
        });
        this.drawnItems.addLayer(this.currentRouteShape);
        this.map.fitBounds(L.latLngBounds(this.pointList));
      }
      this.updateStartAndEndMarker();
    }
  };

  initLayers() {
    this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');

    let layers = this.mapService.getBaseLayers();

    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService
    this.map.on('baselayerchange', function (e) {
      mapService.saveLayer(e.name);
    });

  }

  /**
   * Select new path stroke style
   */
  selectPathStyle(pathStyle) {
    this.poi.pathStyle = pathStyle;
    this.updateDrawing();
  };

  /**
   * Place start and end marker on map
   */
  updateStartAndEndMarker() {
    // Add marker
    if (this.currentStartMarker) {
      // remove from map first
      this.drawnItems.removeLayer(this.currentStartMarker);
      this.currentStartMarker = undefined;
    }

    if (this.currentEndMarker) {
      // remove from map first
      this.drawnItems.removeLayer(this.currentEndMarker);
      this.currentEndMarker = undefined;
    }

    if (this.selectedStartIcon && this.pointList.length >= 1) {
      // Start
      var p = this.pointList[0];
      var icon = L.icon({
        iconUrl: this.createImageUrl(this.selectedStartIcon),
        iconSize: this.poi.startIconDimension,
        iconAnchor: [this.poi.startIconDimension[0] / 2, this.poi.startIconDimension[1] / 2]
      });
      this.currentStartMarker = L.marker([p.lat, p.lng], {
        icon: icon
      }).addTo(this.map);
      this.drawnItems.addLayer(this.currentStartMarker);
    }

    if (this.selectedEndIcon && this.pointList.length > 1) {
      // End
      var p = this.pointList[this.pointList.length - 1];
      var icon = L.icon({
        iconUrl: this.createImageUrl(this.selectedEndIcon),
        iconSize: this.poi.endIconDimension,
        iconAnchor: [this.poi.endIconDimension[0] / 2, this.poi.endIconDimension[1] / 2]
      });
      this.currentEndMarker = L.marker([p.lat, p.lng], {
        icon: icon
      }).addTo(this.map);
      this.drawnItems.addLayer(this.currentEndMarker);
    }
  }


  cancel() {
    this.$uibModalInstance.close();
  };


  /**
   * Save object
   */
  save() {
    this.isLoading = true;
    this.dataService.updatePOI(this.poi, (poi) => {
      this.poi = poi;
      this.isLoading = false;
      this.$uibModalInstance.close();
    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.isLoading = false;
    });
  };


  /**
   * Delete object
   */
  delete() {
    this.$uibModal.open({
      template: require('./../../misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.dataService.deletePOI(this.poi,
              () => {
                this.isLoading = false;
                this.$uibModalInstance.close();
              },
              (response) => {
                //Error occured
                this.isLoading = false;
                this.$log.error(response);
              });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  };

  /**
   * Remove existing image asset
   */
  removeAsset(isStartAsset) {
    if (isStartAsset) {
      this.poi.startIcon = '';
      this.poi.startIconDimension = [];
      this.selectedStartIcon = undefined;
    } else {
      this.poi.endIcon = '';
      this.poi.endIconDimension = [];
      this.selectedEndIcon = undefined;
    }
    this.updateDrawing();
  }

  /**
   * Select a image asset
   */
  selectAsset(isStartAsset, asset) {
    if (isStartAsset) {
      this.poi.startIcon = asset.id;
      this.poi.startIconDimension = [asset.width, asset.height];
      this.selectedStartIcon = asset;
    } else {
      this.poi.endIcon = asset.id;
      this.poi.endIconDimension = [asset.width, asset.height];
      this.selectedEndIcon = asset;
    }
    this.updateDrawing();
  };

  /**
   * Create a image URL for image asset
   */
  createImageUrl(asset) {
    return this.restService.getBaseUrl() + '/objects/icons/' + asset.id + '?Authorization=' + this.restService.getAuthHeader();
  };

  /**
   * Get the asset name
   */
  getAssetName(iconImg) {
    if (!iconImg || iconImg === '') {
      return undefined;
    }
    for (var i = 0; i < this.assets.length; i++) {
      var asset = this.assets[i];
      if (asset.id === iconImg) {
        return asset;
      }
    }
    return undefined;
  };

  /**
   * Update image assets
   */
  updateImageAssets() {
    this.isLoadingImageAssets = true;
    this.dataService.getAllImageAssets(false, (assets) => {
      this.assets = assets;
      this.$log.info('Image Assets: ' + assets.length);
      this.isLoadingImageAssets = false;

      this.selectedStartIcon = this.getAssetName(this.poi.startIcon);
      this.selectedEndIcon = this.getAssetName(this.poi.endIcon);
      this.updateDrawing();
    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.isLoadingImageAssets = false;
    });
  };
}
