'use strict';

/* @ngInject */
export default class ChangeStringModalController {
  private $uibModalInstance;
  private str: string;
  private okFunction: Function;
  private required: boolean;

  constructor($uibModalInstance, str: string, okFunction: Function, required: boolean) {
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
    this.str = str;
    this.required = required;
  }

  valid() {
    if (this.required) {
      return this.str !== undefined && this.str.trim().length > 0
    } else {
      return true;
    }
  }

  cancel() {
    this.$uibModalInstance.close();
  }
  save() {
    this.$uibModalInstance.close('cancel');
    this.okFunction(this.str);
  }
}