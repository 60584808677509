'use strict';

import {IScope} from "angular";
import {OutageSettings, OutageSystem} from "../../../../../data/admin.data";
import RestService from "../../../../../services/rest.service";

require('./admin.outage.component.scss');

export default class AdminOutageSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.outage.component.html');
    this.scope = {};
    this.controller = AdminOutageComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminOutageComponentController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;

  private settings: OutageSettings;

  constructor($scope: IScope, restService: RestService
  ) {
    this.restService = restService;
    this.$scope = $scope;
    this.isLoading = true;
    this.restService.loadOutageSettings().then(result => {
      this.settings = result;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    })
  }


  save(): void {
    this.isLoading = true;
    this.restService.updateOutageSettings(this.settings).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }


}