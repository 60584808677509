'use strict';

import angular = require('angular');
export default class ObjectBuildingsComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.buildings.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectBuildingsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectBuildingsComponentController {
	public $uibModal: any;
	public alarmObject: any;
	public isEditable: any;

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  add() {
    var r = {
      name: '',
      hint: '',
      floors: ''
    }
    if (angular.isUndefined(this.alarmObject.buildings)) {
      this.alarmObject.buildings = [];
    }
    this.alarmObject.buildings.push(r);
  };

  delete(r) {
    this.alarmObject.buildings.splice(this.alarmObject.buildings.indexOf(r), 1);
  };


  /**
   * Show on map
   * @param {*} element
   */
  showOnMap(building) {
    if (this.isEditable) {
      if (angular.isUndefined(building.coords)) {
        building.coords = {
          lat: this.alarmObject.address.coords.lat,
          lng: this.alarmObject.address.coords.lng,
          showOnMap: true
        }
      }

      this.$uibModal.open({
        template: require('./../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
        controller: 'ChooseOnMapCtrl',
        controllerAs: 'ctrl',
        size: 'lg',
        resolve: {
          coords: () => {
            return building.coords;
          },
          okFunction: () => {
            return (coords) => {
              building.coords = coords;
            }
          }
        }
      });
    };
  }
}
