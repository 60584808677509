import angular from 'angular';
import 'angular-ui-bootstrap'

import services from '../../../services'
// import modals from '../../modals';
import ApagerSummaryComponent from "./apager.summary.component/apager.summary.component";
import ApagerSummaryTablerowComponent from "./apager.summary.tablerow.component/apager.summary.tablerow.component";

export default angular.module('FE2.components.apager', ['ui.bootstrap', services])
  .directive("apagerSummary", () => new ApagerSummaryComponent())
  .directive("apagerSummaryRow", () => new ApagerSummaryTablerowComponent())
  .name;
