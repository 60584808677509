'use strict'

import { AlarmObject } from "../../../../data/objects.data";

require("./object.examinations.component.scss");

/* @NgInject */
export default class ObjectExaminationsComponent {
    public restrict: string;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
        this.restrict = 'A'
        this.template = require('./object.examinations.component.html');
        this.scope = {
          alarmObject: '='
        };
        this.controller = ObjectExaminationsComponentController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
      }

}

class ObjectExaminationsComponentController {

    public alarmObject: AlarmObject;

    constructor() {}

}