import ErrorService from "../../../../services/error.service";
import { ILogService, IRootScopeService, IScope, IWindowService} from "angular";
import RestService from "../../../../services/rest.service";
import * as angular from "angular";

require('./import.organisation.modal.css')
export class ImportOrganisationModalController{
  public $uibModalInstance: any;
  public uploader: any;
  public isLoading: boolean = false;
  public errorService: ErrorService;
  public $log: ILogService;
  public $http: any;
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $window: IWindowService;

  constructor($scope:IScope, $rootScope: IRootScopeService, $uibModalInstance,uploader,errorService: ErrorService, $log: ILogService, $http, restService:RestService, $window:IWindowService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.$rootScope= $rootScope;
    this.uploader = uploader;
    this.errorService = errorService;
    this.$log = $log;
    this.$http = $http;
    this.restService = restService;
    this.$window = $window;

    this.uploader.onCompleteAll=  ()=>{
      this.$log.info('onCompleteAll');
      this.$uibModalInstance.close();
    }
    this.uploader.onBeforeUploadItem = (item)=>{
      this.$log.info("import");
      item.url = this.restService.getBaseUrl() + '/admin/users/upload'
    };

    this.uploader.onSuccessItem = (item: any, response: string, status: any, headers: any)=>{
      if (angular.isUndefined(response) || response ===''){
        this.$uibModalInstance.close();
        return;
      }
      this.createDownloadFile(response);
    }

    this.uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    }
  }

  private createDownloadFile(response) {
    let binaryData = [];
    binaryData.push(response);
    // create temporary download link manually --> open window way not possible for post responses
    let blob = new Blob(binaryData, {type: 'text/csv;charset=cp1252'});
    const url =this.$window.URL.createObjectURL(blob);

    this.$window.open(url, '_blank');
    // inform browser to delete the reference to the file

    setTimeout(()=>{
      // wait a while for revoking url (e.g. firefox pop up download window etc.)
      this.$window.URL.revokeObjectURL(url);
    }, 30*1000)
  }

  cancel(){
    this.$uibModalInstance.close();
  }

  import(){
    this.isLoading = true;
    this.uploader.onErrorItem = (item, response, status) =>{
      this.errorService.notifyWithText(response.message);
    };
    this.uploader.uploadAll();
  }
}