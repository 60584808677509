'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import PrivilegeService from "../../../../services/privilege.service";

require('./rules.component.css');

export default class AvailabilityRulesComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '='
      }
    this.template = require('./rules.component.html');

    this.controller = AvailabilityRulesController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AvailabilityRulesController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $uibModal: any;
  public dataService: any;
  public listeners: any;
  public rules: any;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $uibModal, dataService,
    public privilegeService: PrivilegeService) {

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.dataService = dataService;

    this.listeners = [];
    this.rules = [];

    this.initListeners();

    // Load for the first time
    this.dataService.getAvailabilityRules(false, (rules) => {
      // Filter for not valid rules only
      this.rules = rules;
    }, (err) => {
      this.$log.error(err);
    });
  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('rules.updated', (event, data) => {
      this.rules = data;
    }));


    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
  /**
   * Open to edit
   * @param  rule 
   */
  open(rule) {
    this.$uibModal.open({
      template: require('../../../modals/availability/rules.modal/rules.modal.html'),
      controller: 'RulesModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        rule: () => {
          return rule;
        }
      }
    });
  }

  /**
   * Add a new availability rule
   */
  add() {
    this.$uibModal.open({
      template: require('../../../modals/availability/rules.modal/rules.modal.html'),
      controller: 'RulesModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        rule: () => {
          return undefined;
        }
      }
    });
  }
}