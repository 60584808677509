'use strict';
//objectSubobjects
export default class ObjectSubObjectsComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.subobjects.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectSubObjectsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectSubObjectsComponentController {
	public $scope: any;
	public $rootScope: any;
	public $log: any;
	public $uibModal: any;
	public dataService: any;
	public alarmObject: any;
	public isEditable: any;

  constructor($scope, $rootScope, $uibModal, $log, dataService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
  }

  delete(subObject) {
    // Confirm delete
    this.$uibModal.open({
      template: require('../../../modals/objects/confirm.delete.sub.object.modal/confirm.delete.sub.object.modal.html'),
      controller: 'ConfirmDeleteSubObjectCtrl',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return (keepInDb) => {
            this.$rootScope.isLoading = true
            this.dataService.deleteSubObject(this.alarmObject.id, subObject.id, keepInDb, () => {
              this.$rootScope.isLoading = false;
              this.alarmObject.subObjects.splice(this.alarmObject.subObjects.indexOf(subObject), 1);
            }, (response) => {
              //Error occured
              this.$log.error(response);
              this.$rootScope.isLoading = false;
            });
          }

        }
      }
    });
  };

  /**
   * Edit sub object
   */
  editSubObject(subObject) {
    // Open new 
    if (this.isEditable) {
      this.$uibModal.open({
        template: require('../../../modals/objects/edit.alarm.object.modal/edit.alarm.object.modal.html'),
        controller: 'EditAlarmObjectCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'hu',
        resolve: {
          alarmObjectId: () => {
            return subObject.id;
          },
          alarmObject: () => {
            return undefined;
          }
        }
      });
    }
  };


  /**
   * Add a new alarm object
   */
  addSubObject() {
    this.$uibModal.open({
      template: require('../../../modals/objects/add.object.modal/add.object.modal.html'),
      controller: 'AddAlarmObjectCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (name) => {
            this.$rootScope.isLoading = true;
            this.dataService.addSubObject(name, this.alarmObject.id, (alarmObject) => {
              this.$rootScope.isLoading = false;

              this.alarmObject.subObjects.push({
                name: alarmObject.name,
                id: alarmObject.id
              });

              // Open new 
              this.$uibModal.open({
                template: require('../../../modals/objects/edit.alarm.object.modal/edit.alarm.object.modal.html'),
                controller: 'EditAlarmObjectCtrl',
                controllerAs: 'ctrl',
                backdrop: 'static',
                size: 'hu',
                resolve: {
                  alarmObject: () => {
                    return alarmObject;
                  },
                  alarmObjectId: () => {
                    return alarmObject.id;
                  }
                }
              });
            }, (response) => {
              //Error occured
              this.$log.error(response);
              this.$rootScope.isLoading = false;
            });

          }
        }
      }
    });
  };


}
