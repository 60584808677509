import {IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {BaseField, BaseFieldType} from "../../../../data/basefield.data";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

export default class OsFunctionGroupDropdownFieldComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./osfunctiongroup.dropdown.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '=',
    };
    this.controller = OsfunctiongroupDropdownFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class OsfunctiongroupDropdownFieldController {
  public restService: RestService;
  public $scope: IScope;
  public availabilityData: any;
  public field:BaseField;
  public selectableGroupsFunctions:string[];
  public functionsAndGroupsDefault:string= "---";

  constructor($scope: IScope, restService: RestService, dataService: any, public privilegeService: PrivilegeService) {
    this.restService= restService;
    this.$scope= $scope;

    if (dataService.hasAccount()) {
      var account = dataService.getAccount();
      if (account.hasOnlineService) {
        // don't load availabilty os data if person has no edit right for pipelines
        if (this.privilegeService.has(RolePrivilege.Units_Edit_Custom) || this.privilegeService.has(RolePrivilege.Units_Edit)) {
          this.restService.loadAvailabilityForAddressbook(false).then((result) => {
            this.availabilityData = result;
            this.fillBaseFieldData();
          });
        }
      }
    }


  }

  getDefaultValue(): string {
    if (this.field && this.field.options && this.field.options.length > 0) {
      return this.field.options[0];
    }
    return this.functionsAndGroupsDefault;
  }
  getDisplayValue(){
    if (!this.field.value){
      return this.getDefaultValue();
    }
    return this.field.value;
  }

  selectOSFunctionGroup(functionGroup: string){
    this.field.value= functionGroup;
  }


  private fillBaseFieldData() {
    if (this.field.type === BaseFieldType.OS_FUNCTION) {
      this.selectableGroupsFunctions = this.availabilityData.data.functions;
    } else {
      this.selectableGroupsFunctions = this.availabilityData.data.groups;
    }
  }
}