'use strict';

import * as angular from "angular";
import {EAPagerFieldMode, ProvisionField} from "../../../data/person.data";
import {trimHashVal} from "angular-ui-router";

require('./external.registration.view.component.css');

/* @ngInject */
export default class ExternalRegistrationController {
  public $log;
  public $rootScope;
  public $scope;
  public restService;
  public $location;
  public authProvided:boolean;
  public registered:boolean;
  public sending:boolean;
  public status:number;
  public dsgvoAccepted:boolean;
  public request;
  public auth:string;
  public data;
  public isError:boolean;
  public provField: typeof ProvisionField = ProvisionField;
  public isProvisioningFieldValid:boolean= false;
  public isValidAPagerProValue:boolean= false;
  public isEMailValid:boolean= false;
  public isPhoneValid:boolean= false;

  constructor($rootScope, $scope, $log, $location, restService) {
    this.$log = $log;
    this.$log.debug('ExternalRegistrationController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.restService = restService;
    this.$location = $location;

    this.authProvided = true;
    this.registered = false;
    this.sending = false;
    this.status = 200;
    this.dsgvoAccepted = false;

    // Data
    this.request = {
      mobile: '',
      email: '',
      firstname: '',
      lastname: '',
      note: '',
      apager: '',
      groups: [],
      functions: [],
      alarmGroups: [],
      aPagerExists: false,
      eaPagerFieldMode:'',
      provisioningField:''
    };


    this.load();
  }

  /**
   * Get an static image
   */
  getStaticImage(imageName) {
    return this.restService.getStaticImageUrl(imageName);
  }


  load() {
    this.auth = this.$location.search().auth;

    if (angular.isUndefined(this.auth)) {
      //Invalid values
      this.authProvided = false;
      return;
    }

    this.$rootScope.isLoading = true;
    this.restService.getSelfRegistration(this.auth,
      (response) => {
        this.data = response.data;
        // Allow setting aPager Value for person if legacy mode is active
        this.request.eaPagerFieldMode = this.data.mode;
        this.request.provisioningField= this.data.provisioningField;
        this.isProvField(this.request.provisioningField);
        this.isEMailValid=this.validateEMailFormat(this.request.email, true);
        this.isPhoneValid=this.validatePhoneFormat(this.request.mobile,true);
        this.$rootScope.isLoading = false;
        this.$log.debug(response.data);
        this.$scope.$applyAsync();
      },
      (err, response) => {
        this.status = response.status;
        //Error occured
        this.$rootScope.isLoading = false;
        this.isError = true;
      });
  };

  validateEMailFormat(value: string, isProvFieldCheck: boolean):boolean {
    if (angular.isUndefined(value)||!value) {
      if (this.request.mode === EAPagerFieldMode.TOKEN && this.request.provisioningField === ProvisionField.EMAIL) {
        this.setProvisioningField(ProvisionField.EMAIL, false, isProvFieldCheck);
        return false;
      }
      this.setProvisioningField(ProvisionField.EMAIL, true, isProvFieldCheck);
      // don't display error on empty optional fields, in case of other modes
      if (isProvFieldCheck && this.request.provisioningField !== ProvisionField.EMAIL) {
        //only return empty as true if its the field Email and not set as provisioning field
        return true;
      }
    }
    if (value.includes("@")) {
      // Regex from https://stackoverflow.com/a/46370978
      const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      if (value.match(regexp)) {
        this.setProvisioningField(ProvisionField.EMAIL, true, isProvFieldCheck);
        return true;
      }
      this.setProvisioningField(ProvisionField.EMAIL, false, isProvFieldCheck);
      return false;
    }
  }

  setProvisioningField(provField: ProvisionField, isValid: boolean, isProvFieldCheck: boolean){
    if (!isProvFieldCheck){
      return;
    }
    if (this.request.provisioningField=== undefined){
      this.isProvisioningFieldValid = isValid;
    }
    if (this.isProvField(provField)) {
        this.isProvisioningFieldValid = isValid;
    }
  }

  validatePhoneFormat(value:String,isProvFieldCheck:boolean): boolean {
    if (angular.isUndefined(value)||!value){
      if (this.request.mode === EAPagerFieldMode.TOKEN && this.request.provisioningField === ProvisionField.MOBIL) {
        this.setProvisioningField(ProvisionField.MOBIL, false, isProvFieldCheck);
        return false;
      }
      this.setProvisioningField(ProvisionField.MOBIL, true, isProvFieldCheck);

      // don't display error on empty optional fields, in case of other modes
      return true;
    }
    if (value.startsWith("00")) {
      this.setProvisioningField(ProvisionField.MOBIL, false, isProvFieldCheck);

      return false;
    }
    // contains only digit values and starts with double zeros
    if (value.match("^00[0-9]+$")) {
      this.setProvisioningField(ProvisionField.MOBIL, true, isProvFieldCheck);
      return true;
    }
    this.setProvisioningField(ProvisionField.MOBIL, false, isProvFieldCheck);
    return false;
  }

  checkIfRegisteredValueIsLegacy(){
    // check if aPager is token
    if (this.request.apager===''||(this.request.apager.match(/^[A-Z0-9.]+$/)&&this.request.apager.length===8 )){
      this.request.eaPagerFieldMode= EAPagerFieldMode.TOKEN;
      this.request.provisioningField= ProvisionField.EMAIL;
      this.validateToken(this.request.apager);
      this.isEMailValid=this.validateEMailFormat(this.request.email,true);
      this.isPhoneValid= this.validatePhoneFormat(this.request.mobile, true);
    }
    // then if this matches put mode to legacy->support 2 people belonging to 2 different FE2 Systems
    if (this.request.apager.includes('@') || (this.request.apager.match("^00[0-9]+$")&&this.request.apager.length>8)){
      this.request.eaPagerFieldMode= EAPagerFieldMode.LEGACY;
      this.request.provisioningField= undefined;
      this.isEMailValid= this.validateEMailFormat(this.request.email,true);
      this.isPhoneValid= this.validatePhoneFormat(this.request.mobile, true);
      this.validateLegacyAPager(this.request.apager);
    }
    this.isAPagerSettingsValid();
    this.$scope.$applyAsync();
  }

  isAPagerSettingsValid():boolean{
    if (!this.request.aPagerExists){
      // person doesn't have a registered aPager yet, just correct Provisioning field is relevant for token in this case
       return this.isProvisioningFieldValid;
    }
    if (this.request.eaPagerFieldMode=== EAPagerFieldMode.LEGACY){
      // only relevant value in this mode
      return this.isValidAPagerProValue;
    }
    if (this.request.eaPagerFieldMode===EAPagerFieldMode.TOKEN){
      // ensure both are valid
      return this.isValidAPagerProValue && this.isProvisioningFieldValid;
    }

  }
  private validateLegacyAPager(value: string) {
    // do not show warning on empty field
    if (angular.isUndefined(value) || value === '') {
      this.isValidAPagerProValue = true;
      return;
    }
    // undo if valid was true
    this.isValidAPagerProValue = false;
    // check on E-Mail first
    this.isValidAPagerProValue = this.validateEMailFormat(value,false);
    if (this.isValidAPagerProValue) {
      return;
    }
    // validate on phone number if previous validation failed
    this.isValidAPagerProValue = this.validatePhoneFormat(value,false);
  }

  validateToken(value:string){
    //this.valuesChanged();
    if (angular.isUndefined(value) || !value){
      this.isValidAPagerProValue = false;
      return;
    }
    if (value.length!== 8){
      this.isValidAPagerProValue = false;
      return;
    }
    // regex matching parallel to backend structure
    if (value.match(/^[A-Z0-9.]+$/)){
      this.isValidAPagerProValue = true;
      return;
    }
    this.isValidAPagerProValue = false;
  }

  public isProvField(provField:ProvisionField):boolean{
    if (!this.request||!this.request.eaPagerFieldMode){
      return false;
    }
    if (this.request.eaPagerFieldMode===EAPagerFieldMode.TOKEN) {
      if (provField === this.request.provisioningField) {
        return true;
      }
    }
    return false;
  }


  /**
   * Select or deselect online service function
   * @param {function} f
   */
  selectFunction(f) {
    var index = this.request.functions.indexOf(f);
    if (index !== -1) {
      this.request.functions.splice(index, 1);
    } else {
      this.request.functions.push(f);
    }
  }

  /**
   * If online service function is selected or not
   * @param {function} f
   */
  isFunctionSelected(f) {
    var index = this.request.functions.indexOf(f);
    return index !== -1;
  }


  /**
   * Select or deselect online service group
   * @param {function} f
   */
  selectGroup(f) {
    var index = this.request.groups.indexOf(f);
    if (index !== -1) {
      this.request.groups.splice(index, 1);
    } else {
      this.request.groups.push(f);
    }
  }

  /**
   * If online service group is selected or not
   * @param {function} f
   */
  isGroupSelected(f) {
    var index = this.request.groups.indexOf(f);
    return index !== -1;
  }

  /**
   * Select or deselect alarm group
   * @param {function} f
   */
  selectAlarmGroup(f) {
    var index = this.request.alarmGroups.indexOf(f.id);
    if (index !== -1) {
      this.request.alarmGroups.splice(index, 1);
    } else {
      this.request.alarmGroups.push(f.id);
    }
  }

  /**
   * If alarm group is selected or not
   * @param {function} f
   */
  isAlarmGroupSelected(f) {
    var index = this.request.alarmGroups.indexOf(f.id);
    return index !== -1;
  }


  /**
    Save an existing person
  */
  register() {
    this.$rootScope.isLoading = true;
    this.sending = true;
    this.$log.info(this.request);
    this.restService.registerViaExternal(this.request, this.auth,
      () => {
        this.$rootScope.isLoading = false;
        this.sending = false;
        this.registered = true;
      },
      () => {
        //Error occured
        this.sending = false;
        this.$rootScope.isLoading = false;
      });
  };
}
