'use strict';

import { ILogService, IRootScopeService } from "angular";
import RestService from "./rest.service";

/* @ngInject */
export default class DashboardService {
  private $rootScope: IRootScopeService;
  private restService: RestService;
  private dataService;
  private messages = {};

  constructor($rootScope: IRootScopeService, dataService, restService: RestService) {
    this.$rootScope = $rootScope;
    this.restService = restService;
    this.dataService = dataService;
  }


  /**
   * Get the current number of alarms
   */
  getNumberOfAlarms(success, error) {
    this.restService.getNumberOfCurrentAlarms(true, success, error);
  };

  /**
   * Get the current number of events
   */
  getNumberOfEvents(success, error) {
    this.restService.getNumberOfCurrentEvents(success, error);
  };

  /**
   * Get the events in current time frame
   * @param {} success
   * @param {*} error
   */
  getEventsInTimeFrame(success, error) {
    this.restService.getEventsInTimeFrame((response) => {
      success(response);
    }, (errorResponse) => {
      error(errorResponse);
    });
  };

  /**
   * Get the current number of roadblocks
   */
  getNumberOfRoadblocks(success, error) {
    this.restService.getNumberOfCurrentRoadblocks(success, error);
  }

  /**
   * Get the current number of vehicle faults and vehicles in status 6
   */
  getNumberOfVehicleFaultsAndStatus6(success, error) {
    this.restService.getNumberOfVehicleFaultsAndStatus6(success, error);
  }


  /**
   * Save a custom field in database
   */
  updateCustomField(customField, success, error) {
    this.restService.updateCustomField(customField, success, error);
  }

  /**
   * Load all messages for specific category
   */
  getShiftbookMessages(categoryId: string, success, error) {
    this.restService.getShiftbookMessages(categoryId, (response) => {

      // Convert date string to date object
      response.data.forEach(message => {
        message.creationDate = new Date(message.creationDate);
      });

      this.$rootScope.$emit(`shiftbook.messages.cnt.${categoryId}`, response.data.length);
      this.messages[categoryId] = response.data;
      success(response.data);
    }, error);
  }

  /**
   * Add a new shiftbook message
   * @param {} message
   * @param {*} success
   * @param {*} error
   */
  addNewShiftbookMessage(message, success, error) {
    this.restService.addNewShiftbookMessage(message, (response) => {
      // Convert creation date
      response.data.creationDate = new Date(response.data.creationDate);
      this.messages[message.categoryId].push(response.data);
      this.$rootScope.$emit(`shiftbook.messages.cnt.${message.categoryId}`, this.messages[message.categoryId].length);
      success(response.data);
    }, error);
  };

  /**
   * Update an existing shiftbook message
   * @param {} message
   * @param {*} success
   * @param {*} error
   */
  updateShiftbookMessage(message, oldCategoryId: string, success, error) {
    this.restService.updateShiftbookMessage(message, (response) => {

      if (oldCategoryId && message.categoryId !== oldCategoryId) {
        // We need to move message to new category
        // Remove from previous category
        this.messages[oldCategoryId].splice(this.messages[oldCategoryId].indexOf(message), 1);
        this.$rootScope.$emit(`shiftbook.messages.cnt.${oldCategoryId}`, this.messages[oldCategoryId].length);
        // Add to new category
        this.messages[message.categoryId].push(message);
        this.$rootScope.$emit(`shiftbook.messages.cnt.${message.categoryId}`, this.messages[message.categoryId].length);
      }

      success(response.data);
    }, error);
  };


  /**
   * Delete an existing shiftbook message
   * @param {} message
   * @param {*} success
   * @param {*} error
   */
  deleteShiftbookMessage(message, success, error) {
    this.restService.deleteShiftbookMessage(message, () => {
      var categoryId = message.categoryId;
      this.messages[categoryId].splice(this.messages[categoryId].indexOf(message), 1);
      this.$rootScope.$emit(`shiftbook.messages.cnt.${categoryId}`, this.messages[categoryId].length);
      success();
    }, error);
  };
};
