'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import angular = require("angular");
import {AMobile, ELicencePermissions} from "../../../../data/amobile.data";
import RestService from "../../../../services/rest.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./amobile.tablerow.component.css');

export default class AmobileTablerowComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.scope = {
      amobile: '='
    };
    this.template = require('./amobile.tablerow.component.html');

    this.controller = AmobileTablerowController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//AmobileTablerowController
/* @ngInject */
class AmobileTablerowController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public restService: RestService;
  public listeners: any;
  public isLoading = false;
  public amobile: AMobile;
  public licencePermissions: typeof ELicencePermissions= ELicencePermissions;
  public priv:PrivilegeService;
  public hasAMobileDelete:boolean= false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, restService: RestService, privilegeService:PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.listeners = [];
    this.restService = restService;
    this.priv= privilegeService;

    this.$scope.$watch('ctrl.amobile', (oldValue, newValue) => {
      if (angular.isDefined(newValue)) {
        this.initListeners();
      }
    });
  }

  /*
    Delete an existing AM instance
  */
  delete($event) {
    $event.stopPropagation();
    this.isLoading = true;
    this.restService.deleteAMobileById(this.amobile.id, () => {
      this.isLoading = false;
      this.$rootScope.$emit('addressbook.view.tab', 3); // Trigger reload
    }, (errorResponse) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(errorResponse);
    });
  };

  initListeners() {
    this.hasAMobileDelete =this.priv.has(RolePrivilege.Addressbook_aMobile_Delete);
    this.listeners.push(
      this.$rootScope.$on('amobile.change.' + this.amobile.id, (event, data) => {
        this.$log.info(data);
        this.amobile = data;
        this.$scope.$apply();
      })
    );

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}
