'use strict';

/* @ngInject */
import { KbaRequest } from "../../../../data/admin.data";

require('./kba.request.modal.scss')

export default class ModalKbaRequestController {
  private $uibModalInstance: any;
  public request: KbaRequest;
  public vendor: string;


    constructor($uibModalInstance, request: KbaRequest) {
      this.$uibModalInstance = $uibModalInstance;
      this.request = request;

      if (this.request.result) {
        this.vendor = `https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/${this.request.result.textMarke.toLowerCase()}.png`;
      }
    }

    cancel() {
        this.$uibModalInstance.close();
    }
}
