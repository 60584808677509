'use strict';

/* @ngInject */
export default class ConfirmModalController {
	public $uibModalInstance: any;
	public okFunction: any;
  public text: string;
  public info: string;

  constructor($uibModalInstance, okFunction: Function, text: string, info: string) {
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
    this.text = text;
    this.info = info;
  }

  cancel() {
    this.$uibModalInstance.close();
  };

  ok() {
    this.$uibModalInstance.close();
    this.okFunction();
  };

}
