import { Coordinate } from "./vehicles.data";

export interface Roadblock {
  id: string;
  name: string;
  routeShape: number[][];
  from: string;
  to: string;
  street: string;
  city: string;
  cityAbbr: string;
  postalCode: string;
  reason: string;
  note: string;
  type: ERoadblockType;
  variant: ERoadWorksType;
  parent: string;
  userId: string;
  publicAccessible: boolean;
  roadblockAccess: string;
  lastChanged: any;
  important: boolean;
  validRouteShape: boolean;
  idMapTrip: string;
  source: string;
  sourceId: string;
  mapTripType: EMapTripType;
  mapTripDirection: EMapTripDirection;
  mapTripHeight: number;
  mapTripWidth: number;
  mapTripLength: number;
  mapTripWeight: number;
  openlr: string;
}

export enum EMapTripType {
    BLOCK = 'BLOCK',
    AVOID = 'AVOID'
}

export enum EMapTripDirection {
    ATOB = 'ATOB',
    BTOA = 'BTOA',
    BOTH = 'BOTH'
}
export enum ERoadWorksType{
  DEFAULT='DEFAULT',
  NIGHT='NIGHT',
  LIMITED='LIMITED'
}

export enum ERoadblockType {
  ROAD_WORKS = 'ROAD_WORKS',
  ROAD_CLOSED = 'ROAD_CLOSED',
  INCIDENT = 'INCIDENT'
}

export interface DetourMatch {
  openlr: string;
  coordinatesAsDoubles: number[][];
  startDivided: boolean;
  endDivided: boolean;
}