'use strict';

import { IHttpService, ILogService, IRootScopeService, IScope, IWindowService } from "angular";
import { AlarmTemplatePaginatedResponse } from "../../../../data/template.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./alarm.templates.component.css');

//alarmTemplatesView
export default class AlarmTemplatesComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.templates.component.html');
    this.scope = {
      account: '=',
      grids: '='
    }
    this.controller = AlarmTemplatesComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmTemplatesComponentController {
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $log: ILogService;
  public $uibModal: any;
  public dataService: any;
  public $window: any;
  public $http: any;
  public restService: RestService;
  public FileUploader: any;
  public account: any;
  public grids: any;
  public isLoading = false;

  public searchFilter: string = "";
  public templateResponse: AlarmTemplatePaginatedResponse;
  private page: number = 0;

  private listeners = [];

  constructor($scope: IScope, $rootScope: IRootScopeService, $window: IWindowService, $http: IHttpService, restService: RestService, dataService, $log: ILogService, $uibModal, FileUploader,
    public privilegeService: PrivilegeService) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.$window = $window;
    this.$http = $http;
    this.restService = restService;
    this.FileUploader = FileUploader;

    this.listeners.push(this.$rootScope.$on('alarm.template.refresh', () => {
      this.refresh();
    }));
    this.refresh();

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

  }

  /*
   * Add new alarm template
   */
  add() {
    var template = {};
    this.$uibModal.open({
      template: require('./../../../modals/alarms/edit.alarm.template.modal/edit.alarm.template.modal.html'),
      controller: 'EditAlarmTemplateModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        template: () => {
          return template;
        }
      }
    });
  };

  refresh() {
    this.isLoading = true;
    this.restService.getAlarmTemplatesPaginated(this.page, this.searchFilter)
      .then(templateResponse => {
        this.templateResponse = templateResponse;
      })
      .catch(err => {
        this.$log.error(err);
      })
      .finally(() => {
        this.isLoading = false;
        this.$scope.$apply();
      })
  }

  resetSearchFilter() {
    this.searchFilter = "";
    this.page = 0;
    this.refresh();
  }

  searchFilterChanged() {
    this.page = 0;
    this.refresh();
  }

  nextPage() {
    if(!this.templateResponse.hasNext) return;
    this.page++;
    this.refresh();
  }

  previousPage() {
    if (!this.templateResponse.hasPrevious) return;
    this.page--;
    this.refresh();
  }

  /**
   * Add a new alarm template grid
   */
  addTemplateGrid() {
    var templateGrid = {};
    this.$uibModal.open({
      template: require('./../../../modals/alarms/edit.alarm.template.grid.modal/edit.alarm.template.grid.modal.html'),
      controller: 'EditAlarmTemplateGridModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        grid: () => {
          return templateGrid;
        }
      }
    });
  };

  /**
   * Edit existing alarm template grid
   */
  editGrid(grid) {
    this.$uibModal.open({
      template: require('./../../../modals/alarms/edit.alarm.template.grid.modal/edit.alarm.template.grid.modal.html'),
      controller: 'EditAlarmTemplateGridModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        grid: () => {
          return grid;
        }
      }
    });
  };


  /**
       Import templates from JSON
     */
  import() {
    var modalInstance = this.$uibModal.open({
      template: require('./../../../modals/misc/import.modal/import.modal.html'),
      controller: 'ImportModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        uploader: () => {
          let fileUploader = new this.FileUploader();
          return fileUploader;
        },
        url: () => {
          return '/templates/alarmgrid/upload';
        }
      }
    });

    modalInstance.result.then(() => {
      //Reload
      this.$rootScope.$emit('imported.alarmtemplates');
      this.refresh();
    });
  };

  /**
    Export templates as JSON
  */
  export() {
    this.$window.open(this.restService.getBaseUrl() + '/templates/alarm/?download=true&Authorization=' + this.$http.defaults.headers.common.Authorization, '_blank');
  };

  /**
   * Delete existing alarm template grid
   */
  deleteAlarmTemplateGridById(grid) {
    this.isLoading = true;
    this.dataService.deleteAlarmTemplateGridById(grid, (grids) => {
      this.grids = grids;
      this.isLoading = false;
    }, (err) => {
      this.$log.error(err);
      this.isLoading = false;
    });
  }
}
