'use strict';

import { ILogService, IScope } from "angular";
import { AlarmObject } from "../../../../data/objects.data";
import RestService from "../../../../services/rest.service";

//objectDocuments
require('./object.documents.component.scss');
export default class ObjectDocumentsComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.documents.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectDocumentsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectDocumentsController {
  public isUploading = false;
  public isLoading = false;
  public auth: string;
  public uploader: any;
  public alarmObject: AlarmObject;

  constructor(public $log: ILogService,
    public $scope: IScope,
    public restService: RestService,
    public dataService) {
    this.auth = this.restService.getAuthHeader();

    $scope.$watch('ctrl.alarmObject', (newValue, oldValue) => {
      if (newValue && !this.uploader) {
        this.uploader = this.restService.createUploader(this.alarmObject);
      }
    });
  }

  getDownloadPath(doc) {
    return `/rest/objects/${this.alarmObject.id}/files/${doc.id}?Authorization=${this.auth}`;
  };

  /**
   * Add a new document
   */
  uploadFiles() {
    if (this.uploader.queue.length === 0) {
      return;
    }
    this.isUploading = true;

    this.uploader.onBeforeUploadItem = (item) => {
      //Characters not affected by URL encode have to be removed
      let name = item.file.name.replace('&', '').replace('#', '');
      //Change upload path
      item.url = item.url + encodeURI(name);
    };


    //Success
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.$log.info(`Upload completed: ${status}`);

      //If file already exists, delete it
      var toDelete = -1;
      for (var i = 0; i < this.alarmObject.documents.length; i++) {
        if (this.alarmObject.documents[i].id === response.id) {
          toDelete = i;
          break;
        }
      }
      if (toDelete > -1) {
        this.alarmObject.documents.splice(toDelete, 1);
      }

      this.alarmObject.documents.push(response);
      this.isUploading = false;
    };
    //Error
    this.uploader.onErrorItem = (item, response, status) => {
      this.$log.error(`Upload failed: ${status}`);
      this.$log.error(response);
      this.isUploading = false;
    };


    this.uploader.onCompleteAll = function () {
      this.isUploading = false;
    };

    this.uploader.uploadAll();
  };

  /**
   * Delete existing document
   * @param {*} document 
   */
  delete(document) {
    this.isLoading = true;
    this.dataService.deleteDocument(this.alarmObject, document, (suc) => {
      this.isLoading = false;
    }, (err) => {
      this.$log.error(err);
      this.isLoading = false;
    });
  };
}
