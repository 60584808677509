'use strict';

import angular = require("angular");
import { AlarmData } from "../../../../data/alarm.data";

require('./alarm.detail.component.css');


export default class AlarmDetailComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;


  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.detail.component.html');
    this.scope = {
      alarm: '='
    };
    this.controller = AlarmDetailComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmDetailComponentController {
  public $scope: angular.IScope;
  public $uibModal: any;
  public helperService: any;
  public icon: string;
  public alarm: AlarmData;

  constructor($scope: angular.IScope, $uibModal, helperService) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.helperService = helperService;
  }

  /**
   * Select a alarm object
   * @param {*} alarmObject 
   */
  selectAlarmObject() {
    if (!this.alarm.parameters['building_id']) {
      return;
    }

    this.$uibModal.open({
      template: require('../../../modals/objects/edit.alarm.object.modal/edit.alarm.object.modal.html'),
      controller: 'EditAlarmObjectCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'hu',
      resolve: {
        alarmObject: () => {
          return undefined;
        },
        alarmObjectId: () => {
          return this.alarm.parameters['building_id'];
        }
      }
    });
  }
}
