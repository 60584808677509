"use strict";

import { IScope } from "angular";
import { ERelaisGroup } from "../../../../data/admin.data";
import { BuildingControllerResponse } from "../../../../data/controller.data";
import RestService from "../../../../services/rest.service";

require("./edit.controller.modal.scss")

/* @ngInject */
export default class EditControllerModalController {
  public isSaving = false;
  public tab: EditControllerTab = EditControllerTab.OVERALL;
  public groups: ERelaisGroup[] = Object.values(ERelaisGroup);
  public form: any;
  constructor(private $uibModalInstance: any,
    private $scope: IScope,
    private restService: RestService,
    public controller: BuildingControllerResponse
  ) {

  }


  cancel() {
    this.$uibModalInstance.close();
  }

  save() {
    this.isSaving = true;
    this.restService.saveBuildingController(this.controller).then((response) => {
      this.controller = response;
    }).finally(() => {
      this.isSaving = false;
      this.$scope.$applyAsync();
    });
  }
}

enum EditControllerTab {
  OVERALL = 'OVERALL',
  RELAIS = 'RELAIS'
}