'use strict';

import { IScope } from "angular";
import angular = require("angular");
import * as L from "leaflet";
import { SirenLocation, VehicleLocationResponse } from "../../../../data/vehicles.data";
import MapService from "../../../../services/map.service";


/* @ngInject */
export default class ChooseSirenOnMapController {
  public marker: L.Marker;
  public radiusCircle: any;
  public map;

  constructor(private readonly $uibModalInstance: any,
    private readonly $scope: IScope,
    private readonly mapService: MapService,
    private readonly dataService: any,
    private readonly okFunction: any,
    public coords: SirenLocation,
    $document) {

    const settings = this.dataService.getAccount().settings;

    if (angular.isUndefined(this.coords.lat)) {
      this.coords = {
        lat: settings.lat,
        lng: settings.lng,
        radius: 0
      } as SirenLocation;
    }

    $document.ready(() => {
      this.initMap();
    });
  }

  /**
   * Init the map
   */
  initMap() {
    L.Icon.Default.imagePath = '/img/static';
    this.map = L.map('mapid').setView([this.coords.lat, this.coords.lng], 13);

    this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');

    let layers = this.mapService.getBaseLayers();

    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService
    this.map.on('baselayerchange', function (e) {
      mapService.saveLayer(e.name);
    });

    const latLng = L.latLng(this.coords.lat, this.coords.lng);

    this.radiusCircle = L.circle(latLng, this.coords.radius);
    if (this.coords.radius > 0) {
      this.radiusCircle.addTo(this.map);
    }

    this.marker = L.marker(latLng, { draggable: true })
      .addTo(this.map);

    this.map.on('click', (ev) => {
      this.markerMovedEvent(ev.latlng);
    });
    this.marker.on('dragend', (e) => {
      this.markerMovedEvent(e.target.getLatLng());
    });

  }

  /**
   * Should be triggered, if marker should be moved
   * @param latLng 
   */
  markerMovedEvent(latLng: L.LatLng) {
    this.marker.setLatLng(latLng);
    this.radiusCircle.setLatLng(latLng);
    this.coords.lat = latLng.lat;
    this.coords.lng = latLng.lng;
    this.$scope.$applyAsync();
  }

  radiusChanged() {
    if (this.coords.radius > 0) {
      this.radiusCircle.setRadius(this.coords.radius);
      this.radiusCircle.addTo(this.map);
    } else {
      this.map.removeLayer(this.radiusCircle);
    }
  }
  
  updateMap() {
    const coords = L.latLng(this.coords.lat, this.coords.lng);
    this.markerMovedEvent(coords);
    this.map.setView(coords);
  }


  /**
   * Return the new lat/lng and close modal
   */
  ok() {
    this.$uibModalInstance.close();
    const currentLatLng = this.marker.getLatLng();
    this.okFunction({
      lat: currentLatLng.lat,
      lng: currentLatLng.lng,
      radius: this.coords.radius
    });
  }

}