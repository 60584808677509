'use strict';

import angular = require('angular');
export default class AvailabilityMonthComponent {
	public restrict: any;
	public scope: any;
	public template: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        person: '='
      }
    this.template = require('./month.component.html');

    this.controller = AvailabilityMonthController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityMonth
/* @ngInject */
class AvailabilityMonthController {
	public $scope: any;
	public $rootScope: any;
	public $log: any;
	public dataService: any;
	public helperService: any;
	public useDate: any;
	public months: any;
	public data: any;
	public disableButtons: any;
	public notYetLoaded: any;
	public isError: any;
	public selectedYear: any;
	public selectedMonth: any;
	public isWeekSelected: any;
	public selectedWeek: any;
	public electedWeek: any;
	public person: any;
	public month: any;

  constructor($scope, $rootScope, $log, dataService, helperService) {

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.dataService = dataService;
    this.helperService = helperService;

    this.useDate = false;
    this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    this.data = {
      threshold: this.helperService.getFromStorage('threshold', 50),
      hours: 12
    };
    this.disableButtons = true;
    this.$rootScope.isAvailabilityLoading = false;
    this.notYetLoaded = true;
    this.isError = false;

    // Init
    var now = new Date();
    this.selectedYear = now.getFullYear();
    this.selectedMonth = now.getMonth() + 1;

    this.$scope.$watch("ctrl.person", (person) => {
      if (angular.isDefined(person)) {
        this.load();
      }
    });
  }

  /**
   * Switch to next month
   */
  nextMonth() {
    if (this.selectedMonth === 12) {
      this.selectedMonth = 1;
      this.selectedYear++;
    } else {
      this.selectedMonth++;
    }
    this.useDate = false;
    this.load();
  }

  /**
   * Select month
   */
  selectMonth(month) {
    this.selectedMonth = month;
    this.useDate = false;
    this.load();
  }

  /**
   * Switch to previous month
   */
  lastMonth() {
    if (this.selectedMonth === 1) {
      this.selectedMonth = 12;
      this.selectedYear--;
    } else {
      this.selectedMonth--;
    }
    this.useDate = false;
    this.load();
  }

  /**
   * Select a week
   * @param {} week
   */
  selectWeek(week) {
    this.isWeekSelected = true;
    this.selectedWeek = week;
  }

  /**
   * Deselect a week
   */
  clearSelection() {
    this.isWeekSelected = false;
    this.electedWeek = undefined;
  }

  /**
   * Load month from server
   */
  load() {
    this.$rootScope.isAvailabilityLoading = true;
    this.disableButtons = true;
    this.dataService.loadMonthlyAvailability(this.person, this.selectedMonth, this.selectedYear, (response) => {
      this.$log.info(response);
      this.notYetLoaded = false;
      this.disableButtons = false;
      this.$rootScope.isAvailabilityLoading = false;
      this.month = response;
    }, (err) => {
      this.disableButtons = false;
      this.isError = true;
      this.$rootScope.isAvailabilityLoading = false;
      // Could not load monthly availability
      this.$log.error('Could not load monthly availability');
      this.$log.error(err);
    });
  }

  /**
   * Emit threshold event
   */
  changeThreshold() {
    this.data.hours = Math.round(24 / 100 * this.data.threshold);
    this.helperService.saveInStorage('threshold', this.data.threshold);
  }
}