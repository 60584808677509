'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { AlarmGroupSimple, SingleAlarmGroupSaveRequest} from "../../../../data/alarmgroup.data";
import RestService from "../../../../services/rest.service";

/**
 * Controller for modal which displays a single function with all persons from addressbook
 *
 */
/* @ngInject */
export default class SingleGroupModalController {
  public $scope: IScope;
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $uibModalInstance: any;
  public restService: RestService;
  public okFunction: any;
  public name: string;
  public color: string;
  public groupId: string;
  // use simple object--> no person mapping necessary
  public group: AlarmGroupSimple;

  constructor($scope: IScope, $log: ILogService, $rootScope: IRootScopeService, $uibModalInstance, restService: RestService, group: AlarmGroupSimple, okFunction) {
    this.$scope = $scope;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.name = group.name;
    this.color = group.color;
    this.groupId = group.id
    this.group = group;
    this.okFunction = okFunction;
  }

  cancel() {
    this.$uibModalInstance.close();
    this.okFunction(false);
  }

  saveSingleAlarmGroup() {
    var groupToSave = {
      id: this.groupId,
      name: this.name,
      color: this.color,
      defaultGroup: this.group.defaultGroup
    } as SingleAlarmGroupSaveRequest


    this.restService.saveSingleAlarmGroup(groupToSave, () => {
      this.group.color = this.color;
      this.group.name = this.name;

      this.$uibModalInstance.close();
      this.okFunction(true);
    }, (error) => {
      this.$log.error(error);

      this.$uibModalInstance.close();
      this.okFunction(true);
    })
  }
}
