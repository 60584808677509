'use strict';
//QrInstanceModalController
/* @ngInject */
export default class QrInstanceModalController {
	public $scope: any;
	public $uibModalInstance: any;
	public apiKey: any;

    constructor($scope, $uibModalInstance, apiKey) {
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.apiKey = apiKey;
    }

    cancel() {
        this.$uibModalInstance.close();
    }
}