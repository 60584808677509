import {IScope} from "angular";
import {Emergency, EmergencyReport, MissionReport, MissionReportSlice} from "../../../../../data/emergency.data";
import {RolePrivilege} from "../../../../../data/privileges.enum";
import PrivilegeService from "../../../../../services/privilege.service";
import RestService from "../../../../../services/rest.service";

require("./mission.report.component.scss")
/* @ngInject */
export default class MissionReportComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./mission.report.component.html');
    this.scope = {
      emergency: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = MissionAlarmComponentController;

  }
}

class MissionAlarmComponentController {
  public emergency: Emergency;
  public readonly: boolean = true;
  public hasExportRight: boolean;
  public missionReports: MissionReportSlice;
  public $scope: IScope;
  public page: number = 1;
  public limit: number = 10;
  public isLoadingReports: boolean = false;
  private isAdmin: boolean = false;

  constructor($scope: IScope, public privilegeService: PrivilegeService, public restService: RestService, public $uibModal, public $window, public $http, dataService: any) {
    this.readonly = !this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Report_Write);
    this.hasExportRight= privilegeService.privileges['Home_Emergency_Details_Export_Report']

    this.$scope = $scope;
     this.isAdmin = dataService.getAccount().admin;
     let loggedInUserId= dataService.getAccount().id;

    this.$scope.$watch('ctrl.emergency', (oldValue, emergency: Emergency) => {
      if (this.emergency) {
        let userId;
        if (this.isAdmin){
          userId= this.emergency.parentUserId;
        }
        this.isLoadingReports = true;
        this.restService.getMissionReports(this.emergency.externalId, 0, this.limit, userId).then((reports) => {
          this.missionReports = reports;
        }).finally(() => {
          this.isLoadingReports = false;
          this.$scope.$applyAsync();
        });
      }
    });
    this.$scope.$on('report.changed', () => {
      let userId;
      if (this.isAdmin){
        userId= this.emergency.parentUserId;
      }
      this.restService.getMissionReports(this.emergency.externalId, 0, this.limit, userId).then((reports) => {
        this.missionReports = reports;
        if (this.emergency.parentUserId == loggedInUserId){
          this.restService.closeMissionAllowed(this.emergency.externalId).then((allowed)=>{
            this.emergency.closingAllowed = allowed;
          });
        }

      }).finally(() => {
        this.$scope.$applyAsync();
      });
    })
  }

  exportPdf(){
    var url = `${this.restService.getBaseUrl()}/missionReports/${encodeURIComponent(this.emergency.externalId)}/export?Authorization=${this.$http.defaults.headers.common.Authorization}`

    if (this.isAdmin) {
      url = `${this.restService.getBaseUrl()}/missionReports/${encodeURIComponent(this.emergency.externalId)}/export?userId=${this.emergency.parentUserId}&Authorization=${this.$http.defaults.headers.common.Authorization}`
    }
    this.$window.open(url, '_blank');
  }

  nextPage() {
    this.page++;
    this.pageChanged();
  }

  previousPage() {
    if (this.page === 0) return;
    this.page--;
    this.pageChanged();
  }

  pageChanged() {
    let reqPage = this.page <= 0 ? 0 : this.page - 1;
    this.isLoadingReports = true;
    this.restService.getMissionReports(this.emergency.externalId, reqPage, this.limit).then((reports) => {
      this.missionReports = reports;
    }).finally(() => {
      this.isLoadingReports = false;
      this.$scope.$applyAsync();
    });

  }

  /**
   * get the prefilled report from backend and open the edit modal
   */
  prefillNewReport() {
    if (this.emergency) {
      this.restService.loadPrefilledReport(this.emergency.externalId, this.emergency.parentUserId).then((reportPrefilled) => {
        this.openReportEditModal(reportPrefilled);
      })
    }

  }

  createReport() {
    this.prefillNewReport();
  }

  openReportEditModal(report: MissionReport) {
    this.$uibModal.open({
      template: require('../../../../modals/alarms/mission.report.modal/mission.report.modal.html'),
      controller: 'ReportModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        missionReport: () => {
          return report;
        },
        emergency: () => {
          return this.emergency;
        },
        isNew: () => {
          return true;
        }, okFunction: () => {
          return (refresh) => {
            if (refresh) {
              this.$scope.$emit("report.changed");
            }
          }
        }
      }
    });
  }
}
