'use strict';

//humanReadableDiff
/* @ngInject */
export default class HumanReadableTimeDiffFilter {
  static filter(diff) {
    if (diff === undefined || diff === 0) {
      return '';
    }
    diff = diff / 1000;

    var sec = Math.round(diff);
    var min = Math.floor(diff / 60);
    var hour = Math.floor(min / 60);
    var days = Math.floor(hour / 24);
    if (hour !== 0) {
      min = min % 60;
      sec = sec % 60;
    }

    if (min !== 0) {
      sec = sec % 60;
    }

    //Days
    if (days !== 0) {
      if (days === 1) {
        return 'Nach einem Tag';
      } else {
        if (days > 365) {
          return 'Ein Jahr oder später';
        } else {
          return 'Nach ' + days + ' Tagen';
        }

      }
    }

    if (hour !== 0) {
      if (min !== 0) {
        if (sec !== 0) {
          // Hours, minutes and seconds
          return 'Nach ' + hour + ' Stunde/n, ' + min + ' Minute/n und ' + sec + ' Sekunde/n';
        } else {
          // Hours and minutes
          return 'Nach ' + hour + ' Stunde/n und ' + min + ' Minute/n';
        }
      } else {
        // no minutes
        if (sec !== 0) {
          // Hours, and seconds
          return 'Nach ' + hour + ' Stunde/n und ' + sec + ' Sekunde/n';
        } else {
          // Hours
          return 'Nach ' + hour + ' Stunde/n';
        }
      }
    } else {
      if (min !== 0) {
        if (sec !== 0) {
          // minutes and seconds
          return 'Nach ' + min + ' Minute/n und ' + sec + ' Sekunde/n';
        } else {
          // minutes
          return 'Nach ' + min + ' Minute/n';
        }
      } else {
        if (sec !== 0) {
          //  Seconds
          return 'Nach ' + sec + ' Sekunde/n';
        } else {
          // Hours
          return 'Sofort';
        }
      }
    }
  }
}
