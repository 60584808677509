import { IScope } from "angular";
import { ChatroomAdminUserResponse, SimpleChatroomResponse } from "../../../../data/chat.data";
import { Slice } from "../../../../data/misc.data";
import RestService from "../../../../services/rest.service";
import TracelogPluginModalController from "../../../modals/pipeline/tracelog.plugin.modal/tracelog.plugin.modal";
import ErrorService from "../../../../services/error.service";

require("./admin.chat.component.scss")

export default class AdminChatComponent {
    public restrict: string;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
      this.restrict = 'E';
      this.template = require('./admin.chat.component.html');
      this.scope = {};
      this.controller = Controller;
      this.controllerAs = 'ctrl';
      this.bindToController = true;
    }
}

class Controller {

    private readonly size: number = 25;
    private page: number = 0;

    public data: Slice<SimpleChatroomResponse[]>;
    public isLoading: boolean = false;

    public isLoadingAdmin: boolean = false;
    public adminUserExists: boolean = false;
    public adminUserName: string = "";

    constructor(private readonly restService: RestService,
        private readonly $scope: IScope,
        private readonly $uibModal: any,
        private readonly errorService: ErrorService) {

        this.loadData();
        this.loadAdmin();
    }


    private async loadData() {
        this.isLoading = true;
        this.data = await this.restService.getChatroomSlice(this.page, this.size);
        this.isLoading = false;
        this.$scope.$applyAsync();
    }

    private async loadAdmin() {
        this.isLoadingAdmin = true;
        try {
            const admin = await this.restService.getChatroomAdminUser();
            this.adminUserName = admin.displayName ?? "";
            this.adminUserExists = admin.exists;
        } catch (err) {
            this.errorService.notifyWithText(err.data.message);
        } finally {
            this.isLoadingAdmin = false;
            this.$scope.$applyAsync();
        }
    }

    public previousPage() {
        if (this.data.first) return;
        this.page--;
        this.loadData();
    }

    public nextPage() {
        if (this.data.last) return;
        this.page++;
        this.loadData();
    }

    public async openModal(chat: SimpleChatroomResponse) {
        const chatroom = await this.restService.getSingleChatroom(chat.alias);
        this.$uibModal.open({
            template: require('../../../modals/admin/chat.details.modal/chat.details.modal.html'),
            controller: 'ChatDetailsModalController',
            controllerAs: 'ctrl',
            size: 'lg',
            resolve: {
              chatroom: () => chatroom
            }
          });
    }

}