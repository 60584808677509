import {IScope} from "angular";
import PrivilegeService from "../../../../../services/privilege.service";
import RestService from "../../../../../services/rest.service";
import {ProtocolModalMode} from "../../../../modals/admin/rest.protocol.modal/rest.protocol.modal";

require("./rest.protocol.body.component.css")
/* @ngInject */
export default class RestProtocolBodyComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./rest.protocol.body.component.html');
    this.scope = {
      data: '=',
      id: '=',
      mode: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = RestProtocolBodyController;

  }
}

class RestProtocolBodyController {

  public data: string;
  public id: string;
  public mode: ProtocolModalMode;


  constructor(private $scope: IScope,
              public privilegeService: PrivilegeService,
              private $http: any,
              private $window: any,
              private restService: RestService,
              public Notification: any,
              public $translate: any,
              private $uibModal: any,
              private dataService: any,
              private accountService: any) {

  }

  downloadBody() {
    if (this.mode == ProtocolModalMode.REQUEST_BODY) {
      if (this.id != null && this.data != null) {
        const auth = this.$http.defaults.headers.common.Authorization;
        const url = `${this.restService.getBaseUrl()}/restProtocols/${this.id}/request/body/download?Authorization=${auth}`;
        this.$window.open(url, '_blank')
      }
    }
    if (this.mode == ProtocolModalMode.RESPONSE_BODY) {
      if (this.id != null && this.data != null) {
        const auth = this.$http.defaults.headers.common.Authorization;
        const url = `${this.restService.getBaseUrl()}/restProtocols/${this.id}/response/body/download?Authorization=${auth}`;
        this.$window.open(url, '_blank')
      }
    }
  }

  copyBody() {
    navigator.clipboard.writeText(this.data)
      .then(() => this.$translate(['ADMIN.CLIPBOARD_MSG', 'ADMIN.CLIPBOARD_TITLE']).then((translations) => {
        this.Notification.success({
          message: translations['ADMIN.CLIPBOARD_MSG'],
          title: translations['ADMIN.CLIPBOARD_TITLE']
        });
      }));
  }


}