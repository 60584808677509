'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import {RolePrivilege} from "../../../../data/privileges.enum";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import {AnnouncementDisplay} from "../../../../data/announcement.data";

require('./dashboard.component.css');

export default class DashboardComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      vehicle: '='
    }
    this.template = require('./dashboard.component.html');

    this.controller = DashboardComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class DashboardComponentController {
  public helperService: HelperService;
  public dashboardService: any;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $uibModal: any;
  public $window: any;
  public $http: any;
  public dataService: any;
  public restService: RestService;
  public categories: any;
  public customFields: any;
  public alarmWidgetData: any;
  public eventWidgetData: any;
  public roadblocksWidgetData: any;
  public vehicleWidgetData: any;
  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $uibModal, $window, $http, helperService: HelperService,
    dashboardService, dataService, restService: RestService, public privilegeService: PrivilegeService) {
    this.helperService = helperService;
    this.dashboardService = dashboardService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$log.debug('Dashboard Controller started...');
    this.$uibModal = $uibModal;
    this.$window = $window;
    this.$http = $http;
    this.dataService = dataService;
    this.restService = restService;
    this.categories = [];
    this.customFields = [];

    this.getCategoriesFromServer(false);
    this.getCustomFieldsFromServer(false);


    this.initWidgets();
    this.updateWidgets();
  }

  /**
   * Init the widgets
   */
  initWidgets() {
    // Todays alarms
    if (this.privilegeService.has(RolePrivilege.Home_Alarms_List)) {
      this.alarmWidgetData = {
        count: 0,
        countError: 0,
        state: 'LOADING'
      };
    }

    // Todays events
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Events_Read)) {
      this.eventWidgetData = {
        count: 0,
        state: 'LOADING'
      };
    }

    // Todays constrcutions
    if (this.privilegeService.has(RolePrivilege.Objects_Roadblocks)) {
      this.roadblocksWidgetData = {
        count: 0,
        state: 'LOADING'
      };
    }

    // Current Faults
    if (this.privilegeService.has(RolePrivilege.Station_Vehicles_Faults) || this.privilegeService.has(RolePrivilege.Station_Sirens_Faults)) {
      this.vehicleWidgetData = {
        countFaults: 0,
        countStatus6: 0,
        state: 'LOADING'
      };
    }
  };

  /**
   * Update all widgets
   */
  updateWidgets() {

    if (this.privilegeService.has(RolePrivilege.Home_Alarms_List)) {
      this.alarmWidgetData.state = 'LOADING';
      this.dashboardService.getNumberOfAlarms((data => {
        this.alarmWidgetData.count = data.count;
        this.alarmWidgetData.countError = data.countError;
        if (data.countError > 0) {
          this.alarmWidgetData.state = 'DANGER';
        } else {
          this.alarmWidgetData.state = 'READY';
        }
      }), error => {
        this.$log.error('Could not load number of alarms to update widgets', error);
      });
    }

    if (this.privilegeService.has(RolePrivilege.Objects_Database_Events_Read)) {
      this.eventWidgetData.state = 'LOADING';
      this.dashboardService.getNumberOfEvents((cnt => {
        this.eventWidgetData.count = cnt;
        this.eventWidgetData.state = 'READY';
      }), error => {
        this.$log.error('Could not load number of events', error);
      });
    }

    if (this.privilegeService.has(RolePrivilege.Objects_Roadblocks)) {
      this.roadblocksWidgetData.state = 'LOADING';
      this.dashboardService.getNumberOfRoadblocks((cnt => {
        this.roadblocksWidgetData.count = cnt;
        if (cnt > 0) {
          this.roadblocksWidgetData.state = 'WARNING';
        } else {
          this.roadblocksWidgetData.state = 'SUCCESS';
        }
      }), error => {
        this.$log.error('Could not load number of roadblocks', error);
      });
    }

    if (this.privilegeService.has(RolePrivilege.Station_Vehicles_Faults) || this.privilegeService.has(RolePrivilege.Station_Sirens_Faults)) {
      this.vehicleWidgetData.state = 'LOADING';
      this.dashboardService.getNumberOfVehicleFaultsAndStatus6((data => {
        this.vehicleWidgetData.countFaults = data.countFaults;
        this.vehicleWidgetData.countStatus6 = data.countStatus6;
        if (data.countFaults > 0) {
          this.vehicleWidgetData.state = 'DANGER';
        } else {
          if (data.countStatus6 > 0) {
            this.vehicleWidgetData.state = 'WARNING';
          } else {
            this.vehicleWidgetData.state = 'SUCCESS';
          }
        }
      }), error => {
        this.$log.error('Could not load number of vehicle faults', error);
      });
    }
  }

  reload() {
    this.getCategoriesFromServer(true);
    this.getCustomFieldsFromServer(true);

    this.updateWidgets();
  }

  getCategoriesFromServer(forceReload) {
    this.dataService.getShiftbookCategories(forceReload, (response) => {
      this.categories = response;
    }, (errorResponse) => { })
  }

  getCustomFieldsFromServer(forceReload) {
    this.dataService.getShiftbookCustomFields(forceReload, (response) => {
      this.customFields = response;
    }, (errorResponse) => { })
  }

  /**
   * Open current events modal
   */
  openCurrentEvents() {
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Events_Read)) {
      this.$uibModal.open({
        template: require('../../../modals/wache/currentEvents.shiftbook.modal/currentEvents.shiftbook.modal.html'),
        controller: 'CurrentEventsShiftbookModalController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg'
      });
    }
  }

  /**
   * Open current alarms modal
   */
  openCurrentAlarms() {
    if (this.privilegeService.has(RolePrivilege.Home_Alarms_List)) {
      this.$uibModal.open({
        template: require('../../../modals/wache/currentAlarms.shiftbook.modal/currentAlarms.shiftbook.modal.html'),
        controller: 'CurrentAlarmShiftbookModalController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg'
      });
    }
  }

  /**
   * Open current roadblocks modal
   */
  openCurrentRoadblocks() {
    if (this.privilegeService.has(RolePrivilege.Objects_Roadblocks)) {
      this.$uibModal.open({
        template: require('../../../modals/wache/roadblock.shiftbook.modal/roadblock.shiftbook.modal.html'),
        controller: 'RoadblockShiftbookModalController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg'
      });
    }
  }

  openCurrentFaultMessages() {
    if (this.privilegeService.has(RolePrivilege.Station_Vehicles_Faults) || this.privilegeService.has(RolePrivilege.Station_Sirens_Faults)) {
      //Index 1 for "Fahrzeuge" Tab
      this.$rootScope.$emit('tab.change.wache', 'VEHICLES');
    }
  }

  manageCategories() {
    this.$uibModal.open({
      template: require('../../../modals/wache/categories.shiftbook.modal/categories.shiftbook.modal.html'),
      controller: 'CategoriesShiftbookModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        categories: () => {
          return this.categories;
        }
      }
    });
  }

  manageCustomFields() {
    this.$uibModal.open({
      template: require('../../../modals/wache/customFields.shiftbook.modal/customFields.shiftbook.modal.html'),
      controller: 'CustomFieldsShiftbookModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        customFields: () => {
          return this.customFields;
        }
      }
    });
  }

  exportDashboard() {
    this.$window.open(`${this.restService.getBaseUrl()}/shiftbook/export?Authorization=${this.$http.defaults.headers.common.Authorization}`,'_blank');
  }
}
