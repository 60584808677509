'use strict';

export default class AlarmFeedbackPanelComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./alarm.feedbackpanel.component.html');
    this.scope = {
      feedback: '=',
      isFeedbackLoading: '='
    };
    this.controller = AlarmFeedbackPanelComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmFeedbackPanelComponentController {
	public isPositive: any;
	public feedback: any;

  constructor($scope) {
    this.isPositive = false;

    $scope.$watch('ctrl.feedback', (newValue, oldValue) => {
      if (newValue) {
        var statistic = this.feedback.statistic;
        var negative = statistic.no + statistic.vacation;
        var positive = statistic.here + statistic.yes;
        this.isPositive = positive >= negative;
      }
    });

  }
}
