'use strict';
//objectEvent
export default class ObjectEventComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.event.component.html');
    this.scope = {
      alarmObject: '=',
      event: '=',
      isEditable: '='
    };
    this.controller = ObjectEventComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectEventComponentController {
	public $scope: any;
	public start: any;
	public event: any;
	public end: any;
	public endIsValid: any;
	public alarmObject: any;

  constructor($scope) {
    this.$scope = $scope;

    /**
     * Init data changed
     */
    this.$scope.$watch('ctrl.event', () => {
      this.start = new Date(this.event.start);
      this.end = new Date(this.event.end);
      this.endIsValid = this.event.end > this.event.start;
    });
  }

  update() {
    this.event.start = this.start.getTime();
    this.event.end = this.end.getTime();

    this.endIsValid = this.event.end > this.event.start;

  }

  /**
   * Delete existing event
   * @param {*} event 
   */
  delete(event) {
    this.alarmObject.events.splice(this.alarmObject.events.indexOf(event), 1);
  };

}
