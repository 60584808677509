import { IHttpBackendService, IScope, IWindowService } from "angular";
import { Emergency, EmergencyReport } from "../../../../../data/emergency.data";
import {RolePrivilege} from "../../../../../data/privileges.enum";
import PrivilegeService from "../../../../../services/privilege.service";
import RestService from "../../../../../services/rest.service";

require("./mission.export.component.scss")
/* @ngInject */
export default class MissionExportComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./mission.export.component.html');
    this.scope = {
      emergency: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = MissionExportComponentController;

  }
}

class MissionExportComponentController {
  public emergency: Emergency;
  constructor(
    private $window: IWindowService,
    private $http,
    private restService: RestService,
    public privilegeService: PrivilegeService) {

  }

  exportGeoJson() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Export_LocationMap)) {
      return;
    }
    this.$window.open(
      `${this.restService.getBaseUrl()}/lagekarte/geo/${encodeURIComponent(this.emergency.mapId)}?forceReload=true&download=true&Authorization=${this.$http.defaults.headers.common.Authorization}`,
      '_blank'
    );
  }

  exportReport() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Export_Protocol)) {
      return;
    }
    this.$window.open( // add parent user ref for admin access to other organisations location map data
      `${this.restService.getBaseUrl()}/emergency/${encodeURIComponent(this.emergency.externalId)}/report?userId=${encodeURIComponent(this.emergency.parentUserId)}&Authorization=${this.$http.defaults.headers.common.Authorization}`,
      '_blank'
    );
  }

  exportDiary() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Export_Diary)) {
      return;
    }
    this.$window.open( // add parent user ref for admin access to other organisations location map data
      `${this.restService.getBaseUrl()}/emergency/${encodeURIComponent(this.emergency.externalId)}/${encodeURIComponent(this.emergency.mapId)}/diary?userId=${encodeURIComponent(this.emergency.parentUserId)}&Authorization=${this.$http.defaults.headers.common.Authorization}`,
      '_blank'
    );
  }


  exportLocationMap() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Export_Diary)) {
      return;
    }
    this.$window.open(
      `${this.restService.getBaseUrl()}/emergency/${encodeURIComponent(this.emergency.externalId)}/${encodeURIComponent(this.emergency.mapId)}/pdf?userId=${encodeURIComponent(this.emergency.parentUserId)}&Authorization=${this.$http.defaults.headers.common.Authorization}`,
      '_blank'
    );
  }
}
