'use strict';

import { ILogService, IScope } from "angular";
import { AlarmObject } from "../../../../data/objects.data";

//objectPaths
export default class ObjectPathsComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.paths.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectPathsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectPathsComponentController {
  public alarmObject: AlarmObject;
  public isEditable = false;
  public isLoading = false;

  constructor(public $scope: IScope, public $uibModal, public $log: ILogService, public dataService) {
  }

  /**
   * Add a new path
   */
  add() {
    this.$uibModal.open({
      template: require('../../../modals/objects/add.object.modal/add.object.modal.html'),
      controller: 'AddAlarmObjectCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (name) => {
            this.dataService.addPOI(name, this.alarmObject, (poi) => {
              this.alarmObject.paths.push(poi);
              // Open new 
              this.$uibModal.open({
                template: require('../../../modals/objects/edit.poi.modal/edit.poi.modal.html'),
                controller: 'EditPOICtrl',
                controllerAs: 'ctrl',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  poi: () => {
                    return poi;
                  },
                  alarmObject: () => {
                    return this.alarmObject;
                  },
                  editable: () => {
                    return true;
                  }

                }
              });
            }, (response) => {
              //Error occured
              this.$log.error(response);
            });
          }
        }
      }
    });
  };

  /**
   * Edit existing path
   */
  editOrView(path, onlyView: boolean) {
      this.$uibModal.open({
        template: require('../../../modals/objects/edit.poi.modal/edit.poi.modal.html'),
        controller: 'EditPOICtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          poi: () => {
            return path;
          },
          alarmObject: () => {
            // Not assigned to any alarm object
            return this.alarmObject;
          },
          editable: () => {
            return !onlyView && this.isEditable;
          }
        }
      });
  };

  /**
   * Delete existing path
   */
  delete(path) {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.dataService.deletePOIFromAlarmObject(path,
              () => {
                this.isLoading = false;
                this.alarmObject.paths.splice(this.alarmObject.paths.indexOf(path), 1);
              },
              (response) => {
                //Error occured
                this.isLoading = false;
                this.$log.error(response);
              });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  };

}
