'use strict';

import { ILocationService, ILogService, IRootScopeService, IScope } from "angular";
import angular = require("angular");
import { RolePrivilege } from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";

require('./rules.modal.css');
//RulesModalCtrl
/* @ngInject */
export default class RulesModalController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $uibModal: any;
  public $uibModalInstance: any;
  public $state: any;
  public $location: ILocationService;
  public dataService: any;
  public rule: any;
  public mode: any;
  public onlineservice: any;
  public isLoading = false;
  public isEditAllowed = true;
  public isCreateAllowed = true;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $uibModal, $uibModalInstance, $state,
    public privilegeService: PrivilegeService, $location: ILocationService, dataService, rule) {

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.$state = $state;
    this.$location = $location;
    this.dataService = dataService;
    this.rule = rule;
    this.isCreateAllowed = this.rule === undefined && privilegeService.has(RolePrivilege.Availability_Rules_Create);
    this.isEditAllowed = this.rule !== undefined && privilegeService.has(RolePrivilege.Availability_Rules_Edit);

    this.mode = 'EDIT';
    if (!this.rule) {
      this.mode = 'ADD';

      this.rule = {
        name: '',
        severity: 'INFO',
        type: 'AVAILABILITY',
        conditions: [],
        alertUnit: true
      };
    } else {
      this.$log.debug(this.rule);
    }

    this.dataService.getAvailability(false, (onlineservice) => {
      this.onlineservice = onlineservice;
    });

  }

  /**
   * Change the comparison value
   */
  changeComparison(condition, comp) {
    condition.comparison = comp;
    if (comp === 'FUNCTION') {
      condition.comparisonSource = this.onlineservice.data.functions[0];
    } else if (comp === 'GROUP') {
      condition.comparisonSource = this.onlineservice.data.groups[0];
    } else {
      condition.comparisonSource = '';
    }
  }

  /**
   * Add a new condition
   */
  addCondition() {
    this.rule.conditions.push({
      value: 1,
      comparison: 'OVERALL',
      comparisonSource: ''
    });
  };

  removeCondition(condition) {
    this.rule.conditions.splice(this.rule.conditions.indexOf(condition), 1);
  };

  /**
   * Delete entry
   */
  deleteEntry() {

    this.$uibModal.open({
      template: require('../confirm.delete.rule.modal/confirm.delete.rule.modal.html'),
      controller: 'ConfirmDeleteRuleModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.dataService.deleteAvailabilityRule(this.rule, () => {
              this.isLoading = false;
              this.$uibModalInstance.close();
            }, (err) => {
              this.isLoading = false;
              this.$log.error(err);
            });
          };
        }
      }
    });
  }

  save() {
    this.isLoading = true;
    if (this.mode === 'ADD') {
      this.dataService.createAvailabilityRule(this.rule, (data) => {
        this.isLoading = false;
        this.$uibModalInstance.close();
        this.$log.debug(data);
      }, (err) => {
        this.isLoading = false;
        this.$log.error(err);
      });
    } else {
      var toSave = {};
      angular.copy(this.rule, toSave);
      delete toSave['sorting'];
      for (var i = 0; i < toSave['conditions'].length; i++) {
        delete toSave['conditions'][i].isValid;
      }
      this.$log.debug(toSave);
      this.dataService.saveAvailabilityRule(toSave, (data) => {
        this.isLoading = false;
        this.$rootScope.$emit('rule.updated.' + toSave['id'], data);
        this.$uibModalInstance.close();
        this.$log.debug(data);
      }, (err) => {
        this.isLoading = false;
        this.$log.error(err);
      });
    }
  }

  cancel() {
    this.$uibModalInstance.close();
  }
  /**
    Edit the unit
  */
  editUnit() {
    this.$location.search('code', this.rule.id);
    this.$state.go('main.units', {
      code: this.rule.id
    });
    this.cancel();
  };
}
