'use strict';

import { IScope } from "angular";
import angular = require("angular");
import { LogObjectEntryTransferable } from "../../../../data/tracelog.data";

require('./tracelog.plugin.modal.css')

/* @ngInject */
export default class TracelogPluginModalController {
  public $scope: IScope;
  public $uibModalInstance: any;
  public $translate: any;
  public traceLog: LogObjectEntryTransferable[];

  constructor($scope: IScope, $uibModalInstance, $translate, traceLog: LogObjectEntryTransferable[]) {
    this.$scope = $scope
    this.$uibModalInstance = $uibModalInstance
    this.$translate = $translate
    this.traceLog = traceLog;
  }

  close() {
    this.$uibModalInstance.close();
  }
}