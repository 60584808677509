'use strict';

import {ILogService, IScope, IWindowService, IHttpService} from "angular";
import {AddressSettings, AdminAlarmObjectsResponse, AdminStreetsResponse} from "../../../../../data/admin.data";
import RestService from "../../../../../services/rest.service";

require("./admin.address.component.css")

export default class AdminAddressComponent {
    public restrict: string;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
        this.restrict = 'EA';
        this.template = require('./admin.address.component.html');
        this.scope = {
        };
        this.controller = AdminAddressComponentController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class AdminAddressComponentController {

    public settings: AddressSettings;
    public objects: AdminAlarmObjectsResponse;
    private currentPageObject = 1;
    private limitObject = 20;
    public streets: AdminStreetsResponse;
    private currentPageStreet = 1;
    private limitStreet = 20;
    public isLoading: boolean = false;
    public $uibModal: any;
    public FileUploader: any;
    public $window:IWindowService;
    private $http: IHttpService;
    public isLoadingImportObjects:boolean= false;
    public isLoadingImportStreets:boolean= false;


  constructor(private $scope: IScope,
        private $log: ILogService,
        private restService: RestService, $window: IWindowService,$http: IHttpService,$uibModal, FileUploader) {
      this.FileUploader = FileUploader;
      this.$window = $window;
      this.$uibModal = $uibModal;
      this.$http = $http;


      this.load();
    }

    save() {
        this.isLoading = true;
        this.restService.saveAddressSettings(this.settings)
            .catch(err => this.$log.error(err))
            .finally(() => {
                this.isLoading = false;
                this.$scope.$applyAsync();
            })
    }

    load() {
        this.isLoading = true;
        this.restService.loadAddressSettings()
            .then(settings => this.settings = settings)
            .catch(err => this.$log.error(err))
            .finally(() => {
                this.isLoading = false;
                this.$scope.$applyAsync();
            });
      this.loadObjectsCSV();
      this.loadStreetsCSV();
    }

  private loadObjectsCSV() {
    this.isLoading = true;
    this.restService.loadAddressObjects(this.currentPageObject-1, this.limitObject).then((objects) => {
      this.objects = objects;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  private loadStreetsCSV() {
    this.isLoading = true;
    this.restService.loadAddressStreets(this.currentPageStreet-1, this.limitStreet).then((streets) => {
      this.streets = streets;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  getPositionModeForTranslation(): string {
        if(!this.settings) {
            return "POSITION_INTELLIGENT";
        }
        switch  (this.settings.positionMode) {
            case 1: return "POSITION_FIRST"
            case 2: return "POSITION_INTELLIGENT"
            case 3: return "POSITION_JUNCTION"
            default: return "POSITION_INTELLIGENT"
        };
    }

    getNotFoundModeForTranslation(): string {
        if(!this.settings) {
            return "CANCEL";
        }
        switch  (this.settings.notFoundMode) {
            case 1: return "CANCEL"
            case 2: return "ONLY_STREET"
            default: return "CANCEL"
        };
    }
  importObjects(){
      var modalInstance = this.import('/admin/settings/address/objects/upload');
      this.isLoadingImportObjects = true;
    modalInstance.result.then(() => {
      this.loadObjectsCSV();
    });
    this.isLoadingImportObjects = false;

  }

  exportObjects(){
    this.$window.open(
      `${this.restService.getBaseUrl()}/admin/settings/address/objects/download?Authorization=${this.$http.defaults.headers.common.Authorization}`,'_blank'
    );

  }

  importStreets(){
    var modalInstance = this.import('/admin/settings/address/streets/upload');
    this.isLoadingImportStreets = true;
    modalInstance.result.then(() => {
      this.loadStreetsCSV();
    });
    this.isLoadingImportStreets = false;
  }

  exportStreets(){
    this.$window.open(
      `${this.restService.getBaseUrl()}/admin/settings/address/streets/download?Authorization=${this.$http.defaults.headers.common.Authorization}`,'_blank'
    );

  }

  import(url:string):any{
    return this.$uibModal.open({
      template: require('./../../../../modals/misc/import.modal/import.modal.html'),
    controller: 'ImportModalController',
    controllerAs: 'ctrl',
    size: 'sm',
    resolve: {
      uploader: () => {
        let fileUploader = new this.FileUploader();
        return fileUploader;
      },
      url: () => {
        return url;
      }
    }
  });
  }
}