'use strict';
//Controller for availability

/* @ngInject */
export default class PersonAvailabiltyModalController {
	public $uibModalInstance: any;
	public helperService: any;
	public person: any;
	public _color: any;

  constructor($uibModalInstance, helperService, person) {
    this.$uibModalInstance = $uibModalInstance;
    this.helperService = helperService;
    this.person = person;
    this._color = this.helperService.getColorForAvailability(this.person.state);
  }

  close() {
    this.$uibModalInstance.close();
  };
}