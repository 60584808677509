import { ILogService, IRootScopeService, IScope } from "angular";
import { AAOTime, AAOTimeDay } from "../../../../../../data/aao.data";

require('./aao.day.css');

export default class AAODayComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./aao.day.html');
    this.scope = {
      entry: '=',
      day: '='
    };
    this.controller = AAODayComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AAODayComponentController {
  public $log: ILogService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public isLoading: boolean;

  public day = '';
  public entry: AAOTimeDay;
  private HOLIDAY_TRANSLATION = "Feiertage";

  constructor($log: ILogService, $scope: IScope, $rootScope: IRootScopeService, $translate: any) {
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$scope = $scope;
    $translate(['AAO.HOLIDAYS']).then(trans => {
      this.HOLIDAY_TRANSLATION = trans['AAO.HOLIDAYS'];
    })
  }

  public getToolTip() {
    if (!this.entry) return '';
    if (this.day === 'holiday') {
      return `${this.HOLIDAY_TRANSLATION}
      ${this.entry.start} - ${this.entry.end}`;
    };
    return `${this.entry.start} - ${this.entry.end}`;
  }
}