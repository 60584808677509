'use strict';

import {IRootScopeService, IScope} from "angular";
import {PrivacyMappingResponse, PrivacySettingsResponse} from "../../../../../data/admin.settings";
import RestService from "../../../../../services/rest.service";

require('./admin.privacy.component.css');

export default class AdminPrivacySettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.privacy.component.html');
    this.scope = {};
    this.controller = AdminPrivacyComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminPrivacyComponentController {
  private isLoading: boolean = false;
  private restService: RestService;
  private settings: PrivacySettingsResponse;
  private $scope: IScope;
  private $uibModal: any;
  public isLoadingPrivacyMapping: boolean = false;
  public FileUploader: any;
  public $window: any;
  public $http: any;
  public privacyMappings: PrivacyMappingResponse[];

  constructor($scope: IScope, $rootScope: IRootScopeService, restService: RestService, $uibModal: any, FileUploader: any, $window: any, $http: any) {
    this.restService = restService;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.FileUploader = FileUploader;
    this.$http = $http;
    this.$window = $window;

    this.load();
    this.loadPrivacyMappings();
  }


  /**
   * Load privacy settings
   */
  load() {
    this.isLoading = true;
    this.restService.getAdminPrivacySettings().then(response => {
      this.settings = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Save privacy settings
   */
  save() {
    this.isLoading = true;
    this.restService.saveAdminPrivacySettings(this.settings).then(response => {
      this.settings = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  uploadMapping() {
    var modalInstance = this.import('/admin/settings/privacy/mapping/upload');
    this.isLoadingPrivacyMapping = true;
    modalInstance.result.then(() => {
      this.loadPrivacyMappings();
    });
    this.isLoadingPrivacyMapping = false;
  }

  loadPrivacyMappings() {
    this.isLoadingPrivacyMapping = true;
    this.restService.getAdminPrivacyMappings().then((privacyMappings) => {
      this.privacyMappings = privacyMappings;
    }).finally(() => {
      this.isLoadingPrivacyMapping = false;
      this.$scope.$applyAsync();
    })
  }

  import(url: string): any {
    return this.$uibModal.open({
      template: require('./../../../../modals/misc/import.modal/import.modal.html'),
      controller: 'ImportModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        uploader: () => {
          let fileUploader = new this.FileUploader();
          return fileUploader;
        },
        url: () => {
          return url;
        }
      }
    });
  }

  exportPrivacyMappings() {
    this.$window.open(
      `${this.restService.getBaseUrl()}/admin/settings/privacy/mapping/download?Authorization=${this.$http.defaults.headers.common.Authorization}`, '_blank'
    );
  }


}

