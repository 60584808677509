import ErrorService from "../../../../services/error.service";
import * as angular from "angular";
import {ILogService, IRootScopeService, IScope} from "angular";
import RestService from "../../../../services/rest.service";

require('./import.units.modal.scss')
export class ImportUnitsModalController {
  public $uibModalInstance: any;
  public uploader: any;
  public isLoading: boolean = false;
  public errorService: ErrorService;
  public $log: ILogService;
  public $http: any;
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModalInstance, uploader, errorService: ErrorService, $log: ILogService, $http, restService: RestService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.uploader = uploader;
    this.errorService = errorService;
    this.$log = $log;
    this.$http = $http;
    this.restService = restService;

    this.uploader.onCompleteAll = () => {
      this.$log.info('onCompleteAll');
      this.$uibModalInstance.close();
    }
    this.uploader.onBeforeUploadItem = (item) => {
      this.$log.info("import");
      item.url = this.restService.getBaseUrl() + '/admin/units/import/csv'
    };

    this.uploader.onSuccessItem = (item: any, response: string, status: any, headers: any)=> {
      if (angular.isUndefined(response) || response === '') {
        this.$uibModalInstance.close();
        return;
      }
    }
    this.uploader.headers = {
        Authorization: this.$http.defaults.headers.common.Authorization
    }

  }

  import(){
    this.isLoading = true;
    this.uploader.onErrorItem = (item, response, status) =>{
      this.errorService.notifyWithText(response.message);
    };
    this.uploader.uploadAll();
  }

  cancel(){
    this.$uibModalInstance.close();
  }
}