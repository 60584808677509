import { Protocol } from './../../../../../data/alarm.data';
import { IScope } from "angular";
import { Emergency, MissionTimeLineEntry, TimeLineType } from "../../../../../data/emergency.data";
import { EFeedbackState } from '../../../../../data/customFeedback.data';
import HelperService from '../../../../../services/helper.service';

require("./mission.timeline.component.scss")
/* @ngInject */
export default class MissionTimelineComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./mission.timeline.component.html');
    this.scope = {
      emergency: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = MissionTimelineComponentController;

  }
}

class MissionTimelineComponentController {
  public emergency: Emergency;
  public protocol: Protocol[] = [];

  constructor(private $scope: IScope, private helperService: HelperService) {
    $scope.$watch('ctrl.emergency', (oldValue, emergency: Emergency) => {
      if (emergency) {
        this.protocol = emergency.protocol;
      }
    });
  }

  getBackgroundColorForFeedback(feedback) {
    return this.helperService.getBackgroundColorForFeedback(feedback);
  }

  getIconClassForFeedback(feedback: EFeedbackState) {
    return this.helperService.getIconClassForFeedback(feedback);
  }
}
