import { AlarmObject } from './../../../../data/objects.data';
'use strict';
import { ILogService } from "angular";
import angular = require("angular");
import RestService from '../../../../services/rest.service';
//objectAddress
export default class ObjectAddressComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.address.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectAddressComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectAddressComponentController {
  public $log: ILogService;
  public $uibModal: any;
  public isGeocoding: boolean;
  public restService: RestService;
  public alarmObject: AlarmObject;

  constructor($log: ILogService, $uibModal, restService: RestService) {
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.isGeocoding = false;
    this.restService = restService;
  }

  show() {
    //Update
    this.$uibModal.open({
      template: require('../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseOnMapCtrl',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return this.alarmObject.address.coords;
        },
        okFunction: () => {
          return (coords) => {
            this.alarmObject.address.coords = coords;
          }
        }
      }
    });
  };

  /**
   * Geocode the current address
   */
  geocode() {
    var adr = this.alarmObject.address;
    var location = '';
    if (angular.isDefined(adr.street)) {
      location = adr.street;
    } else {
      return;
    }

    if (angular.isDefined(adr.house)) {
      location = location + ' ' + adr.house;
    }

    if (angular.isDefined(adr.postalCode)) {
      location = location + ' ' + adr.postalCode;
    }

    if (angular.isDefined(adr.city)) {
      location = location + ' ' + adr.city;
    } else {
      return;
    }

    location = location.trim();
    if (location === '') {
      return;
    }
    this.$log.debug('Geocoding: ' + location);
    this.isGeocoding = true;
    this.restService.geocode(location, (response) => {
      this.isGeocoding = false;
      this.$log.debug(response.data);
      this.alarmObject.address.coords.lat = response.data.latitude;
      this.alarmObject.address.coords.lng = response.data.longitude;
    }, (error) => {
      this.isGeocoding = false;
      this.$log.error(error);
    });
  };
}