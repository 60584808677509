'use strict';

import { ILogService, IRootScopeService } from "angular";
import RestService from "./rest.service";

/* @ngInject */
export default class MessagesService {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public dataService: any;
  public restService: RestService;
  public messages = [];

  constructor($log: ILogService, $rootScope: IRootScopeService, dataService, restService: RestService) {
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.restService = restService;
    this.dataService = dataService;

    $log.debug('Init MessagesService');
  }

  /**
   * Get all planned messages
   */
  getMessages(success, error) {
    this.restService.getMessages((response) => {
      this.messages = response.data;
      success(response.data);
    }, (errorResponse) => error(errorResponse));
  };

  /**
   * Clear all old messages
   * @param {} success 
   * @param {*} error 
   */
  clearMessages(success, error) {
    this.restService.clearMessages((response) => {
      this.messages = response.data;
      success(response.data);
    }, (errorResponse) => error(errorResponse));
  };

  /**
   * Add a new message
   * @param {} message 
   * @param {*} success 
   * @param {*} error 
   */
  addMessage(message, success, error) {
    this.restService.addMessage(message, (response) => {
      this.messages.push(response.data);
      this.$rootScope.$emit('alarm.messages', this.messages);

      success(response.data);
    }, error);
  };

  /**
   * Add a new message
   * @param {} message 
   * @param {*} success 
   * @param {*} error 
   */
  sendMessage(message, success, error) {
    this.restService.addMessage(message, (response) => {
      success(response.data);
    }, error);
  };

  /**
   * Update an existing shiftbook message
   * @param {} message 
   * @param {*} success 
   * @param {*} error 
   */
  updateMessage(message, success, error) {
    this.restService.updateMessage(message, (response) => {
      success(response.data);
    }, error);
  };

  /**
   * Delete an existing message
   * @param {} message 
   * @param {*} success 
   * @param {*} error 
   */
  deleteMessage(message, success, error) {
    this.restService.deleteMessage(message, () => {
      this.messages.splice(this.messages.indexOf(message), 1);
      this.$rootScope.$emit('alarm.messages', this.messages);
      success();
    }, error);
  };
};
