import { IScope, IRootScopeService, ILogService } from "angular";
import { AdjustLicenceType, LicenceDistribution, UserLicenceResponse as UserLicence } from "../../../../data/admin.data";
import RestService from "../../../../services/rest.service";

require("./licence.distribution.modal.scss")
export default class LicenceDistributionModalController {

    public licenceDistribution: LicenceDistribution;

    constructor(private readonly $scope: IScope,
        private readonly $rootScope: IRootScopeService,
        private readonly $uibModalInstance,
        private readonly restService: RestService,
        licence: AdjustLicenceType,
        public readonly translation: string,
        private readonly $log: ILogService) {
        this.restService.loadLicenceDistribution(licence)
            .then(distribution => {
                this.licenceDistribution = distribution;
                this.$scope.$applyAsync();
            }).catch(err => this.$log.error(err));
    }

    public cancel() {
        this.$uibModalInstance.close();
    }

    public getAvailableLabelClass(userLicence: UserLicence) {
        const available = userLicence.assigned - userLicence.used;
        if (userLicence.assigned === 0) return "label-danger";
        const fraction = available / userLicence.assigned;
        if (fraction > 0.1) return "label-success";
        if (fraction > 0) return "label-warning";
        return "label-danger";
    }
}