import angular from 'angular';
import 'angular-ui-bootstrap'

import services from '../../../services'
import AvailabilityAccessController from './access.component/access.component';
import AvailabilityBarComponent from './bar.component/bar.component';
import AvailabilityChartsComponent from './charts.component/charts.component';
import AvailabilityColumnComponent from './column.component/column.component';
import AvailabilityDayComponent from './day.component/day.component';
import AvailabilityFunctionSummaryComponent from './functions.summary.component/functions.summary.component';
import AvailabilityFutureComponent from './future.component/future.component';
import AvailabilityFutureEntryComponent from './future.entry.component/future.entry.component';
import AvailabilityFutureFunctionsComponent from './future.functions.component/future.functions.component';
import AvailabilityListComponent from './list.component/list.component';
import AvailabilityMonthComponent from './month.component/month.component';
import AvailabilityOverallSummaryComponent from './overall.summary.component/overall.summary.component';
import AvailabilityRuleEntryComponent from './rule.entry.component/rule.entry.component';
import AvailabilityRulesComponent from './rules.component/rules.component';
import AvailabilitySummaryComponent from './summary.component/summary.component';
import AvailabilityTablesComponent from './table.component/table.component';
import AvailabilityWeekComponent from './week.component/week.component';
import PersonListEntryComponent from './list.component/list.entry';

export default angular.module('FE2.components.availability', ['ui.bootstrap', services])
  //.controller('MainController', MainController)
  .directive('availabilityAccess', () => new AvailabilityAccessController())
  .directive('availabilityBar', () => new AvailabilityBarComponent())
  .directive('availabilityCharts', () => new AvailabilityChartsComponent())
  .directive('availabilityColumn', () => new AvailabilityColumnComponent())
  .directive('availabilityDay', () => new AvailabilityDayComponent)
  .directive('availabilityFunctionSummary', () => new AvailabilityFunctionSummaryComponent())
  .directive('availabilityFuture', () => new AvailabilityFutureComponent())
  .directive('availabilityFutureEntry', () => new AvailabilityFutureEntryComponent())
  .directive('availabilityFutureFunctions', () => new AvailabilityFutureFunctionsComponent())
  .directive('availabilityList', () => new AvailabilityListComponent())
  .directive('availabilityMonth', () => new AvailabilityMonthComponent())
  .directive('availabilityOverallSummary', () => new AvailabilityOverallSummaryComponent())
  .directive('availabilityRuleEntry', () => new AvailabilityRuleEntryComponent())
  .directive('availabilityRules', () => new AvailabilityRulesComponent())
  .directive('availabilitySummary', () => new AvailabilitySummaryComponent())
  .directive('availabilityTable', () => new AvailabilityTablesComponent())
  .directive('availabilityWeek', () => new AvailabilityWeekComponent())
  .directive('personListEntry', () => new PersonListEntryComponent())
  .name;