import { ILogService } from 'angular';
import { IScope } from 'angular';
import RestService from '../../../../services/rest.service';
'use strict';

export default class UnitSelectComponent {
	public restrict: any;
	public scope: any;
	public template: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A',
    this.scope = {
      sound: '='
    }
    this.template = require('./unit.select.component.html');

    this.controller = UnitSelectController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//unitSelect
/* @ngInject */
class UnitSelectController {
	public $scope: IScope;
	public $log: any;
	public dataService: any;
	public restService: RestService;
	public units: any;
	public sound: any;

  constructor($scope: IScope, $log: ILogService, dataService, restService: RestService) {
    this.$scope = $scope;
    this.$log = $log;
    this.dataService = dataService;
    this.restService = restService;

    var account = dataService.getAccount();

    this.$log.debug('Init unit select directive');

    this.restService.loadUnits(units => {
      this.units = units[account.username];
    }, err => {
      //Did not work
      this.$log.error('Could not load all units');
    });
  }

  select(unit) {
    this.sound.name = unit.name;
    this.sound.matching = true;
  }
}
