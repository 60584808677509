export interface LogObjectTransferable {
  alarmId: string;
  unitId: string;
  timestamp: string;
}

export interface LogObjectEntryTransferable {
  id: string;
  parentId: string;
  timestamp: string;
  level: LogLevel;
  message: string;
  pluginId: string;
}

export enum LogLevel {
  TRACE = 'TRACE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}