'use strict';

import { IDocumentService, IScope } from "angular";
import * as L from "leaflet";
import { Coords } from "../../../../data/objects.data";
import MapService from "../../../../services/map.service";

/* @ngInject */
export default class ChooseOnMapController {
  public $uibModalInstance: any;
  public $scope: IScope;
  public mapService: MapService;
  public coords: Coords;
  public okFunction: any;
  public listeners: any;
  public marker: any;
  public map: any;

  constructor($uibModalInstance, $scope: IScope, $document: IDocumentService, mapService: MapService, coords: Coords, okFunction) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.mapService = mapService;
    this.coords = coords;
    this.okFunction = okFunction;
    this.listeners = [];

    $document.ready(() => {
      this.initMap();
    });
  }


  initMap() {
    L.Icon.Default.imagePath = '/img/static';
    this.map = L.map('mapid').setView([this.coords.lat, this.coords.lng], 18);
    this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');

    let layers = this.mapService.getBaseLayers();

    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService;
    this.map.on('baselayerchange', function (e) {
      mapService.saveLayer(e.name);
    });

    this.marker = L.marker(L.latLng(this.coords.lat, this.coords.lng), { draggable: true }).addTo(this.map);

    this.map.on('click', (ev) => {
      this.markerMovedEvent(ev.latlng);
    });

    this.marker.on('dragend', (e) => {
      this.markerMovedEvent(e.target.getLatLng());
    });
  }

  /**
   * Should be triggered, if marker should be moved
   * @param latLng
   */
  markerMovedEvent(latLng: L.LatLng) {
    this.marker.setLatLng(latLng);
    this.coords.lat = latLng.lat;
    this.coords.lng = latLng.lng;
    this.$scope.$applyAsync();
  }

  updateMap() {
    const coords = L.latLng(this.coords.lat, this.coords.lng);
    this.markerMovedEvent(coords);
    this.map.setView(coords);
  }


  ok() {
    this.$uibModalInstance.close();
    this.okFunction(this.coords);
  }
}
