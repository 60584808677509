import RestService from "../../../../services/rest.service";
import {IRootScopeService, IScope} from "angular";
import angular = require("angular");
import {User} from "../../../../data/admin.data";
import { IOBattery } from "../../../../data/iobattery.data";
import PrivilegeService from '../../../../services/privilege.service';

require('./admin.users.iobattery.component.css')

export default class UserIOBatteryComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.users.iobattery.component.html');
    this.scope = {
      user: '='
    };
    this.controller = UserIOBatteryController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
/* @ngInject */
class UserIOBatteryController {
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public user: User;
  public ioBatteries: IOBattery[] =[];
  public selectedIOBatteries: IOBattery[]= [];
  public isLoadingIOBatteries:boolean;
  public isLoadingIOBatteriesWithAccess: boolean;
  public isSaving = false;

  constructor(restService: RestService, $scope: angular.IScope, $rootScope: angular.IRootScopeService, public privilegeService: PrivilegeService) {
    this.restService = restService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.selectedIOBatteries = [];
    this.ioBatteries = [];
    // get user when it is set, otherwise user will always be undefined
    $scope.$watch('ctrl.user', (oldValue, newValue: User) => {
      if (angular.isDefined(newValue)) {
        this.user = newValue
        if (this.user.admin) {
          return; // Don't need to load anything for admin
        }
        this.loadIOBatteriesWithAccess();
        this.loadIOBatteries();
      }
    });
  }

  removeFromOrganisation(ioBattery:IOBattery){
    this.isSaving = true;
    this.restService.removeAccessToIOBattery(this.user, ioBattery)
      .then(() => {
        this.isSaving = false;
        this.$scope.$applyAsync();
        this.loadIOBatteriesWithAccess();
      })
  }

  addToOrganisation(ioBattery:IOBattery){
    this.isSaving = true;
    this.restService.addAccessToIOBattery(this.user, ioBattery)
    .then(() => {
        this.isSaving = false;
        this.$scope.$applyAsync();
        this.loadIOBatteriesWithAccess();
      }
    );
  }

  isSelected(ioBattery:IOBattery){
    if (angular.isUndefined(this.selectedIOBatteries)){
      return;
    }
    var list = this.selectedIOBatteries.filter(compareTo => ioBattery.dataStoreId === compareTo.dataStoreId);
    return list.length ===1;
  }

  /**
   * load all iobatteries from backend
   */
  loadIOBatteries(){
    this.isLoadingIOBatteries = true;
    this.restService.loadAllIOBatteries().then((result:IOBattery[]) =>{
      if (angular.isDefined(result)) {
        this.ioBatteries = result;
      }
    }).finally(() => {
      this.isLoadingIOBatteries = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * load iobatteries that organisation has access to from backend
   */
  loadIOBatteriesWithAccess(){
    this.isLoadingIOBatteriesWithAccess = true;
    if(angular.isUndefined(this.user)){
      this.isLoadingIOBatteriesWithAccess = false;
      return;
    }
    this.restService.loadIOBatteriesForUser(this.user)
      .then((result:IOBattery[]) =>{
        if (angular.isDefined(result)) {
          this.selectedIOBatteries = result;
        }
      }
    ).finally(()=>{
      this.isLoadingIOBatteriesWithAccess = false;
      this.$scope.$applyAsync();
    })
  }
}