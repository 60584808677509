'use strict';

import angular = require('angular');

export default class ObjectExinguishComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.extinguish.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectExinguishComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectExinguishComponentController {
	public $scope: any;
	public $uibModal: any;
	public types: any;
	public hasBMA: any;
	public selected: any;
	public alarmObject: any;
	public isEditable: any;

  constructor($scope, $uibModal) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.types = ['WATER', 'CO2', 'XENON', 'ARGON', 'FOAM', 'SAND', 'INTERTING', 'FEED'];
    this.hasBMA = false;
    this.selected = undefined;

    /**
     * Init data changed
     */
    this.$scope.$watch('ctrl.alarmObject', () => {
      if (angular.isDefined(this.alarmObject)) {
        this.hasBMA = angular.isDefined(this.alarmObject.bma) && this.alarmObject.bma !== null;
      }
    });
  }

  /**
   * Add a new extinguish
   */
  addExtinguish() {
    var data = {
      type: 'WATER',
      note: '',
      location: ''
    }

    if (angular.isUndefined(this.alarmObject.bma.extinguishes)) {
      this.alarmObject.bma.extinguishes = [];
    }
    this.alarmObject.bma.extinguishes.push(data);
  };

  /**
   * Delete existing extinguish
   * @param {*} data
   */
  deleteExtinguish(data) {
    this.alarmObject.bma.extinguishes.splice(this.alarmObject.bma.extinguishes.indexOf(data), 1);
  };

  /**
   * Show on map
   * @param {*} element
   */
  showOnMap(element) {
    if (this.isEditable) {
      if (angular.isUndefined(element.coords)) {
        element.coords = {
          lat: this.alarmObject.address.coords.lat,
          lng: this.alarmObject.address.coords.lng,
          showOnMap: true
        }
      }
      this.$uibModal.open({
        template: require('../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
        controller: 'ChooseOnMapCtrl',
        controllerAs: 'ctrl',
        size: 'lg',
        resolve: {
          coords: () => {
            return element.coords;
          },
          okFunction: () => {
            return (coords) => {
              element.coords = coords;
            }
          }
        }
      });
    };
  }
}