'use strict';

import {ProtocolEntry, RestProtocolSettings, RestProtocolSlice} from "../../../../data/admin.data";
import RestService from "../../../../services/rest.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";

require('./admin.rest.protocol.component.css');

export default class AdminRestProtocolComponent {
  public restrict: any;
  public template: any;
  public controller: any;
  public controllerAs: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.rest.protocol.component.html');
    this.controller = AdminRestProtocolComponentController;
    this.controllerAs = 'ctrl';
  }
}

/* @ngInject */
class AdminRestProtocolComponentController {
  public $uibModal: any;
  public $rootScope: angular.IRootScopeService;
  public privilegeService: PrivilegeService;
  public $log: angular.ILogService;
  public restService: any;
  public Notification: any;
  public $translate: any;
  public data: RestProtocolSlice;
  public limit: number;
  public listeners = [];
  public isLoading: boolean = true;
  public isDeleting = false;
  public searchFilter: string = '';
  public totalElements: number;
  public restProtocolSettings: RestProtocolSettings;

  public allMethods = [];

  public jumpToDate: Date;
  public isDateSearch = false;
  public filter = {
    searchFilter: '',
    searchMethod: [],
    reverseOrdering: false
  } as PageFilter;

  constructor($rootScope: angular.IRootScopeService, $uibModal, $log: angular.ILogService, restService: RestService, Notification, $translate, privilegeService: PrivilegeService) {

    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.restService = restService;
    this.Notification = Notification;
    this.$translate = $translate;
    this.privilegeService = privilegeService;

    this.allMethods = ['GET', 'POST', 'PUT', 'DELETE', 'HEAD', 'PATCH', 'OPTIONS', 'TRACE'];

    this.data = {
      number: 1
    } as RestProtocolSlice

    this.jumpToDate = new Date();
    this.jumpToDate.setMilliseconds(0);
    this.jumpToDate.setSeconds(0);

    this.limit = 50;

    this.loadRestProtocolSettings();
    this.pageChanged();
  }

  hasSettingsPrivilege() {
    return this.privilegeService.has(RolePrivilege.Admin_Rest_Protocol);
  }

  loadRestProtocolSettings() {
    this.restService.loadRestProtocolSettings()
      .then(settings => {
        this.restProtocolSettings = settings;
      }).catch(err => {
      this.$log.error(err);
    });
  }

  openAdditional(entry: ProtocolEntry) {

    this.$uibModal.open({
      template: require('../../../modals/admin/rest.protocol.modal/rest.protocol.modal.html'),
      controller: 'RestProtocolModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        entryId: () => {
          return entry.id;
        }
      }
    });
  }

  getMethodsFilter() {
    return this.filter.searchMethod.join(";");
  }

  pageChanged() {
    this.isLoading = true;
    //Current page starts with 0
    this.restService.getRestProtocols(this.data.number - 1, this.limit, this.filter.searchFilter, this.isDateSearch ? this.jumpToDate.getTime() : 0, this.getMethodsFilter(), (response) => {
      this.isLoading = false;
      this.data = response.data;
      this.data.number = this.data.number + 1;
      this.totalElements = this.data.number * this.data.size;
      if (!this.data.last) {
        this.totalElements = this.totalElements + this.data.size;
      }
    }, (error) => {
      this.isLoading = false;
      this.$log.error(error);
    });
  };

  previousPage() {
    if (this.data.number > 1) {
      this.data.number = this.data.number - 1;
      this.pageChanged();
    }
  }

  nextPage() {
    if (!this.data.last) {
      this.data.number = this.data.number + 1;
      this.pageChanged();
    }
  }

  refresh() {
    this.data.number = 1;
    this.pageChanged();
  }


  searchForDate() {
    if (!this.jumpToDate) return;
    this.isDateSearch = true;
    this.refresh();
  }

  resetDateSearch() {
    this.isDateSearch = false;
    this.jumpToDate = new Date();
    this.jumpToDate.setMilliseconds(0);
    this.jumpToDate.setSeconds(0);
    this.refresh();
  }

  onSearchFilterChanged() {
    this.refresh();
  };

  resetSearchFilter() {
    this.filter.searchFilter = '';
    this.refresh();
  };


  /**
   * Reset the search filter
   * */
  resetSearchAndReload() {
    this.searchFilter = '';
    this.refresh();
  };

  doFilteringMethod(method) {
    if (this.filter.searchMethod.includes(method)) {
      this.filter.searchMethod.splice(this.filter.searchMethod.indexOf(method), 1)
    } else {
      this.filter.searchMethod.push(method);
    }
    this.pageChanged();
  }

  isNotFilteredMethod(method) {
    return this.filter.searchMethod.includes(method);
  }

  getMethodClass(method) {
    return "label label-default ng-binding class-" + method;
  }

  getResponseStatusClass(status) {
    if (status < 200) {
      return "label label-default ng-binding class-status-1xx";
    }
    if (status >= 200 && status < 300) {
      return "label label-default ng-binding class-status-2xx";
    }
    if (status >= 300 && status < 400) {
      return "label label-default ng-binding class-status-3xx";
    }
    if (status >= 400 && status < 500) {
      return "label label-default ng-binding class-status-4xx";
    }
    if (status >= 500) {
      return "label label-default ng-binding class-status-5xx";
    }
  }

}

interface PageFilter {
  searchFilter: string,
  searchMethod: string[],
  orderByField: string,
  reverseOrdering: boolean
}
