'use strict';

import angular = require('angular');

export default class ObjectRisksComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.risks.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectRisksComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectRisksComponentController {
	public $scope: any;
	public $uibModal: any;
	public types: any;
	public stagingRoomTypes: any;
	public alarmObject: any;
	public isEditable: any;

  constructor($scope, $uibModal) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.types = ['MEETING_POINT', 'STAGING_AREA', 'SPECIAL_ACCESS', 'AVAILABILITY_AREA', 'RMHP', 'TREATMENT_PLACE', 'LOADING_ZONE', 'PATIENT_POINT', 'CARE_POINT', 'CONTACT_POINT', 'LADDER_POINT'];
    this.stagingRoomTypes = ['FW', 'RD', 'AIR', 'SONST'];

  }


  /**
   * Add a new hint
   */
  add() {
    var data = {
      type: 'MEETING_POINT',
      note: '',
      coords: {lat: 0, lng: 0, showOnMap: false}
    }

    //Init with default
    if (angular.isDefined(this.alarmObject.address) && angular.isDefined(this.alarmObject.address.coords)) {
      data.coords.lat = this.alarmObject.address.coords.lat;
      data.coords.lng = this.alarmObject.address.coords.lng;
      data.coords.showOnMap = true;
    }


    if (angular.isUndefined(this.alarmObject.hints)) {
      this.alarmObject.hints = [];
    }

    this.alarmObject.hints.push(data);
  };


  actionOnType(hint, type) {
    if (type !== 'STAGING_AREA') {
      hint.stagingRoomType = undefined;
    } else {
      hint.stagingRoomType = 'FW';
    }
  }


  /**
   * Delete existing hint
   * @param {*} data
   */
  delete(data) {
    this.alarmObject.hints.splice(this.alarmObject.hints.indexOf(data), 1);
  };

  /**
   * Show on map
   * @param {*} element
   */
  showOnMap(element) {
    if (this.isEditable) {
      if (angular.isUndefined(element.coords)) {
        element.coords = {
          lat: this.alarmObject.address.coords.lat,
          lng: this.alarmObject.address.coords.lng,
          showOnMap: true
        }
      }
      this.$uibModal.open({
        template: require('../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
        controller: 'ChooseOnMapCtrl',
        controllerAs: 'ctrl',
        size: 'lg',
        resolve: {
          coords: () => {
            return element.coords;
          },
          okFunction: () => {
            return (coords) => {
              element.coords = coords;
            }
          }
        }
      });
    }
    ;
  }
}
