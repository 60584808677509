'use strict';

//numberOfUnits
/* @ngInject */
export default class NumberOfUnitsFilter {
  static filter(nbr) {
    switch (nbr) {
      case 0:
        return 'keine Einheit';
      case 1:
        return 'eine Einheit';
      default:
        return nbr + ' Einheiten';
    }
  }
}
