'use strict';

import {ILogService} from "angular";
import {RolePrivilege} from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";

require('./custom.field.component.css');

export default class CustomFieldComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA',
      this.scope = {
        field: '='
      }
    this.template = require('./custom.field.component.html');

    this.controller = CustomFieldComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class CustomFieldComponentController {
  public $log: ILogService;
  public dashboardService: any;
  public isLoading: any;
  public field: any;

  constructor($log: ILogService, dashboardService, public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.dashboardService = dashboardService;
    this.isLoading = false;
  }

  saveData() {
    if (!this.privilegeService.has(RolePrivilege.Station_Shiftbook_Fields_Edit)) {
      return
    }
    this.isLoading = true;

    this.dashboardService.updateCustomField(this.field, () => {
      this.isLoading = false;
    }, (errorResponse) => {
      this.$log.error('Could not update custom field', errorResponse);
      this.isLoading = false;
    });
  }
}
