'use strict'

import { HospitalServices } from "../../../../data/objects.data";

require("./hospital.services.component.scss");

export default class HospitalServicesComponent {
    public restrict: any;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: any;
    public bindToController: any;

    constructor() {
        this.restrict = 'E'
        this.template = require('./hospital.services.component.html');
        this.scope = {
            services: "=",
            isEditable: "="
        };
        this.controller = HospitalServicesComponentController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class HospitalServicesComponentController {

    public isEditable: boolean;
    public services: HospitalServices;
    
    constructor() {
    }
}