'use strict';
import {UserAccount} from "../data/account.data";
import {ILogService, IRootScopeService} from "angular";
import * as angular from "angular";
import { RolePrivilege } from "../data/privileges.enum";

/* @ngInject */
export default class PrivilegeService {

  public privileges: Map<RolePrivilege, boolean>;
  public account:UserAccount;
  public $log: ILogService;
  public $rootScope: IRootScopeService;

  constructor($log: ILogService, $rootScope: IRootScopeService) {
    this.$log = $log;
    this.$rootScope = $rootScope;
  }
  setAccount(account: UserAccount) {
    this.account = account;
    this.privileges = this.account.privileges as Map<RolePrivilege, boolean>;
  }

  has(privilege: RolePrivilege): boolean {
    if (!this.account || !this.account.privileges) {
      return false;
    }
    return this.account.privileges[privilege];
  }

}