'use strict';

import {IScope} from 'angular';
import {LocationMapTask} from '../../../../data/locationmap.data';
import HelperService from '../../../../services/helper.service';

require('./queue.task.entry.scss');

export default class QueueTaskEntryComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      entry: '='
    }
    this.template = require('./queue.task.entry.html');

    this.controller = QueueTaskEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class QueueTaskEntryComponentController {

  public entry: LocationMapTask;
  public creationDate: Date = new Date();

  constructor(public $scope: IScope, private helperService: HelperService) {
    this.$scope.$watch('ctrl.entry', () => {
      this.creationDate = new Date(this.entry.creationTime);
      this.$scope.$applyAsync();
    });
  }

  humanReadableDateTime() {

    return this.helperService.humanReadableDateTime(this.creationDate.getTime());
  }
}