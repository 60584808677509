'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { PersonsAddressbookResponse } from "../../../../data/person.data";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";

/**
 * Controller for modal which displays a single function with all persons from addressbook
 *
 */
/* @ngInject */
export default class SingleSelectModalController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public addressbook: PersonsAddressbookResponse;
  public restService: RestService;
  public $uibModalInstance: any;
  public selectName: string;
  public selectType: string;
  public filterForApager: boolean = true;

  public params = {
    sortType: 'displayName',
    sortReverse: false,
    searchFilter: '',
    currentPage: 0,
    totalElements: 0,
    pageSize: 20
  } as SortParams;
  public isLoading = true;

  constructor($scope: IScope, $log: ILogService, $uibModalInstance, restService: RestService, $rootScope: IRootScopeService, selectName: string, selectType: string, filterForApager: boolean) {
    this.$scope = $scope;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.selectName = selectName;
    this.selectType = selectType;
    this.filterForApager = filterForApager;


    this.pageChanged();
  }

  pageChanged(search?: boolean) {
    if (search) {
      this.params.currentPage = 1;
    }
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadPersons(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.searchFilter, this.params.sortReverse ? 'DESC' : 'ASC', this.params.pageSize, this.filterForApager ? 'aPagerPro' : '', true)
        .then((result: PersonsAddressbookResponse) => {
          this.addressbook = result;
          this.params.totalElements = this.addressbook.totalElements;
          this.params.totalPages = this.addressbook.totalPages;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }

  cancel() {
    this.$uibModalInstance.close();
  }
}