'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import {UserAccount} from "../../../../data/account.data";
import {DocumentTag} from "../../../../data/objects.data";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./document.tag.modal.css');

/* @ngInject */
export default class DocumentTagModalController {
  public $rootScope: any;
  public $uibModal: any;
  public $uibModalInstance: any;
  public $log: any;
  public dataService: any;
  public helperService: HelperService;
  public account: UserAccount;
  public isEditable = false;
  public isAdd = false;
  public allTags: DocumentTag[];
  public allGlobalTags: DocumentTag[];
  public isLoading: boolean;
  public editing: DocumentTag;
  public okFunction: any;

  constructor($rootScope: IRootScopeService, $uibModal, $uibModalInstance, $log: ILogService, dataService, helperService: HelperService,
    public privilegeService: PrivilegeService, private restService: RestService, private $scope: IScope, okFunction) {
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.dataService = dataService;
    this.helperService = helperService;
    this.okFunction = okFunction;
    this.$log.debug('Opening DocumentTagModalController...');
    this.loadTags();
  }

  loadTags() {
    this.restService.getObjectDocumentTagsAll().then(response => {
      this.allTags = response.tags;
      this.allGlobalTags = response.globalTags;
      this.$scope.$apply();
    }).catch(error => {
      this.$log.error(error);
      this.$scope.$apply();
    });
  }

  addTag() {
    if (!this.privilegeService.has(RolePrivilege.Objects_ObjectDocument_Tag_Create)) {
      return;
    }
    this.isEditable = false;
    this.isAdd = true;
    this.editing = new DocumentTag();
    this.editing.color = "#0000ff";
    this.$scope.$applyAsync();
  }

  deleteTag(tag: DocumentTag) {
    if (!this.privilegeService.has(RolePrivilege.Objects_ObjectDocument_Tag_Delete)) {
      return;
    }
    if (this.editing == tag) {
      this.editing = null;
      this.isAdd = false;
      this.isEditable = false;
    }
    this.restService.deleteDocumentTag(tag.id).then(response => {
      this.isLoading = false;
      this.loadTags();
    }).catch(error => {
      this.$log.error(error);
      this.isLoading = false;
    });
    this.$scope.$applyAsync();
  }

  close() {
    this.$uibModalInstance.close();
    this.okFunction();
  }

  validateTag() {
    return this.editing != null && this.editing.label != null && this.editing.color != null;
  }

  save() {
    if (!this.validateTag()) {
      return;
    }
    this.isLoading = true;
    if (this.isAdd) {
      if (!this.privilegeService.has(RolePrivilege.Objects_ObjectDocument_Tag_Create)) {
        return;
      }
      this.restService.addDocumentTag(this.editing).then(response => {
        this.loadTags();
        this.isLoading = false;
        this.isEditable = false;
        this.isAdd = false;
        this.editing = null;
      }).catch(error => {
        this.$log.error(error);
        this.isLoading = false;
        this.$scope.$apply();
      });
    } else {
      if (!this.privilegeService.has(RolePrivilege.Objects_ObjectDocument_Tag_Edit)) {
        return;
      }
      this.restService.editDocumentTag(this.editing).then(response => {
        this.loadTags();
        this.isLoading = false;
        this.isEditable = false;
        this.isAdd = false;
        this.editing = null;
      }).catch(error => {
        this.$log.error(error);
        this.isLoading = false;
        this.$scope.$apply();
      });
    }
  }

  editTag(tag: DocumentTag) {
    if (!this.privilegeService.has(RolePrivilege.Objects_ObjectDocument_Tag_Edit)) {
      return;
    }
    this.isEditable = true;
    this.isAdd = false;
    this.editing = tag;
  }
}
