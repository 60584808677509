/* @ngInject */
import * as angular from "angular";
import {ILogService, IRootScopeService, IScope} from "angular";
import {EInputFallbackCategory, InputPluginDetails} from "../../../../data/input.data";
import {BaseField, BaseFieldType} from "../../../../data/basefield.data";
import RestService from "../../../../services/rest.service";
import {User} from '../../../../data/admin.data';

require('./edit.input.modal.scss')
export default class EditInputModalController {
  public $scope: IScope;
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $uibModalInstance: any;
  public inputPlugin: InputPluginDetails;
  public additionalTabs: AdditionalTab[] = [];
  public hasOptionalSettings:boolean=false;
  public tab: string = CurrentTab.SETTINGS;
  public fields: any[] = [];
  public hasSettings:boolean= false;
  public units: any;
  public restService:RestService;
  public isSaving:boolean= false;
  public triggerReload: boolean= false;
  public okFunction: any;
  public googleLink:string;
  public azureLink:string;
  public tokenType:string;
  public providers: EProvider[] = [];
  public fallbackCategory: typeof EInputFallbackCategory = EInputFallbackCategory;


  constructor($scope: IScope, $log: ILogService, $rootScope: IRootScopeService, $uibModalInstance, $uibModal, inputPlugin, dataService, restService:RestService, okFunction){
    this.$scope = $scope;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.inputPlugin= inputPlugin;
    this.restService = restService;
    this.triggerReload= false;
    this.okFunction= okFunction;
    this.providers=[EProvider.USER,EProvider.Azure];

    this.inputPlugin.pluginData.forEach((field: BaseField) => {
      if (field.optional) {
        this.hasOptionalSettings = true;
      }
      // Gather categories
      if (field.category) {
        const tab = this.additionalTabs.filter(entry => entry.category === field.category);
        if (tab.length === 0) {
          const newTab = {
            category: field.category,
            fields: []
          } as AdditionalTab;
          this.additionalTabs.push(newTab);
          tab.push(newTab);
        }
        tab[0].fields.push(field);
      }
    });
    this.selectTab(CurrentTab.SETTINGS);

    restService.loadUnitsForBaseField().then((response) => {
      this.units = response;
    })
    this.updateTokenType();
    let updateTokenType = setInterval(() => this.updateTokenType(), 15 * 1000);
    this.$scope.$on('$destroy', () => {
      clearInterval(updateTokenType);
    });
  }

  openURLInfo(url: string) {
    // Open in browser
    window.open(url, '_blank').focus();
  }

  /**
   * expand method to add new icons here input plugin tabs
   * @param category
   */
  getIconTab(category: string) {
    if (category === 'ALARM' || category === 'COBRA_ALARM') {
      return 'fad fa-siren-on fa-fw header-icon';
    }
    if (category === 'SPLITTER') {
      return 'fad fa-line-columns header-icon'
    }
    if(category === 'SECURITY'){
      return 'fad fa-shield-alt header-icon'
    }
    if (category === 'MISC'){
        return '';
    }
    if (category === 'DATA_PROTECTION'){
      return 'fa-duotone fa-file-shield'
    }
    return 'fad fa-fw fa-stream header-icon';
  }
  selectTab(selectTab:string){
    this.tab = selectTab;
    this.fields = [];
    this.inputPlugin.pluginData.forEach((field: BaseField) => {
      switch (field.type) {
        case BaseFieldType.PERSON_SELECT:
        case BaseFieldType.DATABASE_PERSON_SELECT:
          break;
        default:
          switch (selectTab) {
            case CurrentTab.SETTINGS:
              if (!field.optional && !field.category) {
                this.fields.push(field);
              }
              break;
            case CurrentTab.OPTIONAL:
              if (field.optional && !field.category) {
                this.fields.push(field);
              }
              break;
            default:
              // Additional category
              if (field.category === this.tab) {
                this.fields.push(field);
              }
              break;
          }

      }
    });
    this.hasSettings = this.fields.length > 0;
  }

  getIcon(): string {
    if (angular.isUndefined(this.inputPlugin.simpleInputClassName)) return '';

    const iconPath = `/img/static/input/${this.inputPlugin.simpleInputClassName.replace(/.*\./, '')}.png`;
    return iconPath;
  }
  /**
   * Close the modal
   */
  cancel() {
    this.$uibModalInstance.close();
  }

  save(){
    this.isSaving= true;
    this.restService.saveInputPluginDetailed(this.inputPlugin).then(()=>{
      this.triggerReload = true
    }).finally(()=>{
      this.isSaving= false;
      this.$uibModalInstance.close();
      this.okFunction(this.triggerReload);
    })
  }

  isOauthProvider():Boolean{
    const prov = this.getProvider();
    return prov === EProvider.Azure || prov === EProvider.Google
  }

  getProvider(){
    const provider = this.inputPlugin.pluginData.filter((item) => item.key === 'provider');
    if (!provider || provider.length !== 1) {
      return undefined;
    }
    return provider[0].value;
  }

  goGoogle(){
    if(this.getProvider()!==EProvider.Google) {
      return;
    }
      this.restService.getGoogleLink(this.getUsername()).then((response)=>{
        this.googleLink = response;
        window.open(this.googleLink, "_blank");
      })

  }
  getUsername():string {
    let username=this.inputPlugin.pluginData.filter(function(item) {
      return item.key === 'username';
    });
    if(username.length==0){
      return null;
    }
    return username[0].value;
  }

  goAzure(){
    if(this.getProvider()!==EProvider.Azure) {
      return;
    }
    this.restService.getAzureLink(this.getUsername()).then((response)=>{
      this.azureLink = response;
      window.open(this.azureLink, "_blank");
    })

  }

  updateTokenType(){
    if(this.getProvider()!==EProvider.USER && this.tokenType!==this.getProvider()){
      let email = this.getUsername()
      if(email!=null)
        this.restService.updateTokenType(email).then((response)=>{
          this.tokenType = response;
          this.$scope.$apply();
        })
    }
  }

  updateHost(prov:EProvider){
    let host=this.inputPlugin.pluginData.filter(function(item) {
      return item.key === 'server';
    });
    if(prov===EProvider.Google) host[0].value='imap.gmail.com'
    if(prov===EProvider.Azure) host[0].value='outlook.office365.com'
  }

  changeProvider(field:BaseField,prov:EProvider){
    field.value=prov;
    this.updateHost(prov);
    this.updateTokenType();
    this.setReconnectTime(prov)
  }

  setReconnectTime(prov:EProvider) {
    let reconnectTime=this.inputPlugin.pluginData.filter(function(item) {
      return item.key === 'reconnectTime';
    });
    if(prov===EProvider.Azure) reconnectTime[0].value=1;
    if(prov!==EProvider.Azure) reconnectTime[0].value=6;
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

}
enum CurrentTab {
  SETTINGS = 'SETTINGS',
  OPTIONAL = 'OPTIONAL',
  METRICS = 'METRICS'
}

enum EProvider {
  Google = 'Google',
  Azure = 'Azure',
  USER = 'User'
}

interface AdditionalTab {
  category: string,
  fields: BaseField[]
}