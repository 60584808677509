'use strict';

import { IRootScopeService, IScope } from "angular";

export default class RelaisLabelComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.scope = {
      relais: '=',
      timeplan: '=',
      isSelected: '='
    }
    this.template = require('./relais.label.component.html');

    this.controller = RelaisLabelController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

require('./relais.label.component.css');


//relaisLabel
/* @ngInject */
class RelaisLabelController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public isActive: boolean = false;
  public timeplan: any;

  constructor($scope, $rootScope) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;


    $scope.$watch('ctrl.timeplan', (newValue, oldValue) => {
      if (newValue) {
        for (var i = 0; i < this.timeplan.times.length; i++) {
          var day = this.timeplan.times[i];
          for (var ii = 0; ii < day.length; ii++) {
            if (day[ii]) {
              this.isActive = true;
              break;
            }
          }
          if (this.isActive) {
            break;
          }
        }
      }
    });


  }
}
