'use strict';

import {ILogService, IRootScopeService} from "angular";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import angular = require("angular");
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./vehicleFault.modal.css');

/* @ngInject */
export default class VehicleFaultModalController {
  public $rootScope: IRootScopeService;
  public $uibModalInstance: any;
  public $log: ILogService;
  public $uibModal: any;
  public dataService: any;
  public restService: RestService;
  public vehicleFaultStates: any;
  public vehicle: any;
  public isNew: any;
  public loadingPersons: any;
  public vehicleFault: any;
  public isResolved: any;
  public oldFault: any;
  public color: any;
  public form: any;
  public hasCreateRight: boolean;
  public hasEditRight: boolean;
  public maxDateTime;
  public maxDateTimeString;

  constructor($rootScope: IRootScopeService, $uibModalInstance, $uibModal, dataService, restService: RestService, vehicleFault, vehicle, $log: ILogService,
    public privilegeService: PrivilegeService, public $document) {
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.restService = restService;
    this.vehicleFaultStates = this.dataService.getVehicleFaultStates();
    this.vehicle = vehicle;
    this.isNew = false;
    this.loadingPersons = false;

    $document.ready(() => {
      this.blockFutureFaults();
    });



    if (vehicleFault === undefined) {
      this.isNew = true;
      var date = new Date();
      date.setMilliseconds(0);
      date.setSeconds(0);
      vehicleFault = {
        vehicleFaultState: 'ACTIVE',
        creationDate: date,
        announcer: '',
        corrector: '',
        faultMessage: '',
        processingNote: '',
        correctionAddition: '',
        correctionDate: undefined
      }
    }

    this.hasCreateRight = this.isNew && (this.privilegeService.has(RolePrivilege.Station_Vehicles_Faults_Create) || this.privilegeService.has(RolePrivilege.Station_Sirens_Faults_Create));
    this.hasEditRight = !this.isNew && (this.privilegeService.has(RolePrivilege.Station_Vehicles_Faults_Edit) || this.privilegeService.has(RolePrivilege.Station_Sirens_Faults_Edit));

    this.vehicleFault = vehicleFault;
    this.isResolved = this.vehicleFault.vehicleFaultState === 'ELIMINATED';

    this.oldFault = angular.copy(vehicleFault);

    this.updateValues();
  }

  // Autocomplete

  /**
   * Auto complete persons
   */
  getPersons(val) {
    return this.restService.searchForPersons(val);
  }

  blockFutureFaults() {
    this.maxDateTime = new Date();
    //input max needs format like: 2023-01-18T13:43:12
    this.maxDateTimeString = this.maxDateTime.toISOString().slice(0, 10) + 'T' + this.maxDateTime.toLocaleTimeString();

    let elem = document.getElementById("faultDate");
    // solve it like this because apparently the max attribute kann only be a string literal
    if (elem) {
       elem["max"] = this.maxDateTimeString;
    }
  }

  /**
   * Select announcer
  */
  announcerSelected(person) {
    this.vehicleFault.announcer = person.displayName;
  }

  /**
   * Select resolver
  */
  resolverSelected(person) {
    this.vehicleFault.corrector = person.displayName;
  }

  cancel() {
    this.vehicleFault.vehicleFaultState = this.oldFault.vehicleFaultState;
    this.vehicleFault.announcer = this.oldFault.announcer;
    this.vehicleFault.faultMessage = this.oldFault.faultMessage;
    this.vehicleFault.corrector = this.oldFault.corrector;
    this.vehicleFault.correctionAddition = this.oldFault.correctionAddition;
    this.vehicleFault.correctionDate = this.oldFault.correctionDate;
    this.$log.info('Cancelled FaultMessageModal');
    this.$uibModalInstance.close();
  }

  setState(state) {
    this.vehicleFault.vehicleFaultState = state;
    this.updateValues();
    this.validate();
  }

  updateValues() {
    switch (this.vehicleFault.vehicleFaultState) {
      case 'ACTIVE':
        this.color = '#d9534f';
        this.vehicleFault.correctionDate = undefined;
        break;
      case 'ACTIVE_PROGRESS':
        this.color = '#1e3dbc';
        this.vehicleFault.correctionDate = undefined;
        break;
      case 'ELIMINATED':
        this.color = '#449d44';
        this.vehicleFault.correctionDate = new Date();
        break;
    }
  }

  validate() {

    //Field to always validate
    if (this.vehicleFault.faultMessage !== undefined && this.vehicleFault.faultMessage !== null && this.vehicleFault.faultMessage.trim().length > 0) {
      this.form.faultMessage.$setValidity('faultMessage', true);
    } else {
      this.form.faultMessage.$setValidity('faultMessage', false);
    }

    if (this.isNew) {
      // Only validate fields for new mesages
      if (this.vehicleFault.announcer !== undefined && this.vehicleFault.announcer !== null && this.vehicleFault.announcer.trim().length > 0) {
        this.form.announcer.$setValidity('announcer', true);
      } else {
        this.form.announcer.$setValidity('announcer', false);
      }

      if (this.vehicleFault.creationDate !== undefined && this.vehicleFault.creationDate !== null) {
        if (this.vehicleFault.creationDate > this.maxDateTime){
          this.form.creationDate.$setValidity('creationDate', false);
        }else {
         this.form.creationDate.$setValidity('creationDate', true);
        }
      } else {
        this.form.creationDate.$setValidity('creationDate', false);
      }
    } else if (this.vehicleFault.vehicleFaultState === 'ACTIVE_PROGRESS') {
      // check if corrector is set
      if (this.vehicleFault.corrector !== undefined && this.vehicleFault.corrector !== null && this.vehicleFault.corrector.trim().length > 0) {
        this.form.corrector.$setValidity('corrector', true);
      } else {
        this.form.corrector.$setValidity('corrector', false);
      }
      // always set valid in this state, handle it as 'optional' field here
      this.form.correctionAddition.$setValidity('correctionAddition', true);

    } else {
      // Validate remaining fields for existing messages
      if (this.vehicleFault.corrector !== undefined && this.vehicleFault.corrector !== null && this.vehicleFault.corrector.trim().length > 0) {
        this.form.corrector.$setValidity('corrector', true);
      } else {
        this.form.corrector.$setValidity('corrector', false);
      }

      if (this.vehicleFault.correctionAddition !== undefined && this.vehicleFault.correctionAddition !== null && this.vehicleFault.correctionAddition.trim().length > 0) {
        this.form.correctionAddition.$setValidity('correctionAddition', true);
      } else {
        this.form.correctionAddition.$setValidity('correctionAddition', false);
      }
    }
  }

  delete() {

    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.dataService.deleteVehicleFault(this.vehicle, this.vehicleFault, (response) => {
              this.$rootScope.$emit('faults.update');
              this.$log.info('FaultMessage deleted');
              this.$uibModalInstance.close();
            }, (error) => {
              this.$log.error('Error deleting fault message')
              this.$log.error(error)
            });
          }
        },
        additionalText: () => {
          return 'SHIFTBOOK.CATEGORY.DELETE_TEXT'
        }
      }
    });
  }

  save() {
    if (this.isNew) {
      this.dataService.addVehicleFault(this.vehicle, this.vehicleFault, (response) => {
        this.$rootScope.$emit('faults.update');
        this.$log.info('FaultMessage added');
        this.$uibModalInstance.close();
      }, (error) => {
        this.$log.error('Error adding fault message')
        this.$log.error(error)

      });
    } else {
      this.dataService.updateVehicleFault(this.vehicle, this.vehicleFault, (response) => {
        this.$rootScope.$emit('faults.update');
        this.$log.info('FaultMessage updated');
        this.$uibModalInstance.close();
      }, (error) => {
        this.$log.error('Error updating fault message')
        this.$log.error(error)
      });
    }
  }
}
