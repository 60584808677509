import {EVehicleStatus, StatusEntryResponse, IconFrontendRepresentation, EVehicleType} from './../../../../data/vehicles.data';
'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import angular = require("angular");
import { Vehicle } from "../../../../data/vehicles.data";
import RestService from '../../../../services/rest.service';
import PrivilegeService from '../../../../services/privilege.service';
import {RolePrivilege} from '../../../../data/privileges.enum';
import AccountService from '../../../../services/account.service';
import { EFE2Features } from '../../../../data/account.data';

require('./vehicle.modal.scss');

//VehicleModalCtrl
/* @ngInject */
export default class VehicleModalController {
  public statusToDisplay = [
    EVehicleStatus.STATUS_1,
    EVehicleStatus.STATUS_2,
    EVehicleStatus.STATUS_3,
    EVehicleStatus.STATUS_4,
    EVehicleStatus.STATUS_6,
    EVehicleStatus.STATUS_7,
    EVehicleStatus.STATUS_8,
    EVehicleStatus.STATUS_9,
    EVehicleStatus.STATUS_C];
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public restService: RestService;
  public $uibModal: any;
  public $uibModalInstance: any;
  public vehicle: Vehicle;
  public dataService: any;
  public relais = [];
  public listeners = [];
  public hideIfNotActive: boolean = true;
  public selectionModeAll: boolean = false;
  public hasTimeplanPrivilege: boolean;
  public isLoading: boolean;
  public color: string;
  public timeplan: any;
  public selectedRelais: any;
  public selectedRelaisTimeplan: any;
  public textColor: string;
  private okFunction: Function;
  public hasEditPermission: boolean;
  private trackingFeatureEnabled: boolean = false;
  public iconPath:string;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $uibModal, $translate, Notification, $uibModalInstance, restService: RestService, vehicle: Vehicle, dataService, okFunction: Function,
    public mode: VehicleModes,
    public privilegeService: PrivilegeService, accountService: AccountService, vehicleId?: string) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.dataService = dataService;
    this.okFunction = okFunction;
    this.hasTimeplanPrivilege = this.privilegeService.has(RolePrivilege.Station_Vehicles_TTS);
    this.restService = restService;
    this.hasEditPermission = this.privilegeService.has(RolePrivilege.Station_Vehicles_Edit) || this.privilegeService.has(RolePrivilege.Station_Sirens_Edit);
    this.trackingFeatureEnabled = accountService.isFeatureEnabled(EFE2Features.VEHICLE_TRACKING);
    if (!this.mode) {
      this.mode = VehicleModes.OVERVIEW;
    }
    if (vehicleId) {
      restService.getVehicle(vehicleId).then(vehicle => {
        this.vehicle = vehicle;
        this.init();
      }).catch(err => {
        $translate('VEHICLES.COULD_NOT_LOAD_TITLE').then((translation: string) => {
          Notification.warning({
            message: err.data.message,
            title: translation,
          });
        });
        this.$uibModalInstance.close();
      });
    } else {
      this.vehicle = vehicle;
      this.init();
    }
  }

  init() {
    this.textColor = this.vehicle.statusTextColor;
    if (this.privilegeService.has(RolePrivilege.Station_Vehicles_TTS)) {
      //init load
      this.load();
    }
    this.initListeners();
    if (this.vehicle.vehicleIcon){
      this.setIconPath(this.vehicle.vehicleIcon);
    }
  }

  //Load times
  load() {
    this.isLoading = true;
    this.restService.loadTimesForVehicle(this.vehicle, (timeplan) => {
      this.timeplan = timeplan;
      //Load relais
      this.dataService.getRelais(false, true, (relais) => {
        this.relais = relais;
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        this.$log.error(err);
      });
    }, (err) => {
      this.isLoading = false;
      this.$log.error(err);
    });
  }

  /**
   * Select a relais to start editing
   */
  selectRelais(relais) {
    if (angular.isDefined(this.selectedRelais)) {
      this.timeplan.relaisMapping[this.selectedRelais.id] = this.selectedRelaisTimeplan;
    }
    if (!this.privilegeService.has(RolePrivilege.Station_Vehicles_TTS)) {
      return;
    }
    relais.hasBeenEdited = true;
    this.selectedRelais = relais;

    if (angular.isDefined(this.timeplan.relaisMapping[relais.id])) {
      this.$log.debug('Relais has timeplan:');
      this.selectedRelaisTimeplan = this.timeplan.relaisMapping[relais.id];
    } else {
      this.selectedRelaisTimeplan = {
        times: []
      };
      for (var i = 0; i < 7; i++) {
        var arr = [];
        for (var ii = 0; ii < 24; ii++) {
          arr[ii] = 0;
        }
        this.selectedRelaisTimeplan.times[i] = arr;
      }
      this.timeplan.relaisMapping[relais.id] = this.selectedRelaisTimeplan;
    }
  }

  /**
   * Edit the timeplan for a specific gate
   */
  editTimeplan(timeplan) {
    var modalInstance = this.$uibModal.open({
      template: require('../../../modals/wache/timeplan.modal/timeplan.modal.html'),
      controller: 'TimeplanModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        timeplan: () => {
          return timeplan;
        }
      }
    });

    modalInstance.result.then((newTimeplan) => {
      timeplan = newTimeplan;
    });
  }

  /**
   * Removes all timeplans
   */
  deleteAllRelais() {
    this.timeplan.relaisMapping = {};
    //Set flag flag
    for (var i = 0; i < this.relais.length; i++) {
      this.relais[i].hasBeenEdited = true;
    }
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  save(andClose: boolean) {
    if (angular.isDefined(this.selectedRelais)) {
      this.timeplan.relaisMapping[this.selectedRelais.id] = this.selectedRelaisTimeplan;
    }

    //Delete unnecessary flag
    for (var i = 0; i < this.relais.length; i++) {
      delete this.relais[i].hasBeenEdited;
    }

    if (!this.vehicle.column) {
      // User has entered value outside of 1-7. we set it to 1
      this.vehicle.column = 1;
    }

    if (!this.vehicle.row) {
      // User has entered a row value < 1. We set it to 1
      this.vehicle.row = 1;
    }

    if (this.vehicle.height < 0) {
      this.vehicle.height = 0;
    }
    if (this.vehicle.weight < 0) {
      this.vehicle.weight = 0;
    }
    if (this.vehicle.width < 0) {
      this.vehicle.width = 0;
    }
    if (this.vehicle.length < 0) {
      this.vehicle.length = 0;
    }
    if (this.vehicle.minVoltage < 0) {
      this.vehicle.minVoltage = 0;
    }
    if (this.vehicle.maxVoltage < 0) {
      this.vehicle.maxVoltage = 0;
    }
    if (this.vehicle.minVoltage > this.vehicle.maxVoltage) {
      this.vehicle.minVoltage = this.vehicle.maxVoltage;
    }

    this.isLoading = true;
    if (this.privilegeService.has(RolePrivilege.Station_Vehicles_TTS_Edit)) {
      this.$log.info('Update timeplan...');
      this.restService.updateTimesForVehicle(this.vehicle, this.timeplan, () => {
        this.saveVehicle(andClose);
      }, (err) => {
        this.isLoading = false;
        this.$log.error('Could not save timeplan');
      });
    } else {
      this.saveVehicle(andClose);
    }
    this.isLoading = false;

  }

  saveVehicle(andClose: boolean) {
    // Update vehicle
    if (this.vehicle.vehicleType != EVehicleType.SIREN && !this.privilegeService.has(RolePrivilege.Station_Vehicles_Edit)) {
      this.isLoading = false;
      return;
    }
    if (this.vehicle.vehicleType == EVehicleType.SIREN && !this.privilegeService.has(RolePrivilege.Station_Sirens_Edit)) {
      this.isLoading = false;
      return;
    }
    this.$log.info('Update vehicle...');
    this.restService.updateVehicle(this.vehicle).finally(() => {
      this.isLoading = false;
      if (this.okFunction) {
        this.okFunction(this.vehicle);
      }
      if(andClose){
        this.$uibModalInstance.close();
      }
    });
  }

  copyFromVehicle() {
    var modalInstance = this.$uibModal.open({
      template: require('../../../modals/wache/copy.from.vehicle.modal/copy.from.vehicle.modal.html'),
      controller: 'CopyFromVehicleController',
      controllerAs: 'ctrl',
      size: 'sm'
    });

    modalInstance.result.then((vehicle) => {
      this.isLoading = true;
      this.restService.loadTimesForVehicle(vehicle, (timeplan) => {
        this.timeplan = timeplan;
        this.isLoading = false;
        if (angular.isDefined(this.selectedRelais)) {
          var tmp = this.selectedRelais;
          this.selectedRelais = undefined;
          this.selectRelais(tmp);
        }
      }, (err) => {
        this.isLoading = false;
      });
    });
  }

  initListeners() {
    //Update color status.change

    //This listener is for later status updates (doesn't work at first load)
    this.listeners.push(this.$rootScope.$on('status.change.' + this.vehicle.id, (event, data: StatusEntryResponse) => {
      this.vehicle.statusColor = data.color;
      this.textColor = data.textColor;
    }));

    //This listener is for later status updates (doesn't work at first load)
    this.listeners.push(this.$rootScope.$on('vehicle.type.change.' + this.vehicle.id, (event, vehicle: any) => {
      this.$log.info(`Vehicle type for ${vehicle.vehicleName} changed. The vehicle will be saved`);
      this.save(false);
    }));

    this.listeners.push(this.$scope.$on("vehicle.iconChange."+this.vehicle.id, (event, icon:IconFrontendRepresentation)=>{
      if (icon){
        this.setIconPath(icon);
      } else {
        this.iconPath = undefined;
        this.$scope.$applyAsync();
      }
    }));



    //Unregister listener
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  private setIconPath(icon: IconFrontendRepresentation) {
    this.iconPath = `/img/static/vehicle_icons/${ encodeURIComponent(icon.category)}/${ encodeURIComponent(icon.filename)}`;
    this.$scope.$applyAsync();
  }

  getIcon(): string {
    if (!this.iconPath){
      return '';
    }
    return this.iconPath;
  }

  /**
   * Decides if safe button is enabled or disabled
   */
  isSaveDisabled(): boolean {
    if (!this.vehicle) return true;
    let height = this.vehicle.height;
    let length = this.vehicle.length;
    let width = this.vehicle.width;

    if (height === 0 && length === 0 && width === 0) {
      // All values are 0 -> OK
      return false;
    }

    if (height === 0 || length === 0 || width === 0) {
      // At least one value is 0 -> Not OK
      return true;
    }
    return false;
  }

  toggleAlarmstatus(status: EVehicleStatus) {
    if (this.vehicle.vehicleType != EVehicleType.SIREN && !this.privilegeService.has(RolePrivilege.Station_Vehicles_Edit)) {
      return;
    }
    if (this.vehicle.vehicleType == EVehicleType.SIREN && !this.privilegeService.has(RolePrivilege.Station_Sirens_Edit)) {
      return;
    }
    if (this.vehicle.notAlarmabaleStatus.includes(status)) {
      this.vehicle.notAlarmabaleStatus.splice(this.vehicle.notAlarmabaleStatus.indexOf(status), 1);
    } else {
      this.vehicle.notAlarmabaleStatus.push(status);
    }
  }

  /**
   * Returns true, if in this status the vehicle is alertable
   * @param status
   * @returns
   */
  isAlarmstatus(status: EVehicleStatus): boolean {
    if (!this.vehicle) return false;
    return !this.vehicle.notAlarmabaleStatus.includes(status);
  }

  toggleAll(toggleMode: ToggleMode) {
    if (this.vehicle.vehicleType != EVehicleType.SIREN && !this.privilegeService.has(RolePrivilege.Station_Vehicles_Edit)) {
      return;
    }
    if (this.vehicle.vehicleType == EVehicleType.SIREN && !this.privilegeService.has(RolePrivilege.Station_Sirens_Edit)) {
      return;
    }
    switch (toggleMode) {
      case ToggleMode.ALL:
        this.vehicle.notAlarmabaleStatus = [];
        break;
      case ToggleMode.NONE:
        this.vehicle.notAlarmabaleStatus = [];
        this.statusToDisplay.forEach(status => this.vehicle.notAlarmabaleStatus.push(status));
        break;
      case ToggleMode.SELECTIVE:
        this.vehicle.notAlarmabaleStatus = [];
        this.vehicle.notAlarmabaleStatus.push(EVehicleStatus.STATUS_3);
        this.vehicle.notAlarmabaleStatus.push(EVehicleStatus.STATUS_4);
        this.vehicle.notAlarmabaleStatus.push(EVehicleStatus.STATUS_6);
        this.vehicle.notAlarmabaleStatus.push(EVehicleStatus.STATUS_7);
        this.vehicle.notAlarmabaleStatus.push(EVehicleStatus.STATUS_8);
        this.vehicle.notAlarmabaleStatus.push(EVehicleStatus.STATUS_9);
        this.vehicle.notAlarmabaleStatus.push(EVehicleStatus.STATUS_C);
        break;
    }
  }

  /**
   * Open the swap vehicles modal
   */
  openSwapModal() {
    this.$uibModal.open({
      template: require('./../swap.vehicles.modal/swap.modal.html'),
      controller: 'VehicleSwapModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        vehicle: () => {
          return this.vehicle;
        },
        okFunction: () => {
          return () => {
            this.$rootScope.$emit('update.vehicles');
            this.cancel();
          }
        }
      }
    });
  }

  isSiren() {
    return this.vehicle?.vehicleType === EVehicleType.SIREN;
  }
}

export enum VehicleModes {
  OVERVIEW = 'OVERVIEW',
  STATUS = 'STATUS',
  WACHALARM = 'WACHALARM',
  FAULTS = 'FAULTS',
  ALARMSTATUS = 'ALARMSTATUS',
  VOLTAGE = 'VOLTAGE',
  TRACKING_SETTINGS='TRACKING_SETTINGS',
  LOCATION = 'LOCATION',
  RESCUE_SERVICES = 'RESCUE_SERVICES',
  DEVICES = 'DEVICES'
}

enum ToggleMode {
  ALL = 'ALL',
  NONE = 'NONE',
  SELECTIVE = 'SELECTIVE'
}
