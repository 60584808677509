import RestService from "../../../../services/rest.service";
import {ProvisioningGeoFenceEdit} from "../../../../data/geofence.data";
import {EAvailabilityState} from "../../../../data/availability.data";
import * as angular from "angular";
import {IScope} from "angular";
import MapService from "../../../../services/map.service";
import * as L from 'leaflet'
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./edit.geofence.modal.scss')
/* @ngInject */
export default class EditGeofenceModalController{
  public $uibModalInstance: any;
  public $scope:IScope;
  public okFunction: any;
  public isLoading:boolean = false;
  public isSaving:boolean = false;
  public isNew= false;
  public restService:RestService;
  public mapService:MapService;
  public map:L.Map;
  public provisioningGeofence: ProvisioningGeoFenceEdit;
  public availabilityStates: typeof EAvailabilityState= EAvailabilityState ;
  public tempLat: number;
  public tempLng:number;
  public distanceDefaults: number[] = [500, 1, 1.5, 2, 3, 5]
  public settings;
  public account;
  public centerCoordsArray: L.LatLng;
  public isChecked:boolean= false;
  public provisioningGeofencesAll: ProvisioningGeoFenceEdit[];
  public drawnItems;
  public currentMarker: L.Circle;
  public tempMarker:L.Circle[]= [];
  public priv:PrivilegeService;
  public hasGeofenceCreate:boolean= false;
  public hasGeofenceEdit:boolean = false;

  public isEditAllowed:boolean = false;


  constructor($uibModalInstance: any,$scope:IScope, restService:RestService, provisioningGeofence: ProvisioningGeoFenceEdit, mapService:MapService, $document, dataService, privilegeService:PrivilegeService) {
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.provisioningGeofence = provisioningGeofence;
    this.mapService = mapService;
    this.$scope = $scope;
    this.settings = dataService.getAccount().settings;
    this.account = dataService.getAccount();
    this.priv = privilegeService;
    this.hasGeofenceCreate= this.priv.has(RolePrivilege.Addressbook_Geofences_Create);
    this.hasGeofenceEdit= this.priv.has(RolePrivilege.Addressbook_Geofences_Edit);

    if (angular.isUndefined(provisioningGeofence)){
      this.isNew = true;
      this.provisioningGeofence = {
        lat: this.settings.lat,
        lng: this.settings.lng,
        editAllowed:true
      } as ProvisioningGeoFenceEdit;
      if (this.hasGeofenceCreate){
        this.isEditAllowed = true;
      }
    }else {
      this.isNew = false;
      if (this.hasGeofenceEdit){
        this.isEditAllowed= this.provisioningGeofence.editAllowed;
      }
    }

    $document.ready(() => {
      L.Icon.Default.imagePath = '/img/static';

      this.map = L.map('mapId');
      this.map.setView([this.provisioningGeofence.lat, this.provisioningGeofence.lng], 13);
       this.currentMarker = undefined;

      this.initLayers();

      this.drawnItems = new L.FeatureGroup();
      this.map.addLayer(this.drawnItems);
      var drawControl = new L.Control.Draw({
        draw: {
          polyline: false,
          circle: false,
          rectangle: false,
          polygon: false,
          marker: false,
          circlemarker: {
            tooltip:{
              start: 'Klicken um zu platzieren',
            }
          }
        }
      })
      if (this.isEditAllowed) {
        this.map.addControl(drawControl);
      }

      // Map events
      this.map.on('draw:created', (layer) => {
          var geoJson = layer.layer.toGeoJSON();
          var coor = geoJson.geometry.coordinates;
          this.provisioningGeofence.lat = coor[1];
          this.provisioningGeofence.lng = coor[0];
          this.createEditGeofence(this.currentMarker, this.drawnItems);

      });

      // User starts drawing
      this.map.on('draw:drawstart ', () => {
        this.drawnItems.clearLayers();
        // Remove current route shape
        if (this.currentMarker !== undefined) {
          this.drawnItems.removeLayer(this.currentMarker);
          this.currentMarker = undefined;
        }
      });



      if (angular.isDefined(this.provisioningGeofence.lat) && angular.isDefined(this.provisioningGeofence.lng) && angular.isDefined(this.provisioningGeofence.distance)){
        this.currentMarker = this.createEditGeofence(this.currentMarker, this.drawnItems);
      }
    });

  }

  private createEditGeofence(currentMarker, drawnItems: L.FeatureGroup) {
    this.centerCoordsArray = L.latLng(this.provisioningGeofence.lat, this.provisioningGeofence.lng);
    currentMarker = L.circle(this.centerCoordsArray, this.provisioningGeofence.distance, {
      fillOpacity: 0.5,
      color: this.getAvailabilityColor(this.provisioningGeofence),
      fillColor: this.getAvailabilityColor(this.provisioningGeofence)
    })
    currentMarker.addTo(this.map);
    drawnItems.addLayer(currentMarker);
    this.map.setView(this.centerCoordsArray, 13);
    this.map.invalidateSize();
    return currentMarker;
  }

  displayFences(){
    this.isChecked = !this.isChecked;
    if (angular.isUndefined(this.provisioningGeofencesAll)){
      this.isLoading = true;
      this.restService.loadAllGeofencePositionsForUser().then((result)=>{
        this.provisioningGeofencesAll = result;
        this.redrawMap();

      }).finally(()=>{
        this.isLoading = false;
        this.$scope.$applyAsync();

      });
    }else {
      this.redrawMap();
    }

  }

  getAvailabilityColor(geofence: ProvisioningGeoFenceEdit){
    if (geofence.availabilityState === this.availabilityStates.AVAILABLE){
      return 'green';
    }
    if (geofence.availabilityState === this.availabilityStates.TEMP_NOT_AVAILABLE){
      return 'orange';
    }
    if (geofence.availabilityState === this.availabilityStates.NOT_AVAILABLE){
      return 'red';
    }
  }
  setAvailability(availability:EAvailabilityState){
    if (this.provisioningGeofence.availabilityState !== availability){
      this.provisioningGeofence.availabilityState = availability;
      this.redrawMarker();
    }
  }

  distanceChanged(){
    if (this.provisioningGeofence.distance<100){
      return;
    }
    this.redrawMarker();

  }

  private redrawMarker() {
    this.drawnItems.clearLayers();
    this.drawnItems.removeLayer(this.currentMarker);
    this.currentMarker = undefined;
    this.createEditGeofence(this.currentMarker, this.drawnItems);
  }

  isAllDataSet(){
    if (angular.isUndefined(this.provisioningGeofence.lat)){
      return false;
    }
    if (angular.isUndefined(this.provisioningGeofence.lng)){
      return false;
    }
    if (angular.isUndefined(this.provisioningGeofence.distance)){
      return false;
    }
    if (angular.isUndefined(this.provisioningGeofence.availabilityState)){
      // don't allow saving if no state is set
      return false;
    }
    return true;
  }

  cancel(){
    this.$uibModalInstance.close();
  }

  save(){
    this.isSaving = true;
    if (this.isNew){
      this.restService.createGeofenceProvisioning(this.provisioningGeofence).then(()=>{
        this.isSaving = false;
      }).finally(()=>{
        this.$uibModalInstance.close();
      });
    }else {
      this.restService.updateGeofenceProvisioning(this.provisioningGeofence).then(() => {
        this.isSaving = false;

      }).finally(() => {
        this.$uibModalInstance.close();
      });
    }
  }
  initLayers() {
    this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');

    let layers = this.mapService.getBaseLayers();

    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if(selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService
    this.map.on('baselayerchange', function(e) {
      mapService.saveLayer(e.name);
    });

  }
  setDistance(distance:number){
    if (distance<100){
      this.provisioningGeofence.distance = distance*1000;
    }else {
      this.provisioningGeofence.distance= distance
    }
    this.redrawMarker();
  }

  private redrawMap() {
    // display all
    if (this.isChecked){
      this.isLoading= true;
      this.provisioningGeofencesAll.forEach((geofence)=>{
        if (geofence.id !== this.provisioningGeofence.id){
          if (angular.isDefined(geofence.lat) && angular.isDefined(geofence.lng) && angular.isDefined(geofence.distance)) {
            var tempCoors = L.latLng(geofence.lat, geofence.lng);
            var tempMarker = L.circle(tempCoors, geofence.distance, {
              fillOpacity: 0.5,
              color: this.getAvailabilityColor(geofence),
              fillColor: this.getAvailabilityColor(geofence)
            })
            // do only add them to map not as draw item to make edit impossible
            tempMarker.addTo(this.map);
             this.tempMarker.push(tempMarker);

          }
        }
      });
      this.map.invalidateSize();
      this.isLoading= false;
      this.$scope.$applyAsync();
    } else {
      // remove everything
      if (angular.isDefined(this.tempMarker)){
        this.isLoading = true;
        this.tempMarker.forEach((marker)=>{
          this.map.removeLayer(marker);
        });
        this.map.invalidateSize();
        this.isLoading = false;
        this.$scope.$applyAsync();
      }
    }
  }
}