'use strict';

/* @ngInject */
import {RolePrivilege} from "../../../../data/privileges.enum";
import HelperService from "../../../../services/helper.service";
import RestService from "../../../../services/rest.service";
import PrivilegeService from "../../../../services/privilege.service";
import {ILogService, IRootScopeService} from "angular";

interface PasswordChangeData {
  newPw: string,
  newPwRepeated: string
}

export default class ChangePassModalController {

  public showPasswordMap: Map<string, boolean> = new Map<string, boolean>();
  public pw: PasswordChangeData;
  public $rootScope: IRootScopeService;
  public randomPassword: string;
  public $translate: any;
  public restService: RestService;
  public dataService: any;
  public Notification: any;
  public helperService: HelperService;
  public isLoading: boolean = false;
  public $log: ILogService;
  public $state: any;
  public $location: any;

  constructor(private $uibModalInstance, $rootScope: IRootScopeService, helperService: HelperService, $state, $location, $translate, restService: RestService, dataService, Notification, $log: ILogService, public privilegeService: PrivilegeService) {

    this.$translate = $translate;
    this.helperService = helperService;
    this.restService = restService;
    this.dataService = dataService;
    this.Notification = Notification;
    this.$log = $log;
    this.$state = $state;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.pw = {
      newPw: "",
      newPwRepeated: ""
    };
  }

  cancel() {
  };

  ok() {
    this.$uibModalInstance.close(true);
  };


  /**
   * Change the users password
   */
  changePw() {
    if (!this.privilegeService.has(RolePrivilege.Admin)) {
      return;
    }
    if (this.pw.newPw === '' || this.pw.newPwRepeated === '') {
      //Password is empty
      this.$translate(['SETTINGS.PW_ERROR_TITLE', 'SETTINGS.PW_EMPTY']).then((translations) => {
        this.Notification.error({
          message: translations['SETTINGS.PW_EMPTY'],
          title: translations['SETTINGS.PW_ERROR_TITLE']
        });
      });
      return;
    }

    if (this.pw.newPw !== this.pw.newPwRepeated) {
      //Password is not equal
      this.$translate(['SETTINGS.PW_ERROR_TITLE', 'SETTINGS.PW_NOT_EQUAL']).then((translations) => {
        this.Notification.error({
          message: translations['SETTINGS.PW_NOT_EQUAL'],
          title: translations['SETTINGS.PW_ERROR_TITLE']
        });
      });
      return;
    }

    this.isLoading = true;
    this.dataService.changeAdminPassword(this.pw.newPw, this.pw.newPwRepeated, () => {
      this.isLoading = false;
      this.$translate(['SETTINGS.PW_OK', 'SETTINGS.PW_CHANGED']).then((translations) => {
        this.Notification.success({
          message: translations['SETTINGS.PW_CHANGED'],
          title: translations['SETTINGS.PW_OK']
        });
      });
      this.restService.finishFirstAdminLogin();
      this.$rootScope.$emit('main.logout', null);
      this.$uibModalInstance.close(true);
    }, (errorResponse) => {
      this.isLoading = false;
      this.$log.error(errorResponse);
    });

  }

  toggleShowPassword(key: string) {
    this.showPasswordMap.set(key, !this.showPassword(key));
  }

  showPassword(key: string) {
    return this.showPasswordMap.get(key) ?? false;
  }

  isPasswordComplexEnough(): boolean {
    if (!this.newPasswordHasLowercaseCharacter()) {
      return false;
    }
    if (!this.newPasswordHasUppercaseCharacter()) {
      return false;
    }
    if (!this.newPasswordHasDigit()) {
      return false;
    }
    if (!this.newPasswordHasSpecialChar()) {
      return false;
    }
    return this.newPasswordIsLongEnough();
  }

  newPasswordIsLongEnough(): boolean {
    return this.pw.newPw.length >= 10;
  }

  newPasswordHasSpecialChar(): boolean {
    return /[!@#$%&*()_+=|<>?{}\[\]~-]/.test(this.pw.newPw);
  }

  newPasswordHasDigit(): boolean {
    return /\d/.test(this.pw.newPw);
  }

  newPasswordHasUppercaseCharacter(): boolean {
    return /[A-Z]/.test(this.pw.newPw);
  }

  newPasswordHasLowercaseCharacter(): boolean {
    return /[a-z]/.test(this.pw.newPw);
  }

  passwordsMatch(): boolean {
    return this.pw.newPw === this.pw.newPwRepeated;
  }

  generatePassword() {
    this.randomPassword = this.helperService.generatePassword(12);
    return this.randomPassword;
  }
}
