import RestService from "../../../../services/rest.service";
import { IRootScopeService, IScope, IWindowService } from "angular";
import { OnlineServiceMapping, UpdateOnlineServiceMapping, User } from "../../../../data/admin.data";
import angular = require("angular");
import ErrorService from "../../../../services/error.service";
import { UserSettingsAsAdminRequest } from "../../../../data/user.data";

require('./admin.users.os.component.scss')


export default class AdminUsersOSComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.users.os.component.html');
    this.scope = {
      user: '=',
    };
    this.controller = UserOSController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
/* @ngInject */
class UserOSController {
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public user: User;
  public isLoading = false;
  onlineServices: OnlineServiceMapping[];

  constructor(restService: RestService, $scope: angular.IScope, $rootScope: angular.IRootScopeService, private $window: IWindowService, private $http: any, private Notification) {
    this.restService = restService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;

    this.$scope.$watch('ctrl.user', (oldValue, newValue: User) => {
      if (angular.isDefined(newValue)) {
        this.user = newValue;
        this.loadAvailableOnlineServices();
      }
    });
  }


  loadAvailableOnlineServices() {
    // only load list if we can actually select one
    if (this.user.hasOnlineService) return;
    this.restService.loadAdminOnlineServices()
      .then(os => this.onlineServices = os.filter(o => !o.inUse))
      .then(() => this.$scope.$applyAsync());
  }

  selectOs(onlineService: OnlineServiceMapping) {
    // not allow this when user already has os
    if (this.user.hasOnlineService) return;
    this.restService.updateOnlineServiceMapping(this.user.id, onlineService.id)
      .then(() => {
        this.user.hasOnlineService = true;
        this.user.onlineServiceName = onlineService.name;
        this.$scope.$applyAsync();
      })
  }

  createOs() {
    // not allow this when user already has os
    if (this.user.hasOnlineService) {
      return;
    }
    this.restService.createOnlineService(this.user.id)
      .then(() => {
        this.user.hasOnlineService = true;
        this.user.onlineServiceName = this.user.name;
        this.$scope.$applyAsync();
      }).catch(error => {
        this.Notification.error({
          message: error.message,
          title: this.user.name,
        });
      });
  }

  fixOnlineService() {
    this.$window.open(
      `${this.restService.getBaseUrl()}/admin/settings/onlineservices/fix/${this.user.id}?Authorization=${this.$http.defaults.headers.common.Authorization}`,'_blank'
    );
  }

  saveSettings() {
    this.isLoading = true;
    const request = {
      accessToSmsGatewayAllowed: this.user.settings.accessToSmsGatewayAllowed,
      allowedIPRange: this.user.settings.allowedIPRange,
      apiAlarmsDisabled: this.user.settings.apiAlarmsDisabled,
      allowedLicencePlate: this.user.settings.allowedLicencePlate,
      persistAvailability: this.user.settings.persistAvailability
    } as UserSettingsAsAdminRequest;
    this.restService.updateOrganisationSettingsForAdmin(this.user.id, request).catch(() => {
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }
}