'use strict';

import { IScope } from "angular";
import PrivilegeService from "../../../../services/privilege.service";

/* @ngInject */
export default class ModalGetPipelineTemplatesInstanceController {
    public $scope: IScope;
    public $uibModalInstance: any;
    public pipelineTemplates: any;
    public dataService: any;

    constructor($scope: IScope, $uibModalInstance, pipelineTemplates, dataService, public privilegeService: PrivilegeService) {
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.pipelineTemplates = pipelineTemplates;
        this.dataService = dataService;
    }

    cancel() {
        this.$uibModalInstance.close();
    }

    getPipelineTemplate(template) {
        this.$uibModalInstance.close(template);
    }

    deletePipelineTemplate(template) {
        this.$uibModalInstance.close();
        this.dataService.deletePipelineTemplateById(template);
    }
}