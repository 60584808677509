import { AddUnitRequest } from './../../../../data/unit.data';
import {Vehicle, VehicleNameIDResponse} from './../../../../data/vehicles.data';
'use strict';

import { IScope } from "angular";
import {EFE2Features, UserAccount} from "../../../../data/account.data";
import { EUnitType } from "../../../../data/unit.data";
import AccountService from "../../../../services/account.service";
import RestService from '../../../../services/rest.service';

require('./add.unit.modal.scss');


/* @ngInject */
export default class AddUnitModalController {
  public $scope: IScope;
  public $uibModalInstance: any;
  public okFunction: Function;
  public name: string = '';
  public unitType: EUnitType = EUnitType.CUSTOM;
  public queueFeatureEnabled = false;
  public selectedVehicle: VehicleNameIDResponse = undefined;
  public user:UserAccount;

  // Search for vehicles
  public vehicleSearch: string = '';
  public vehicles: VehicleNameIDResponse[] = [];

  constructor($scope: IScope, $uibModalInstance, accountService: AccountService, public restService: RestService, okFunction: Function, dataService:any) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
    this.queueFeatureEnabled = accountService.isFeatureEnabled(EFE2Features.QUEUES);
    this.user = dataService.getAccount();
  }

  /**
   * Search for vehicles
   */
  searchForVehicle() {
    if (this.vehicleSearch === '') {
      this.vehicles = [];
      return;
    }
    if (this.vehicleSearch.length < 2) {
      // Too few characters
      return;
    }

    this.restService.searchForVehiclesAccessibleForUser(this.vehicleSearch, this.user.id, 10).then(result => {
      this.vehicles = result as VehicleNameIDResponse[];
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  /**
   * Remove the vehicle mapping
   */
  removeVehicle() {
    this.selectedVehicle = undefined;
  };

  /**
   * Set the mapping between vehicle and aMobile
   */
  addVehicle(vehicle: Vehicle) {
    this.selectedVehicle = vehicle;
    this.vehicleSearch = '';
    this.vehicles = [];
  };


  ok() {
    this.$uibModalInstance.close();

    let request = {
      name: this.name,
      unitType: this.unitType,
      connectedVehicleId: this.selectedVehicle ? this.selectedVehicle.id : undefined
    } as AddUnitRequest;
    this.okFunction(request);
  };

  cancel() {
    this.$uibModalInstance.close();
  };
}
