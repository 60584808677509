'use strict';


require('./preview.scss');

export default class PreviewComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      link: '='
    }
    this.template = require('./preview.html');

    this.controller = PreviewComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class PreviewComponentController {
  private link: string;

  help() {
    if (this.link) {
      window.open(this.link, '_blank').focus();
    } else {
      window.open('https://alamos-support.atlassian.net/wiki/x/AQDcmQ', '_blank').focus();
    }
  }

}
