import {equals, IWindowService} from "angular";
import PrivilegeService from "../../../../../services/privilege.service";
import RestService from "../../../../../services/rest.service";
import {ProtocolModalMode} from "../../../../modals/admin/rest.protocol.modal/rest.protocol.modal";

require("./rest.protocol.headers.component.scss")
/* @ngInject */
export default class RestProtocolHeadersComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./rest.protocol.headers.component.html');
    this.scope = {
      data: '=',
      mode: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = RestProtocolHeadersController;

  }
}

class RestProtocolHeadersController {
  public data: Map<string, string>;
  public mode: ProtocolModalMode;

  constructor(
    private $window: IWindowService,
    private $http,
    private restService: RestService,
    public privilegeService: PrivilegeService) {
  }

  isDataEmpty() {
    return this.data == null || equals({}, this.data);
  }


}
