import { ILogService } from 'angular';
import { IRootScopeService } from 'angular';
import { IScope } from 'angular';
'use strict';

export default class AvailabilityOverallSummaryComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '='
      }
    this.template = require('./overall.summary.component.html');

    this.controller = AvailabilityOverallSummaryController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityOverallSummary
/* @ngInject */
class AvailabilityOverallSummaryController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public dataService: any;
  public validRules: any;
  public isLoading: any;
  public isAvailabilityPositive: boolean;
  public listeners: any[];
  public isAvailabilityLoading: boolean;
  public onlineservice: any;

  constructor($scope, $rootScope, $log, dataService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.dataService = dataService;
    this.$log.debug('Loading AvailabilityOverallSummaryController');

    this.validRules = [];
    this.isLoading = true;
    this.isAvailabilityPositive = true;

    this.listeners = [];

    this.initListeners();

    this.listeners.push(this.$scope.$watch('ctrl.onlineservice', (newValue, oldValue) => {
      if (newValue) {
        this.updateUI();
        this.isLoading = false;
      }
    }));
  }

  initListeners() {
    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }


  updateUI() {
    this.isAvailabilityPositive = this.onlineservice.statistic.available >= this.onlineservice.statistic.notAvailable + this.onlineservice.statistic.tempNotAvailable;

    this.validRules = this.onlineservice.rules.filter((validatedRule) => {
      if (validatedRule.rule.type !== 'AVAILABILITY') {
        return true;
      }

      return validatedRule.valid;
    });
  }
}
