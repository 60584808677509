'use strict';
import ErrorService from "../../../../services/error.service";
import {ILogService, IRootScopeService, IScope, IWindowService} from "angular";
import RestService from "../../../../services/rest.service";
import PrivilegeService from "../../../../services/privilege.service";

require('./export.inRangeAndFilters.modal.css')
/* @ngInject*/
export default class ExportInRangeAndFiltersModalController {
  public $uibModalInstance: any;
  public isLoading: boolean = false;
  public errorService: ErrorService;
  public $log: ILogService;
  public $http: any;
  public $window: IWindowService;
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public startDate: Date;
  public endDate: Date;
  public url: string;
  public title: string;
  public filtering: any;
  public selectedFuncs: string[];
  public selectedGroups: string[];
  public selectedRules: string[];

  constructor( url, filtering, $scope: IScope, $rootScope: IRootScopeService, $uibModalInstance, errorService: ErrorService, $log: ILogService,$window, $http,restService: RestService,public privilegeService: PrivilegeService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.errorService = errorService;
    this.$log = $log;
    this.$http = $http;
    this.restService = restService;
    this.$window = $window;
    this.url = url;
    this.filtering = filtering;
    this.selectedFuncs=[];
    filtering?.filteredFunctions.forEach(x=> this.selectedFuncs.push(x));
    this.selectedGroups=[];
    filtering?.filteredGroups.forEach(x=> this.selectedGroups.push(x));
    this.selectedRules=[];
    filtering?.filteredRules.forEach(x=> this.selectedRules.push(x.rule.name));
    this.title = (this.filtering?.full === 'true')? 'EXTENDED_TITLE':'TITLE';
    this.endDate=new Date();
    this.endDate.setMilliseconds(0);
    this.endDate.setSeconds(0);

  }
  isFuncSelected(func: string){
    return this.selectedFuncs.includes(func);
  }
  isGroupSelected(group: string){
    return this.selectedGroups.includes(group);
  }
  isRuleSelected(rule: string){
    return this.selectedRules.includes(rule);
  }
  selectFunc(func: string){
    if(this.selectedFuncs.includes(func)){
      this.selectedFuncs = this.selectedFuncs.filter(x=> x!==func);
      return;
    }
    this.selectedFuncs.push(func);
  }
  selectGroup(group: string){
    if(this.selectedGroups.includes(group)){
      this.selectedGroups = this.selectedGroups.filter(x=> x!==group);
      return;
    }
    this.selectedGroups.push(group);
  }

  selectRule(rule: string){
    if(this.selectedRules.includes(rule)){
      this.selectedRules = this.selectedRules.filter(x=> x!==rule);
      return;
    }
    this.selectedRules.push(rule);
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  export() {
      const auth = this.$http.defaults.headers.common.Authorization;
      let start = (this.startDate) ? this.startDate.getTime() : 0;
      let end = (this.endDate) ? this.endDate.getTime() : 0;
      let addQueryParams = this.addFilters();
      const url = `${this.restService.getBaseUrl()}${this.url}?Authorization=${auth}&start=${start}&end=${end}${addQueryParams}`;
      this.$window.open(url, '_blank');
  }

  addFilters(){
    if(!this.filtering){
      return "";
    }
    let filteredFunctions = this.joinFiltering(this.selectedFuncs);
    let filteredGroups =  this.joinFiltering(this.selectedGroups);
    let filteredRules = this.joinFiltering(this.selectedRules);
    return "&filteredFunctions=" + encodeURIComponent(filteredFunctions)
      +"&filteredGroups=" + encodeURIComponent(filteredGroups)
      +"&filteredRules=" + encodeURIComponent(filteredRules)
      +"&full=" + this.filtering?.full;
  }


  private joinFiltering(filtered: string[]){
    if(!filtered){
      return "";
    }
    return filtered.join(";");
  }

}