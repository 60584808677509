'use strict'

import { ILogService, IScope } from "angular";
import ErrorService from "../../../../services/error.service";
import RestService from "../../../../services/rest.service";

require("./import.role.modal.css")
/* @NgInject */
export default class ImportRoleModalController {

    public isLoading: boolean = false;

    constructor(private $uibModalInstance: any,
        private uploader: any,
        private $log: ILogService,
        private restService: RestService,
        private $http: any,
        private errorService: ErrorService) {


        this.uploader.onCompleteAll = () => {
            this.$log.info('onCompleteAll');
            this.$uibModalInstance.close();
        };

        this.uploader.onBeforeUploadItem = (item) => {
            this.$log.info('Changing URL before uploading...');
            // Update URL for upload
            item.url = this.restService.getBaseUrl() + '/roles/import';
        };

        this.uploader.headers = {
            Authorization: this.$http.defaults.headers.common.Authorization
        };

    }

    public cancel() {
        this.$uibModalInstance.close();
    }

    public import() {
        this.isLoading = true;
        this.uploader.onErrorItem = (item, response, status) => {
            this.errorService.notifyWithText(response.message);
        };

        this.uploader.uploadAll();
    }
}