import angular from 'angular';
import 'angular-ui-bootstrap';

import services from '../../services';
import filter from '../../filters';
import adminComponents from './admin';
import objectComponents from './objects';
import addressBookComponents from './addressbook';
import apagerComponents from './apager';
import wacheComponents from './wache';
import alarmComponents from './alarm';
import availabilityComponents from './availability';
import layersComponent from './layers';
import pipelineComponents from './pipeline';
import calendarComponents from './calendar';
import miscComponents from './misc';
import unitsComponents from './units';
import queueComponents from './queues';
//import modals from '../modals';


export default angular.module('FE2.components', ['ui.bootstrap', services,
  filter,
  adminComponents,
  objectComponents,
  addressBookComponents,
  apagerComponents,
  wacheComponents,
  alarmComponents,
  availabilityComponents,
  layersComponent,
  pipelineComponents,
  calendarComponents,
  miscComponents,
  unitsComponents,
  queueComponents
])
  .name;
