'use strict';

import { ILogService, IRootScopeService } from "angular";
import { RolePrivilege } from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";

require('./customFields.shiftbook.modal.css');

export default class CustomFieldsShiftbookModalController {
  public isEditingFieldById = '';
  public tmpCustomFieldName = '';
  public hasError = false;
  public isLoading = false;
  public tmpNewName = '';

  constructor(public $uibModalInstance,
    public $log: ILogService,
    public $uibModal,
    public $rootScope: IRootScopeService,
    public dataService,
    public customFields,
    public privilegeService: PrivilegeService) {
  }

  close() {
    this.$uibModalInstance.close();
  }

  editCustomField(field) {
    if (!this.privilegeService.has(RolePrivilege.Station_Shiftbook_Fields_Edit)) {
      return;
    }
    if (this.isEditingFieldById !== '') {
      //An edit was not cancelled correctly so we reset the previous field name
      var _self = this;
      this.customFields.forEach(function (field) {
        if (field.customFieldId === _self.isEditingFieldById) {
          field.fieldName = _self.tmpCustomFieldName;
        }
      })
    }
    this.isEditingFieldById = field.customFieldId;
    this.tmpCustomFieldName = field.fieldName;
  }

  save(field) {
    this.isLoading = true;
    if (field.fieldName.length > 0) {
      this.isEditingFieldById = '';
      this.dataService.editShiftbookCustomField(field, (response) => {
        this.isLoading = false;
      }, (errorResponse) => {
        this.isLoading = false;
        this.$log.error('Could not save custom field', errorResponse);
      });
    }
  }

  cancelEdit(field) {
    this.isEditingFieldById = '';
    field.fieldName = this.tmpCustomFieldName;
  }

  create() {
    if (this.tmpNewName !== undefined && this.tmpNewName.length > 0) {
      this.isLoading = true;
      this.dataService.createShiftbookCustomField(this.tmpNewName, (response) => {
        this.customFields.push(response);
        this.tmpNewName = '';
        this.isLoading = false;
      }, (errorResponse) => {
        this.isLoading = false;
        this.$log.error('Could not create custom Field', errorResponse);
      })
    }
  }

  delete(field) {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.dataService.deleteShiftbookCustomField(field, () => {
              this.isLoading = false;
              var index = this.customFields.indexOf(field);
              this.customFields.splice(index, 1);
              this.$rootScope.$emit('shiftbook.customFieldDeleted');
              //this.$uibModalInstance.close();
            }, (response) => {
              //Error occured
              this.isLoading = false;
              this.$log.error(response);
            });
          };
        },
        additionalText: () => {
          return 'SHIFTBOOK.CUSTOMFIELD.DELETE_TEXT';
        }
      }
    });
  }
}
