'use strict';
import {IScope} from "angular";
import {
  Emergency, EMissionReportState,
  MissionReport, MissionReportPersonRequest, MissionReportResourcesRequest
} from "../../../../../data/emergency.data";
import RestService from "../../../../../services/rest.service";
import {
  PersonSearchQueryResult
} from "../../../../../data/person.data";
import PrivilegeService from "../../../../../services/privilege.service";
import {RolePrivilege} from "../../../../../data/privileges.enum";

import {VehicleForReport} from "../../../../../data/vehicles.data";
/* @ngInject */
export default class MissionReportResourceTablerowComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A';
    this.scope = {
      report: '=',
      emergency: '=',
      vehicle: '='
    }
    this.template = require('./mission.report.resource.tablerow.component.html');
    this.controller = MissionReportResourceTablerowController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
require('./mission.report.resource.tablerow.component.scss')

class MissionReportResourceTablerowController {
  public $scope: IScope;
  public report: MissionReport;
  public vehicle: VehicleForReport;
  public hasEditRight: boolean = false;
  public emergency: Emergency;
  public person: PersonSearchQueryResult;


  constructor($scope: IScope, public $rootScope: any, public privilegeService: PrivilegeService, public restService: RestService, public $uibModal) {
    this.$scope = $scope;

    this.$scope.$watch("ctrl.vehicle", (newValue: VehicleForReport, oldValue) => {
      if (this.vehicle) {
        if (this.report && this.report.reportResources) {
          this.hasEditRight = this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Report_Write);
          if (this.report.state === EMissionReportState.PUBLISHED) {
            this.hasEditRight = false;
          }

          let reportVehicle = this.findVehicle(this.vehicle);
          if (reportVehicle && reportVehicle.vehicleLeader) {
            this.person = {
              personID: reportVehicle.vehicleLeader.personId,
              displayName: reportVehicle.vehicleLeader.displayName,
              osId:  reportVehicle.vehicleLeader.osId
            } as PersonSearchQueryResult;
          }
        }
      }
    })
  }

  /**
   * handler on changed vehicle leader field. To remove the value if name is simply removed
   */
  valueChanged() {
    var resource = this.report.reportResources.find(resource => resource.vehicleId === this.vehicle.id);
    resource.vehicleLeader = {
      displayName: this.person.displayName
    } as MissionReportPersonRequest;
  }

  getVehicleName() {
    if (this.vehicle) {
      if (this.vehicle.shortName){
        return this.vehicle.shortName;
      }
      return this.vehicle.name;
    }
  }

  getPersons(val) {
    return this.restService.searchForPersons(val, this.emergency.parentUserId);
  }


  /**
   * adds the vehicle reference to the report
   * @param vehicle
   */
  addVehicle(vehicle: VehicleForReport) {
    var toSaveResource = {
      vehicleId: vehicle.id
    } as MissionReportResourcesRequest;

    if (!this.report.reportResources.some(saved=> saved.vehicleId === toSaveResource.vehicleId)) {
      this.report.reportResources.push(toSaveResource);
      this.$scope.$emit('reportResource.add', vehicle);
    }

  }

  /**
   * set vehicle leader selected to the correct report vehicle entry
   * @param person chosen from user
   */
  vehicleLeaderSelected(person) {
    var resource = this.report.reportResources.find(vehicleRep => vehicleRep.vehicleId === this.vehicle.id);
    resource.vehicleLeader = {
      personId: person.personID,
      displayName: person.displayName,
      osId: person.osId
    } as MissionReportPersonRequest;
    this.person = person;
    return person;
  }

  /**
   * handle the toggle of ui by adding or removing vehicle references from report
   * @param checked boolean if it is currently check or not
   * @param vehicle alarm vehicle ref from emergency
   */
  toggleVehicle(checked: boolean, vehicle: VehicleForReport) {
    if (checked) {
      this.removeVehicle(vehicle);
    } else {
      this.addVehicle(vehicle);
    }
  }

  private removeVehicle(vehicle: VehicleForReport) {
    var index = this.report.reportResources.findIndex(resource => resource.vehicleId === vehicle.id);
    this.report.reportResources.splice(index, 1);
    this.$scope.$emit('reportResource.remove', vehicle)
    this.person = undefined;
  }

  findVehicle(vehicle: VehicleForReport){
    return this.report.reportResources.find(reportVehicle => reportVehicle.vehicleId === vehicle.id);
  }

  /**
   * return the saved report vehicle
   * @param vehicle alarm vehicle ref from emergency object
   */
  reportHasVehicle(vehicle: VehicleForReport): boolean {
    if (this.report && this.report.reportResources && vehicle) {
      return this.report.reportResources.some(reportVehicle => reportVehicle.vehicleId === vehicle.id);
    }
  }

}