import AdminService from "../../../../services/admin.service";
import RestService from "../../../../services/rest.service";
import {BuildingControllerTypesResponse} from "../../../../data/controller.data";
import {IScope} from "angular";

require('./add.controller.modal.scss')
export default class AddControllerModalController {
  public $uibModalInstance: any;
  public $scope:IScope;
  public okFunction: any;
  public isLoading = false;
  public isLoadingControllers = false;
  public controllerTypes: BuildingControllerTypesResponse[];
  public adminService: AdminService;
  public selectedInstance:BuildingControllerTypesResponse;
  public restService:RestService;

  private name: string;

  constructor($uibModalInstance: any, $scope:IScope,okFunction: any, adminService: AdminService, restService: RestService) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope= $scope;
    this.okFunction = okFunction;
    this.adminService = adminService;
    this.restService = restService;

    this.isLoadingControllers= true;
    this.restService.getBuildingControllerTypes().then((response)=>{
      this.controllerTypes = response;
      this.selectedInstance= response.find(contoller=> !contoller.deprecated);
    }).finally(()=>{
      this.isLoadingControllers= false;
      this.$scope.$applyAsync();
    })
  }

  cancel(){
    this.$uibModalInstance.close();
  }

  ok() {
    this.isLoading = true;
    this.restService.addBuildingController(this.name, this.selectedInstance.type).then((response) => {
      this.okFunction(response);
      this.$uibModalInstance.close();
    }).finally(() => {
      this.isLoading = false;
    });

  }
}