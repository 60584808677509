'use strict';

import { ILocationService, IScope } from "angular";
import { UserAccount } from "../../../../data/account.data";

/* @ngInject */
export default class SelfRegistrationModalController {
  public $scope: IScope;
  public $uibModalInstance: any;
  public $location: ILocationService;
  public dataService: any;
  public isRegenerating: boolean = false;
  public link: string;
  public account: UserAccount;

  constructor($scope: IScope, $uibModalInstance, $location: ILocationService, dataService) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.$location = $location;
    this.dataService = dataService;

    if (this.dataService.hasAccount()) {
      this.init();
    }

  }
  regenerate() {
    this.isRegenerating = true;

    this.dataService.regenerateSelfRegistrationKey((response) => {

      this.generateLink(response.key);
      this.isRegenerating = false;
    }, (err) => {
      // Something went wrong
      this.isRegenerating = false;
    });
  }

  generateLink(key: string) {
    let external = this.account.external;

    if (!this.account.external.startsWith('http')) {
      // Link does not contain http yet
      external = this.$location.protocol() + '://' + external + ':' + this.$location.port();
    }

    this.link = external + '/index.html#!/external/register?auth=' + key;
  };

  init() {
    this.account = this.dataService.getAccount();
    this.generateLink(this.account.selfRegisterKey);
  }

  cancel() {
    this.$uibModalInstance.close();
  };

}
