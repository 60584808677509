'use strict';
import {IScope, IRootScopeService} from "angular";
import RestService from "../../../../services/rest.service";
import {ProvisioningGeoFenceSelection} from "../../../../data/provisioning.data";
import {UserAccount} from "../../../../data/account.data";
import { ProvisioningGeoFenceEdit} from "../../../../data/geofence.data";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
require('./geofence.component.scss')
export default class GeofenceComponent{
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {},
      this.template = require('./geofence.component.html');

    this.controller = GeofenceController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
/* @ngInject */
class GeofenceController{
  public $scope: IScope;
  public $rootScope:IRootScopeService;
  public restService:RestService;
  public account:UserAccount
  public data: ProvisioningGeoFenceSelection[];
  public listeners = [];
  public isLoading:boolean;
  public $uibModal: any;
  public priv:PrivilegeService;
  public hasGeofence: boolean=false;
  public hasGeofenceCreate: boolean=false;


  constructor($scope: angular.IScope, $rootScope: angular.IRootScopeService, restService: RestService, $uibModal, privilegeService:PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.restService = restService;
    this.$uibModal = $uibModal;
    this.priv= privilegeService;

    this.listeners.push(this.$rootScope.$on('addressbook.view.tab', (event, source) => {
      if (source === 7) {
        this.hasGeofence = this.priv.has(RolePrivilege.Addressbook_Geofences);
        this.hasGeofenceCreate = this.priv.has(RolePrivilege.Addressbook_Geofences_Create);
        this.loadGeofences();
        this.reloadOnDelete();
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });


  }

  private loadGeofences() {
    if (!this.hasGeofence){
      return;
    }
    this.isLoading = true;
    this.restService.loadAllGeofenceForUser().then((result) => {
      this.data = result;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  reloadOnDelete(){
    this.$rootScope.$on('delete.geofence',()=>{
      this.loadGeofences();
      this.$scope.$applyAsync();
    })
  }

  openModal(provisioningGeoFence: ProvisioningGeoFenceEdit){
    var instant = this.$uibModal.open({
      template: require('../../../modals/addressbook/edit.geofence.modal/edit.geofence.modal.html'),
      controller: 'EditGeofenceModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        provisioningGeofence: ()=> {
          return provisioningGeoFence;
          }
      }
    });
    instant.result.then(()=>{
      this.isLoading = true;
      this.restService.loadAllGeofenceProvisioningsForUser().then((result)=>{
        this.data = result;
      }).finally(()=>{
        this.isLoading =false;
        this.$scope.$applyAsync();
      })
    })

  }

  addGeofence(){
    if (!this.hasGeofenceCreate){
      return;
    }
    this.openModal(undefined);


  }


}