import 'angular-file-upload'

import HelperService from './helper.service'
import RestService from './rest.service.ts'
import DataService from './data.service'
import ErrorService from './error.service'
import SocketService from './socket.service'
import DashboardService from './dashboard.service'
import AccountService from "./account.service";
import MapService from "./map.service";
import AdminService from "./admin.service";
import MessagesService from "./messages.service";
import TestService from "./test.service.ts";
import CalendarService from "./calendar.service.ts";
import UnitsService from "./units.service.ts";
import VehicleService from "./vehicle.service.ts";
import PrivilegeService from "./privilege.service";

export default angular.module('FE2.services', ['angularFileUpload'])
  .service('restService', RestService)
  .service('helperService', HelperService)
  .service('dataService', DataService)
  .service('errorService', ErrorService)
  .service('socketService', SocketService)
  .service('dashboardService', DashboardService)
  .service('accountService', AccountService)
  .service('mapService', MapService)
  .service('adminService', AdminService)
  .service('messagesService', MessagesService)
  .service('testService', TestService)
  .service('calendarService', CalendarService)
  .service('unitsService', UnitsService)
  .service('vehicleService', VehicleService)
  .service('privilegeService', PrivilegeService)
  .name;
