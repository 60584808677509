'use strict';

import {ILogService, IScope, IWindowService} from "angular";
import {PrivilegeTranslation, Role, RolePrivilegeCategoryResponse, RolePrivilegeResponse, RoleUpdateRequest} from "../../../../data/role.data";
import RestService from "../../../../services/rest.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {UserAccount} from "../../../../data/account.data";
import {is, val} from "angular-ui-router";

//Controller for functions and groups modal

require('./roles.css');

/* @ngInject */
export default class RolesModalController {
  public restService: RestService;
  public $scope: IScope;
  public $translate;
  public $uibModal: any;
  public $log: ILogService;
  public $uibModalInstance: any;

  public roles: Role[] = [];
  public activeRole: Role;
  public filteredPrivileges: Map<string, PrivilegeTranslation[]>;
  public isLoading = true;
  public priv:PrivilegeService;
  public hasCreate: boolean= false;
  public hasEdit:boolean= false;
  public hasDelete:boolean= false;
  public account: UserAccount;
  public isOwnRole:boolean=false;
  public toggleAll = true;
  public toggleOnlyActive = false;
  public searchFilter: string = '';
  public isAdmin = false;
  public privilegeCategories: string[];
  public activeCategory: string;

  constructor($scope: IScope, $translate, $uibModal, $uibModalInstance, restService: RestService,
    privilegeService: PrivilegeService, dataService,
    private $window: IWindowService, private $http: any, private FileUploader: any) {
    this.$scope = $scope;
    this.$translate = $translate;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.priv = privilegeService;
    this.account= dataService.getAccount() as UserAccount;
    this.isAdmin = this.account.admin;

    if (this.isAdmin) {
      this.activeCategory = 'Administration';
    } else {
      this.activeCategory = 'Adressbuch';
    }
    this.hasCreate = this.priv.has(RolePrivilege.User_Roles_Create);
    this.hasEdit = this.priv.has(RolePrivilege.User_Roles_Edit);
    this.hasDelete = this.priv.has(RolePrivilege.User_Roles_Delete);

    this.loadRoles();
  }

  close() {
    this.$uibModalInstance.close();
  }

  /**
   * Load roles
   */
  loadRoles(roleIdToSelect?: string) {
    this.isLoading = true;
    this.restService.loadRoles().then((roles) => {
      this.roles = roles;
      if (this.roles.length > 0) {
        this.roles.forEach(role => {
          const map = new Map(Object.entries(role.translatedCategories));
          role.translatedCategories = map;
        });
        if (roleIdToSelect) {
          const filtered = this.roles.filter(role => role.id === roleIdToSelect);

          if (filtered.length > 0) {
            this.selectActiveRole(filtered[0]);
          } else {
            this.selectActiveRole(this.roles[0]);
          }
        } else {
          this.selectActiveRole(this.roles[0]);
        }
      } else {
        this.selectActiveRole(undefined);
      }
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  filterForNonAdmin() {
    this.privilegeCategories = [];
    this.filteredPrivileges = new Map<string, PrivilegeTranslation[]>();
    for (let entry of Array.from(this.activeRole.translatedCategories.entries())) {
      let category = entry[0];
      let privs = entry[1];

      if (privs.category.priv === RolePrivilege.Admin && !this.isAdmin) {
        this.filteredPrivileges.delete(category);
        continue;
      }

      const tmpList: PrivilegeTranslation[] = [];
      tmpList.push(privs.category);
      tmpList.push(...privs.childs)
      this.privilegeCategories.push(category);

      this.filteredPrivileges.set(category, tmpList);
    }

    this.$scope.$applyAsync();
  }

  selectActiveRole(role: Role) {
    this.activeRole = role;
    this.checkIsOwnRole();
    if (role) {
      this.filterForNonAdmin();
      if (this.searchFilter) {
        this.onSearchFilterChanged();
      }
    }
  }

  getPrivilegesForCategory(category: string) {
    return this.filteredPrivileges.get(category);
  }

  checkIsOwnRole(){
    let f = false;
    for (const x of this.account.roles) {
      f = f || x.id === this.activeRole?.id;
    }
    this.isOwnRole = f;
  }

  onSearchFilterChanged() {
    this.toggleOnlyActive = false;
    this.filteredPrivileges = new Map<string, PrivilegeTranslation[]>();
    if (this.searchFilter) {
      const tmp = this.searchFilter.toLowerCase();
      for (let entry of Array.from(this.activeRole.translatedCategories.entries())) {
        let category = entry[0];
        let privs = entry[1];

        if (privs.category.priv !== RolePrivilege.Admin || this.isAdmin) {
          const tmpList: PrivilegeTranslation[] = [];
          if (privs.category.translation.toLocaleLowerCase().includes(tmp)) {
            tmpList.push(privs.category);
          }
          tmpList.push(...privs.childs.filter(priv => priv.translation.toLocaleLowerCase().includes(tmp)));
          this.filteredPrivileges.set(category, tmpList)
        }
      }
    } else {
      this.resetSearchFilter();
    }
  }

  resetSearchFilter() {
    this.searchFilter = '';
    this.selectActiveRole(this.activeRole);
  }

  toggleAllActive() {
    if (this.toggleOnlyActive) {
      this.resetSearchFilter();
    } else {
      this.searchFilter = '';
      this.filteredPrivileges = new Map<string, PrivilegeTranslation[]>();

      for (let entry of Array.from(this.activeRole.translatedCategories.entries())) {
        let category = entry[0];
        let privs = entry[1];

        if (privs.category.priv === RolePrivilege.Admin && !this.isAdmin) {
          this.filteredPrivileges.delete(privs[0]);
        }

        const tmpList: PrivilegeTranslation[] = [];
        if (privs.category.active) {
          tmpList.push(privs.category);
        }

        tmpList.push(...privs.childs.filter(priv => priv.active));
        this.filteredPrivileges.set(category, tmpList);
      };

    }
    this.toggleOnlyActive = !this.toggleOnlyActive;
  }

  selectPrivilege(priv: PrivilegeTranslation) {
    if (this.activeRole.shared) return;
    priv.active = !priv.active;
  }

  selectAll() {
    if (this.isOwnRole){
      return;
    }
    if (!this.hasEdit) return;
    if (!this.activeRole) return;
    if (this.activeRole.shared) return;

    this.resetSearchFilter();
    let privs = this.filteredPrivileges.get(this.activeCategory);
    privs.forEach(priv => {
      priv.active = this.toggleAll;
    });

    this.toggleAll = !this.toggleAll;
    this.filterForNonAdmin();
  }



  /**
   * Add a new role
   */
  addRole() {
    this.isLoading = true;

    this.$translate('USERS.ROLE_DEFAULT_NAME').then((translation) => {
      this.restService.addRole(translation).then((addedRole) => {
        this.loadRoles(addedRole.id);
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
    });


  }

  /**
   * Add a new role
   */
  saveRole() {
    if (!this.activeRole) return;
    this.isLoading = true;

    const allPrivileges = [];

    this.activeRole.translatedCategories.forEach((privs) => {
      allPrivileges.push(privs.category);
      allPrivileges.push(...privs.childs);
    });

    const roleUpdateRequest = {
      id: this.activeRole.id,
      name: this.activeRole.name,
      note: this.activeRole.note,
      active: this.activeRole.active,
      shared: this.activeRole.shared,
      userId: this.activeRole.userId,
      inUse: this.activeRole.inUse,
      allPrivileges
    } as RoleUpdateRequest;

    this.restService.saveRole(roleUpdateRequest).then((result) => {
      const map = new Map(Object.entries(result.translatedCategories));
      result.translatedCategories = map;
      this.roles = this.roles.map(role => {
        if (role.id === result.id) {
          return result;
        }
        else {
          return role;
        }
      });
      this.selectActiveRole(result);
    }).finally(() => {
      this.isLoading = false;
      this.toggleOnlyActive = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Delete an existing role
   */
  deleteRole() {
    if (!this.activeRole) return;
    if (!this.hasDelete)return;
    if (this.activeRole.inUse){
      this.openSaveDeleteModal();
    }else {
      this.makeDeleteRequest();
    }


  }

  private makeDeleteRequest() {
    this.isLoading = true;
    this.restService.deleteRole(this.activeRole.id).then(() => {
      this.loadRoles();
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Duplicate an existing role
   */
  duplicateRole() {
    if (!this.activeRole) return;
    this.isLoading = true;
    this.restService.duplicateRole(this.activeRole).then(() => {
      this.loadRoles();
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  private openSaveDeleteModal() {
     let modalInstance=  this.$uibModal.open({
       template: require('../savedelete.roles.modal/savedelete.roles.modal.html'),
       controller: 'SavedeleteRolesModalController',
       controllerAs: 'ctrl',
       size: 'md',
       resolve: {
         okFunction: ()=>{
           return (doDelete)=>{
             if (doDelete){
               this.makeDeleteRequest();
             }
           }
         }
       }
       }
     );
  }

  exportRole() {
    this.$window.open(
      `${this.restService.getBaseUrl()}/roles/export/${this.activeRole.id}?Authorization=${this.$http.defaults.headers.common.Authorization}`,
      '_blank'
    );
  }

  importRole() {
    const modalInstance = this.$uibModal.open({
      template: require("../import.role.modal/import.role.modal.html"),
      controller: 'ImportRoleModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        uploader: () => {
          let fileUploader = new this.FileUploader();
          return fileUploader;
        }
      }
    })
    modalInstance.result.then(() => this.loadRoles())
  }
}
