'use strict'

import { PatientCapacity } from "../../../../data/objects.data";

require("./patient.capacity.component.scss");

export default class PatientCapacityComponent {
    public restrict: any;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: any;
    public bindToController: any;

    constructor() {
        this.restrict = 'E'
        this.template = require('./patient.capacity.component.html');
        this.scope = {
            capacity: "=",
            isEditable: "=",
            typeTranslation: "="
        };
        this.controller = PatientCapacityComponentController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class PatientCapacityComponentController {

    public isEditable: boolean;
    public capacity: PatientCapacity;
    public typeTranslation: string;
    
    constructor() {
    }
}