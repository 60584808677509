export interface CustomFeedbackResponseResponse {
  id: string;
  userId: string;
  name: string;
  description: string;
  numberOfOptions: number;
}

export interface CreateCustomFeedbackResponseRequest {
  name: string;
  description: string;
}

export interface CustomFeedbackEntryResponse {
  id: string;
  parentId: string;
  type: EFeedbackState;
  name: string;
}

export enum EFeedbackState {
  YES = 'YES',
  NO = 'NO',
  ABSENT = 'ABSENT',
  RECEIVED = 'RECEIVED',
  READ = 'READ',
  FREE = 'FREE',
  HERE = 'HERE'
}

export interface CreateCustomFeedbackEntryRequest {
  parentId: string;
  type: EFeedbackState;
  name: string;
}
