'use strict';

/* @ngInject */
import {ProtocolEntryAdditionalInformation} from "../../../../data/admin.data";
import RestService from "../../../../services/rest.service";

export default class ModalProtocolAdditionalController {
  private $scope: any;
  private $uibModalInstance: any;
  private $sce: any;
  private $log: any;
  private restService: RestService;
  private entryId: string;
  private data: ProtocolEntryAdditionalInformation;


    constructor($scope, $uibModalInstance, $sce, restService, $log, entryId) {
      this.$scope = $scope;
      this.$uibModalInstance = $uibModalInstance;
      this.$sce = $sce;
      this.$log = $log;
      this.restService = restService;
      this.entryId = entryId;

      restService.loadAdditionalProtocolInformation(entryId, response => {
        this.data = response.data;
      }, (error, res) => {
        $log.debug("Fehler beim Laden der Zusatzinformationen", error)
        $uibModalInstance.close();
      })
    }

    cancel() {
        this.$uibModalInstance.close();
    }
}
