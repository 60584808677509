'use strict';

import { IRootScopeService, IAngularEvent, IScope } from "angular";
import { CustomFeedbackResponseResponse } from "../../../../data/customFeedback.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";
import UnitsService from "../../../../services/units.service";


require('./custom.feedback.tr.css');

export default class CustomFeedbackTableRowComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./custom.feedback.tr.html');
    this.scope = {
      entry: '='
    };
    this.controller = CustomFeedbackTableRowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}




/* @ngInject */
class CustomFeedbackTableRowComponentController {
  entry: CustomFeedbackResponseResponse;
  public isLoading: boolean = false;
  public $uibModal;
  public unitsService: UnitsService;
  public $rootScope: IRootScopeService;

  constructor($rootScope: IRootScopeService, unitsService: UnitsService, public privilegeService: PrivilegeService, $uibModal) {
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.unitsService = unitsService;
  }


  /**
   * Edit custom feedback entry
   */
  editCustomFeedback() {
    if (!this.privilegeService.has(RolePrivilege.Units_Custom_Feedback_Edit)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/customFeedback/edit.custom.feedback.modal/edit.custom.feedback.modal.html'),
      controller: 'EditCustomFeedbackModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        customFeedback: () => {
          return this.entry;
        },
        okFunction: () => {
          return () => {
            this.$rootScope.$emit('update.custom.feedback');
          };
        },
        closeFunction: () => {
          return () => {
            this.$rootScope.$emit('update.custom.feedback');
          };
        }
      }
    });
  }

  /**
   * Delete an custom feedback entry
   */
  deleteCustomFeedback() {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        additionalText: () => {
          return 'CUSTOM_FEEDBACK.CONFIRM_DELETE';
        },
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.unitsService.deleteCustomFeedback(this.entry).finally(() => {
              this.$rootScope.$emit('update.custom.feedback');
              this.isLoading = false;
            });
          };
        }
      }
    });
  }
}