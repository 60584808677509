import { IScope } from 'angular';
'use strict';

import { CustomerNotificationResponse } from "../../../../data/admin.data";
import RestService from "../../../../services/rest.service";

require('./system.status.modal.scss');


/* @ngInject */
export default class SystemStatusModalController {
  public currentTab = SystemStatusTab.STATUS;
  public notifications: CustomerNotificationResponse[] = [];
  public notificationCount = 0;

  constructor(private $scope: IScope, private $uibModalInstance, private restService: RestService, dataService) {
    var account = dataService.getAccount();
    if (account && account.server){
      this.notificationCount = account.server.numberOfCustomerNotifications;
    }
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  selectTab(newTab: SystemStatusTab) {
    this.currentTab = newTab;
    if (newTab === SystemStatusTab.NOTIFICATIONS) {
      this.restService.loadCustomerNotifications().then((notifications) => {
        this.notifications = notifications;
      }).finally(() => {
        this.$scope.$applyAsync();
      })
    }
  }

}

enum SystemStatusTab {
  STATUS = 'STATUS',
  NOTIFICATIONS = 'NOTIFICATIONS'
}