'use strict';

import angular = require("angular");

//objectContacts
require('./object.contacts.component.scss');
export default class ObjectContactsComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.contacts.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectContactsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectContactsComponentController {
  types: string[];
  alarmObject: any;
  constructor(private $scope: any) {
    this.types = [
      'CONTACT_PERSON',
      'RESIDENT',
      'BMA',
      'FIRE_PREVENTION_OFFICER',
      'CURATOR',
      'DOCTOR_ON_DUTY',
      'CARE_ON_DUTY',
      'OWNER',
      'GAS',
      'CEO',
      'JANITOR',
      'INTENSIVE_CARE_UNIT',
      'CHIMNEY_SWEEPER',
      'GATE',
      'STORE_MANAGEMENT',
      'EMERGENCY_ROOM',
      'SURGERY_AREA',
      'REGULATORY_AGENCY',
      'MANAGEMENT',
      'SECURITY',
      'SECURITY_OFFICER',
      'INCIDENT_OFFICER',
      'RADIATION_PROTECTION_OFFICER',
      'ELECTRICITY',
      'MISC',
      'WATER',
      'PLANT_FIRE',
      'PLANT_SECURITY'
    ];
    this.$scope.$watch('ctrl.alarmObject', () => {
      this.alarmObject.contacts.forEach(contact => {
        contact.birthDate = new Date(contact.birthDate);
      })
    });
  }

  add() {
    const contact = {
      name: '',
      type: 'RESIDENT',
      contact: '',
      company: '',
      note: '',
      email: '',
      title: '',
      formOfAddress: '',
      gender: '',
      birthDate: ''
    };
    if (angular.isUndefined(this.alarmObject.contacts)) {
      this.alarmObject.contacts = [];
    }
    this.alarmObject.contacts.push(contact);
  };

  delete(contact) {
    this.alarmObject.contacts.splice(this.alarmObject.contacts.indexOf(contact), 1);
  };

}
