'use strict';

//ellipsisFilter
/* @ngInject */
export default class EllipsisFilter {
  // leading is type boolean
  static filter(text, fixedLength, leading) {
    if (angular.isUndefined(text)) {
      return text;
    }

    if (text.length > fixedLength) {
      if (leading) {
        var trimmed = "…"+text.substring(text.length - fixedLength, text.length);
        return trimmed;
      } else {
        var trimmed = text.substring(0, fixedLength) + '…';
        return trimmed;
      }
    }

    return text;
  }
}
