'use strict';

import { ILogService, IScope } from "angular";
import { Vehicle } from "../../../../data/vehicles.data";
import RestService from "../../../../services/rest.service";

require('./swap.modal.css');

export interface VehicleSwapOptions {
  swapParkingPositions: boolean;
  swapCodes: boolean;
  swapOnlyFirstCodes: boolean;
  swapVehicleData: boolean;
}

//VehicleModalCtrl
/* @ngInject */
export default class VehicleSwapModalController {
  public $scope: IScope;
  public $log: ILogService;
  public $uibModalInstance: any;
  public restService: RestService;
  public isLoading: boolean = false;
  public loadingVehicle1: boolean = false;
  public loadingVehicle2: boolean = false;
  public hasVehicle1: boolean = false;
  public hasVehicle2: boolean = false;
  public swapParkingPositions: boolean = false;
  public swapCodes: boolean = true;
  public swapOnlyFirstCodes: boolean = true;
  public swapVehicleData: boolean = true;
  public vehicle1: Vehicle;
  public vehicle2: Vehicle;

  public okFunction;

  constructor($scope: IScope, $log: ILogService, $uibModalInstance, restService: RestService, vehicle: Vehicle, okFunction) {
    this.$scope = $scope;
    this.$log = $log;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.okFunction = okFunction;
    if (vehicle) {
      this.vehicle1 = vehicle;
      this.hasVehicle1 = true;
    }
  }


  /**
   * Swap the given vehicles
   */
  swap() {
    this.isLoading = true;
    const swapSettings: VehicleSwapOptions = {
      swapParkingPositions: this.swapParkingPositions,
      swapCodes: this.swapCodes,
      swapOnlyFirstCodes: this.swapCodes && this.swapOnlyFirstCodes,
      swapVehicleData: this.swapVehicleData
    }
    this.restService.swapVehicles(this.vehicle1, this.vehicle2, swapSettings).then(() => {
      this.$uibModalInstance.close();
      this.okFunction();
    }).catch(error => {
      this.$log.error('Error swapping two vehicles')
      this.$log.error(error)
      this.isLoading = false;
    });
  }

  isSwapButtonEnabled() {
    if (!(this.hasVehicle2 && this.hasVehicle1)) return false;
    return this.swapCodes || this.swapParkingPositions || this.swapVehicleData;
  }

  /**
   * Search for vehicles
   * @param queryString 
   */
  getVehicles(queryString: string) {
    return this.restService.searchForVehicles(queryString);
  }

/**
 * Select the first vehicle
 * @param vehicle
 */
  vehicle1Selected(vehicle: Vehicle) {
    this.vehicle1 = vehicle;
    this.hasVehicle1 = true;
    if (this.vehicle1.id === this.vehicle2?.id) {
      this.vehicle2 = undefined;
      this.hasVehicle2 = false;
    }
  }

  /**
   * Select the second vehicle
   * @param vehicle 
   */
  vehicle2Selected(vehicle: Vehicle) {
    this.vehicle2 = vehicle;
    this.hasVehicle2 = true;
    if (this.vehicle2.id === this.vehicle1?.id) {
      this.vehicle1 = undefined;
      this.hasVehicle1 = false;
    }
  }

  deselectVehicle1() {
    this.hasVehicle1 = false;
    this.vehicle1 = undefined;
  }

  deselectVehicle2() {
    this.hasVehicle2 = false;
    this.vehicle2 = undefined;
  }

  cancel() {
    this.$uibModalInstance.close();
  }
}
