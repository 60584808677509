'use strict';
import {BlockedAlarmsPaginated, EBlockedReasonType} from "../../../../../data/admin.data";
import RestService from "../../../../../services/rest.service";
import PrivilegeService from "../../../../../services/privilege.service";
import {RolePrivilege} from "../../../../../data/privileges.enum";
/* @ngInject */
export default class AdminBlockedAlarmsTableComponent{
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.blocked.alarms.table.component.html');
    this.scope = {};
    this.controller = AdminBlockedAlarmsTableController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
require('./admin.blocked.alarms.table.component.scss')
class AdminBlockedAlarmsTableController{
  public blockedAlarmsPaginated: BlockedAlarmsPaginated;
  public restService:RestService;
  public page:number= 0;
  public limit:number= 50;
  public isLoadingBlockedAlarms:boolean= false;
  public searchParam:string;
  public allBlockedReasons: EBlockedReasonType[];
  public filterBlockedReasons: EBlockedReasonType[];

  constructor(public $scope:any, restService: RestService, public $filter) {
    this.$scope = $scope;
    this.restService = restService;
    this.filterBlockedReasons = this.fillEnumValuesAsArray();
    this.allBlockedReasons = this.fillEnumValuesAsArray();

    this.$scope.translatedBlockedReason = p => $filter('translate')('ADMIN.BLOCKED_ALARM.TYPE.' + p);

    this.loadBlockedAlarms();

  }

  fillEnumValuesAsArray():EBlockedReasonType[]{
    return Object.keys(EBlockedReasonType) as EBlockedReasonType[];

  }

  resetSearchAndReload(){
    this.searchParam= '';
    this.searchUnitInBlocked();
  }



  getDisplayPage():number{
    return this.page + 1
  }

  previousPage(){
    this.page--;
    if(this.page <0){
      this.page = 0;
    }
    this.loadBlockedAlarms();
  }

  nextPage(){
    this.page++;
    if (this.page<0){
      this.page == 0;
    }
    this.loadBlockedAlarms();
  }

  searchUnitInBlocked(){
    if (!this.searchParam){
      this.page= 0;
    }
    this.loadBlockedAlarms();
  }
  doTypeFiltering(type:EBlockedReasonType){
    var list= this.filterBlockedReasons;
    let index= list.indexOf(type);
    if (index!== -1){
      list.splice(index,1);
    }else {
      list.push(type);
    }
    this.page=0;
    this.loadBlockedAlarms();
  }

  isFilteredByType(type:EBlockedReasonType){
    var list = this.filterBlockedReasons;
    return list.indexOf(type)!==-1;
  }



  private loadBlockedAlarms() {
    this.isLoadingBlockedAlarms = true;
    let typeBlocked='';
    // if size of array is the same, no filter is aktive. Then leave the query parameter out, to gain performance
    if (this.filterBlockedReasons.length !== this.allBlockedReasons.length){
         typeBlocked = this.filterBlockedReasons.join(';');
    }

    this.restService.loadBlockedAlarms(this.page, this.limit, this.searchParam, typeBlocked).then((blockedAlarms)=>{
      this.blockedAlarmsPaginated = blockedAlarms;
    }).finally(()=>{
      this.isLoadingBlockedAlarms= false;
      this.$scope.$applyAsync();
    });
  }
}