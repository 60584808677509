'use strict';

import { IRootScopeService, IScope } from "angular";
import angular = require("angular");
import PrivilegeService from "../../../../services/privilege.service";

require('./alarm.templates.tablerow.component.css');

//alarmTemplatesRow
export default class AlarmTemplatesTableRowComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./alarm.templates.tablerow.component.html');
    this.scope = {
      template: '=',
      grids: '=',
      account: '='
    };
    this.controller = AlarmTemplatesTableRowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmTemplatesTableRowComponentController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $uibModal: any;
  public dataService: any;
  public listeners = [];
  public isGridSelected = false;
  public selectedGrid: any;
  public gridLoading = false;
  public grids: any;
  public template: any;
  public units: any;
  public isLoading = false;
  public account: any;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModal, dataService, public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.dataService = dataService;

    this.selectedGrid = undefined;

    this.listeners.push(this.$rootScope.$on('grid.updated', (event, grids) => {
      this.grids = grids;
      this.init();
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

    this.$scope.$watch('ctrl.grids', (nVal) => {
      if (angular.isDefined(nVal)) {
        this.init();
      }
    });
  }

  /**
   * Delete existing alarm template
   */
  deleteAlarmTemplateById() {
    this.isLoading = true;
    this.dataService.deleteAlarmTemplateById(this.template, () => {
      this.isLoading = false;
      this.$rootScope.$emit('alarm.template.refresh');
    }, (err) => {
      this.isLoading = false;
    });
  };


  /**
   * Choose a column for template
   * @param {} columnIndex
   * @param {*} template
   */
  chooseColumn(columnIndex: number) {
    // Remove from all columns first
    this.removeFromColumn(this.selectedGrid.column1);
    this.removeFromColumn(this.selectedGrid.column2);
    this.removeFromColumn(this.selectedGrid.column3);
    this.removeFromColumn(this.selectedGrid.column4);
    switch (columnIndex) {
      case 0:
        // Do nothing
        break;
      case 1:
        this.selectedGrid.column1.push(this.template.id);
        break;
      case 2:
        this.selectedGrid.column2.push(this.template.id);
        break;
      case 3:
        this.selectedGrid.column3.push(this.template.id);
        break;
      case 4:
        this.selectedGrid.column4.push(this.template.id);
        break;
    }
    this.gridLoading = true;
    this.dataService.updateAlarmTemplateGrid(this.selectedGrid, (data) => {
      this.gridLoading = false;
      this.grids = data;
    }, (err) => {
      this.gridLoading = false;
    });
  };

  /**
   * Get the templates grid
   */
  getGrid() {
    if (angular.isUndefined(this.grids)) {
      return;
    }
    for (var i = 0; i < this.grids.length; i++) {
      var grid = this.grids[i];
      if (grid.column1.indexOf(this.template.id) !== -1) {
        // Template is in column 1
        return grid;
      }
      if (grid.column2.indexOf(this.template.id) !== -1) {
        // Template is in column 1
        return grid;
      }
      if (grid.column3.indexOf(this.template.id) !== -1) {
        // Template is in column 1
        return grid;
      }
      if (grid.column4.indexOf(this.template.id) !== -1) {
        // Template is in column 1
        return grid;
      }
    }
    // Template is not in grid > use first grid
    return this.grids[0];
  }

  /**
   * Put a template on a grid
   */
  chooseGrid(grid) {

    // Remove from current grid
    this.removeFromColumn(this.selectedGrid.column1);
    this.removeFromColumn(this.selectedGrid.column2);
    this.removeFromColumn(this.selectedGrid.column3);
    this.removeFromColumn(this.selectedGrid.column4);

    // Save old grid
    this.gridLoading = true;
    this.dataService.updateAlarmTemplateGrid(this.selectedGrid, () => {
      this.gridLoading = false;
      this.selectedGrid = grid;
    }, (err) => {
      this.gridLoading = false;
    });


  }

  /**
   * Get the column index for a specific template
   * @param {*} template
   */
  getColumn() {
    if (angular.isUndefined(this.selectedGrid)) {
      // Not yet loaded
      return 0;
    }
    var index = this.selectedGrid.column1.indexOf(this.template.id);
    if (index !== -1) {
      return 1;
    }
    index = this.selectedGrid.column2.indexOf(this.template.id);
    if (index !== -1) {
      return 2;
    }
    index = this.selectedGrid.column3.indexOf(this.template.id);
    if (index !== -1) {
      return 3;
    }
    index = this.selectedGrid.column4.indexOf(this.template.id);
    if (index !== -1) {
      return 4;
    }

    //Default
    return 0;
  };

  /**
   * Remove a template from a column
   */
  removeFromColumn(column) {
    var index = column.indexOf(this.template.id);
    if (index !== -1) {
      column.splice(index, 1);
    }
  };


  /**
   * Edit existing alarm template
   */
  edit() {
    this.$uibModal.open({
      template: require('./../../../modals/alarms/edit.alarm.template.modal/edit.alarm.template.modal.html'),
      controller: 'EditAlarmTemplateModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        template: () => {
          return this.template;
        }
      }
    });
  };

  init() {
    // Init
    this.selectedGrid = this.getGrid();
    this.isGridSelected = true;
  };
}