export enum EAvailabilityState {
    AVAILABLE = 'AVAILABLE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    TEMP_NOT_AVAILABLE = 'TEMP_NOT_AVAILABLE',
    NONE = 'NONE'
}

export interface AvailabilityExternalAccess {
    name: string,
    active: boolean,
    access: Access[]
}

export interface Access {
    osId: number;
    groups: string[];
    functions: string[];
    access: string;
}

export interface FailedRules {
    failedRules: number,
    allRules: number
}

export interface AvailabilitySummaryResponse {
    positive: boolean;
    available: number;
    notAvailable: number;
    tempNotAvailable: number;
    total: number;
    percentage: Map<string, number>;
    validRules: RuleValidationResult[]
    invalidRules: RuleValidationResult[]
}

export interface AvailabilityStatisticsData {

}


export interface RuleValidationResult {
    rule: AvailabilityRule;
    id: number;
    nbrOfValidConditions: number;
    nbrOfInvalidConditions: number
    severity: EAvailabilityRuleSeverity;
    conditions: RuleConditionResultHolder[];
    valid: boolean;
}

export interface RuleConditionResultHolder {
    condition: AvailabilityRuleCondition;
    valid: boolean;
}

export interface AvailabilityRule {
    id: number;
    name: string;
    type: EAvailabilityRuleType;
    severity: EAvailabilityRuleSeverity;
    conditions: AvailabilityRuleCondition[];
    globalGroupCheck: boolean;
}

export interface AvailabilityRuleCondition {
    id: number
    value: number;
    comparison: EAvailabilityRuleComparison;
    comparisonSource: string;
}

export interface DeleteFunctionGroupRequest {
    toRemove: string;
    group: boolean;
}

export enum EAvailabilityRuleComparison {
    FUNCTION = 'FUNCTION',
    GROUP = 'GROUP',
    OVERALL = 'OVERALL'
}

export enum EAvailabilityRuleType {
    AVAILABILITY = 'AVAILABILITY',
    FEEDBACK = 'FEEDBACK'
}

export enum EAvailabilityRuleSeverity {
    INFO = 'INFO',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL'
}