'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import ErrorService from "../../../../services/error.service";
import RestService from "../../../../services/rest.service";

require('./import.addressbook.modal.css');

//Controller for import adressbook
/* @ngInject */
export default class ImportAddressbookModalController {
	public uploader: any;
	public $uibModalInstance: any;
	public $scope: IScope;
	public $rootScope: IRootScopeService;
	public $log: ILogService;
	public $http: any;
	public deleteOnImport: boolean = false;
	public restService: RestService;
	public errorService: ErrorService;
  public isLoading: boolean = false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModalInstance, $log: ILogService, $http, restService: RestService, errorService: ErrorService, uploader) {
    this.uploader = uploader;
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$http = $http;

    this.restService = restService;
    this.errorService = errorService;
    this.uploader = uploader;
    this.uploader.onCompleteAll = () => {
      this.$log.info('onCompleteAll');
      this.$uibModalInstance.close();
    };

    this.uploader.onBeforeUploadItem = (item) => {
      this.$log.info('Changing URL before uploading...');
      // Update URL for upload
      item.url = this.restService.getBaseUrl() + '/addressbook/upload?deleteAddressbook=' + this.deleteOnImport;
    };

    this.uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };
  }

  cancel() {
    this.$uibModalInstance.close();
  };

  import() {
    this.isLoading = true;
    this.uploader.onErrorItem = (item, response, status) => {
      this.errorService.notifyWithText(response.message);
    };

    this.uploader.uploadAll();
  };
}
