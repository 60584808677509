'use strict'

import { IScope } from "angular"
import { PipelineProtocolEntry, Unit } from "../../../../data/unit.data";
import RestService from "../../../../services/rest.service";

require("./pipeline.protocol.modal.css")

/* @ngInject */
export default class PipelineProtocolModalController {

    public page = 0;
    public protocolEntries: PipelineProtocolEntry[];
    public first = false;
    public last = false;

    private readonly limit = 10;
    public isLoading = false;
    
    constructor(private $scope: IScope,
        private $uibModalInstance: any,
        private restService: RestService,
        private Notification: any,
        private unit: Unit) {
            
        this.pageChanged();
    }

    pageChanged() {
        this.isLoading = true;
        this.restService.loadPipelineProtocolEntries(this.unit.id, this.page, this.limit)
            .then(res => {
                this.page = res.number;
                this.first = res.first;
                this.last = res.last;
                this.protocolEntries = res.content;
                // show first entry as opened
                if(res.content && res.content.length > 0) {
                    res.content[0].open = true;
                }
                this.$scope.$applyAsync();
            })
            .catch(err => this.Notification.error(err.message))
            .finally(() => this.isLoading = false);
    }

    close() {
        this.$uibModalInstance.close();
    }

    previousPage() {
        if(this.page > 0 && !this.first) {
            this.page--;
        }
        this.pageChanged();
    }

    nextPage() {
        if(!this.last) {
            this.page++;
        }
        this.pageChanged();
    }
}