'use strict';

/* @ngInject */
export default class AddAlarmGroupModalController{
	public $scope: any;
	public $uibModalInstance: any;
	public okFunction: any;
	public name: any;
	public color: any;

  constructor($scope, $uibModalInstance, okFunction){
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;

    this.name = '';
    this.color = '#337ab7';

  }

  ok () {
    if (this.name == '') {
      return;
    }
    this.$uibModalInstance.close();
    this.okFunction(this.name, this.color);
  };

  cancel () {
    this.$uibModalInstance.close();
  };
}

