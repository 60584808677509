export interface AlarmMonitor {
    address: string;
    id: string;
    name: string;
    type: AlarmMonitorType;
    mqtt: boolean;
    password: string;
    accessPassword: string;
    accessId: string;
    port: number;
    receiveStatus: boolean;
    receiveRoadBlocks: boolean;
    receiveMarker: boolean;
    viaWebsocketConnected: boolean;
  numberOfWebsockets: number;
    lat: number;
    lng: number;
    customExternalFE2URL: string;
}

export interface AlarmMonitorDefaultConfig {
    id: string;
    name: string;
}

export enum AlarmMonitorType {
    AM4 = 'AM4', AMWEB = 'AMWEB'
}


export interface AlarmMonitorAddressbookResponse {
    alarmMonitors: AlarmMonitor[],
    totalElements: number,
    licenced: number,
    inUse: number,
    licencedPercentage: number,
    remaining: number,
    totalPages: number
}