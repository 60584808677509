"use strict";

import { IScope } from "angular";
import { SimpleUnit, SplitterData } from "../../../../data/admin.data";
import { Unit } from "../../../../data/unit.data";

require("./edit.splitter.modal.css")

/* @ngInject */
export default class EditAlarmSplitterModalController {

    public unitListFor: SimpleUnit[];
    public unitListFrom: SimpleUnit[];
    public unitListTo: SimpleUnit[];

    constructor(private $uibModalInstance: any,
        private $scope: IScope,
        public units: any,
        public splitter: SplitterData,
        public isNew: boolean // to show a different ui for add and edit
    ) {

        this.buildSelectionLists();
    }

    private buildSelectionLists() {
        this.unitListFor = (Object.values(this.units) as SimpleUnit[])
            .sort(this.compareUnits);
        this.unitListFrom = this.unitListFor
            .filter(unit => unit.code !== this.splitter.codeFor)
            .sort(this.compareUnits);
        this.unitListTo = this.unitListFrom
            .filter(unit => unit.code !== this.splitter.codeFrom)
            .sort(this.compareUnits);
    }

    compareUnits(unitA: SimpleUnit, unitB: SimpleUnit): number {
        if (unitA.parentName !== unitB.parentName) {
            return unitA.parentName.localeCompare(unitB.parentName);
        }
        return unitA.name.localeCompare(unitB.name);
    }

    add(): void {
        this.$uibModalInstance.close(this.splitter)
    }

    cancel() {
        this.$uibModalInstance.close(false);
    }

    getUnitNameWithParent(unitId: string): string {
        let unit: SimpleUnit = this.units[unitId];
        return `${unit.name} (${unit.parentName})`;
    }

    forExplanation(): any {
        return {
            for: this.getUnitNameWithParent(this.splitter.codeFor),
            from: this.getUnitNameWithParent(this.splitter.codeFrom),
            to: this.getUnitNameWithParent(this.splitter.codeTo)
        }
    }

    selectFor(unit: SimpleUnit) {
        this.splitter.codeFor = unit.code;
        this.buildSelectionLists();
        if(this.splitter.codeFrom === unit.code) {
            this.selectFrom(this.unitListFrom[0]);
            return;
        }
        if(this.splitter.codeTo === unit.code) {
            this.selectTo(this.unitListTo[0]);
            return;
        }
        this.$scope.$apply();
    }
    
    selectFrom(unit: SimpleUnit) {
        this.splitter.codeFrom = unit.code;
        this.buildSelectionLists();
        if(this.splitter.codeTo === unit.code) {
            this.splitter.codeTo = this.unitListTo[0].code;
        }
        this.$scope.$apply();
    }

    selectTo(unit: SimpleUnit) {
        this.splitter.codeTo = unit.code;
        this.$scope.$apply();
    }

}