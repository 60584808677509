'use strict';
import { CreateCustomFeedbackEntryRequest, CreateCustomFeedbackResponseRequest, CustomFeedbackEntryResponse, CustomFeedbackResponseResponse } from "../data/customFeedback.data";
import RestService from "./rest.service";

export default class UnitsService {

  private restService: RestService;

  constructor(restService: RestService) {
    this.restService = restService;
  }

  getCustomFeedbacks(): Promise<Array<CustomFeedbackResponseResponse>> {
    return this.restService.getCustomFeedbacks();
  }

  createCustomFeedback(data: CreateCustomFeedbackResponseRequest): Promise<CustomFeedbackResponseResponse> {
    return this.restService.createCustomFeedback(data);
  }

  updateCustomFeedback(data: CustomFeedbackResponseResponse): Promise<CustomFeedbackResponseResponse> {
    return this.restService.updateCustomFeedback(data);
  }

  deleteCustomFeedback(data: CustomFeedbackResponseResponse): Promise<any> {
    return this.restService.deleteCustomFeedback(data);
  }

  getCustomFeedbackEntries(data: CustomFeedbackResponseResponse): Promise<Array<CustomFeedbackEntryResponse>> {
    return this.restService.getCustomFeedbackEntries(data);
  }

  createCustomFeedbackEntry(data: CreateCustomFeedbackEntryRequest): Promise<CustomFeedbackEntryResponse> {
    return this.restService.createCustomFeedbackEntry(data);
  }

  updateCustomFeedbackEntry(data: CustomFeedbackEntryResponse): Promise<CustomFeedbackEntryResponse> {
    return this.restService.updateCustomFeedbackEntry(data);
  }

  deleteCustomFeedbackEntry(data: CustomFeedbackEntryResponse): Promise<any> {
    return this.restService.deleteCustomFeedbackEntry(data);
  }
}