'use strict';

import { IScope } from "angular";
import { DeactivatedPluginListEntry, OutputPluginSettings } from "../../../../../data/admin.data";
import HelperService from "../../../../../services/helper.service";
import RestService from "../../../../../services/rest.service";

require('./admin.outputplugins.component.css');

export default class AdminOutputPluginSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.outputplugins.component.html');
    this.scope = {
    };
    this.controller = AdminOutputPluginComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AdminOutputPluginComponentController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private settings: OutputPluginSettings;
  public helperService: HelperService;

  constructor($scope: IScope, restService: RestService, helperService: HelperService) {
    this.restService = restService;
    this.$scope = $scope;
    this.helperService = helperService;
    this.load();
  }


  /**
   * Load map settings
   */
  load() {
    this.isLoading = true;
    this.restService.loadOutputPluginSettings().then(settings => {
      this.settings = settings;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  changeStateForPlugin(plugin: DeactivatedPluginListEntry) {
    const newStatus = !plugin.deactivated;
    this.isLoading = true;
    this.restService.setOutputPluginStatus(plugin, newStatus).then(() => {
      plugin.deactivated = newStatus;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  getIcon(plugin: DeactivatedPluginListEntry) {
    return this.helperService.getIconForPluginname(plugin.className);
  }
}
