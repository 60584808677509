import {AlarmObjectExamination, ExaminationValidState} from "./examination.data";

export interface Coords {
    lat: number;
    lng: number;
    showOnMap: boolean;
}

export interface Address {
    street: string;
    house: string;
    postalCode: string;
    city: string;
    abbreviation: string;
    drive: string;
    coords: Coords;
    accessGates: any[];
    accessGatesAsString: string;
    overwriteCoordinates: boolean;
}

export interface Hint {
    type: string;
    note: string;
    synced: boolean;
    stagingAreaType?: string;
}

export interface Contact {
    type: string;
    name: string;
    contact: string;
    id: string;
    company: string;
    note: string;
    email: string;
    formOfAddress: string;
    title: string;
    gender: string;
    birthDate: string;
}

export interface Substance {
    location: string;
    note: string;
    risk: string;
    quantity: string;
    un: number;
    riskClass: string;
    synced: boolean;
}

export interface Risks {
    objects: any[];
    barriers: any[];
    persons: any[];
    substances: Substance[];
}

export interface Permissions {
    alarmObjectPermission: string;
    writeReasons: boolean;
    writeNotes: boolean;
    writeChanges: boolean;
}

export interface Link {
    id: string;
    objectId: string;
    externalId: string;
    source: string;
    system: string;
    lastSynced: Date;
}

export interface AlarmObject {
    id: string;
    name: string;
    ttsName: string;
    ident: string;
    additionalIdent: string;
    hint: string;
    type: string;
    level: string;
    state: string;
    address: Address;
    bma?: any;
    hints: Hint[];
    events: any[];
    contacts: Contact[];
    radios: any[];
    risks: Risks;
    documents: any[];
    buildings: any[];
    notes: any[];
    subType: string;
    subObjects: any[];
    feedbacks: any[];
    parent: string;
    usersWithAccess: any[];
    version: string;
    ilsHint?: any;
    importantNotice: string;
    lastChanged: number;
    created: number;
    permissions: Permissions;
    paths: any[];
    links: Link[];
    hasLink: boolean;
    hasDirectionCards: boolean;
    additionalDetails: AdditionalDetail[];
    revision: boolean;
    examination: AlarmObjectExamination;
    hasCuratorAndMailAccount: boolean;
    homeEmergency: HomeEmergency;
    lockingSystem: LockingSystem[];
    patientManagement: boolean;
    rescueServices: RescueServices;
    hospitalServices: HospitalServices;
    shareOnAlarm: boolean;
    hasBMA: boolean;
    zoomLevel: number;
    emergencyPower: EmergencyPower;
}

export interface RescueServices {
    normalPatientCapacity: PatientCapacity;
    manvPatientCapacity: PatientCapacity;
}

export interface PatientCapacity {
    sk1: number;
    sk2: number;
    sk3: number;
    burned: number;
    children: number;
    sht: number;
}

export interface HospitalServices {
    diagnosticList: HospitalDiagnostic[];
    hospitalServices: HospitalService[];
    specialServiceList: HospitalSpecialService[];
}

export interface HospitalService {
    has: boolean;
    service: EHospitalServices;
}

export interface HospitalDiagnostic {
    has: boolean;
    diagnostic: EHospitalDiagnostic;
}

export interface HospitalSpecialService {
    has: boolean;
    service: EHospitalSpecialServices;
}

enum EHospitalSpecialServices {
    BG = "BG"
}
export enum EHospitalServices {
    INT = "INT",
    CHIR = "CHIR",
    UNF_CHIR = "UNF_CHIR",
    NEURO = "NEURO",
    NEURO_CHIR = "NEURO_CHIR",
    HNO = "HNO",
    AUGEN = "AUGEN",
    PSYCH = "PSYCH",
    URO = "URO",
    GYN = "GYN",
    GEBURT = "GEBURT",
    KIND = "KIND",
    MKG = "MKG",
    HG = "HG",
    ANS = "ANS",
    PSYM = "PSYM",
    VERB = "VERB",
    NOT = "NOT",
    INTER = "INTER",
    GER = "GER",
    AUFGABEN = "AUFGABEN",
    NUKLEAR = "NUKLEAR",
    HYPER = "HYPER",
    PALLIATIV = "PALLIATIV",
    LANDESTELLE = "LANDESTELLE",
    HOSPIZ = "HOSPIZ"
}

enum EHospitalDiagnostic {
    MRT = "MRT",
    CT = "CT",
    HKL = "HKL",
    ENDO = "ENDO",
    PRESSURE = "PRESSURE"
}



export interface HomeEmergency {
    number: string;
    storage: string;
}

export interface LockingSystem {
    type: LockingSystemType;
    hint: string;
    placeOfInstallation: string;
    serialNumber: string;
    coordinates: Coords;
}

export enum LockingSystemType {
    CYLINDER = 'CYLINDER',
    ELECTRICAL_CYLINDER = 'ELECTRICAL_CYLINDER',
    PIN_KEYBOARD = 'PIN_KEYBOARD',
    FINGERPRINT = 'FINGERPRINT',
    KEY_SAFE = 'KEY_SAFE',
    PIN_SAFE = 'PIN_SAFE'
}

export interface AdditionalDetail {
    key: string,
    value: string;
}

export interface AlarmObjectChange {
    id: string;
    parent: string;
    user: string;
    changes: string;
    timestamp: number;
}

export interface SimpleAlarmObject {
    parent: string;
    ident: string;
    street: string;
    house: string;
    id: string;
    name: string;
    city: string;
}

export interface AssignAlarmObject {
    userId: string;
    alarmObjectId: string;
    addAllObjects: boolean;
}

export interface AlarmObjectSearchResult {
    currentPage: number;
    hasNext: boolean;
    hasPrevious: boolean;
    data: SimpleAlarmObject[];
}

export interface AlarmObjectsSimpleResponse {
    alarmObjects: AlarmObjectTableRowEntry[];
    hasNext: boolean;
    hasPrevious: boolean;
    currentPage: number;
    addAllowed: boolean;
}

export interface AlarmObjectsSearchResult {
    building: string;
    id: string;
}

export interface AlarmObjectTableRowEntry {
  id: string;
  name: string;
  level: string;
  state: string;
  ident:string;
  type: string;
  color: string;
  fontawesomeIcon: string;
  address: Address;
  parent: string;
  changeRequests: number;
  hasLink: boolean;
  examinationState: ExaminationValidState;
  revision: boolean;
    nbrOfNewFeedback: number;
    fromIvena: boolean;
}

export interface ObjectDocumentTagsResponse {
  globalTags: DocumentTag[];
  tags: DocumentTag[];
}

export interface ObjectDocumentTagsResponse {
  globalTags: DocumentTag[];
  tags: DocumentTag[];
}

export interface ObjectDocumentsPageResponse {
  globalDocuments: ObjectDocument[];
  documents: ObjectDocumentsPage;
}

export interface ObjectDocumentsPage {
  content: ObjectDocument[];
  totalElements: number;
  totalPages: number;
  number: number;
}

export class ObjectDocument {
  id: string;
  fileName: string;
  userId: string;
  type: EDocumentType;
  lastUpdateTimestamp: Date;
  creationTimestamp: Date;
  ser:boolean;
  tagIds: DocumentTag[];
}

export class DocumentTag {
  id: string;
  label: string;
  color: string;
}

export enum EDocumentType {
  PDF = 'PDF', IMG = 'IMG'
}

export interface EmergencyPower {
    hasPowerInput: boolean;
    hasOwnOperatingFluids: boolean;
    amountOfOperatingFluids: string;
}
