import {IScope, IRootScopeService, ILogService, IHttpService} from "angular";
import RestService from "../../../../services/rest.service";
import ErrorService from "../../../../services/error.service";
require('./aao.import.modal.scss')
export default class AAOImportModalController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $uibModalInstance: any;
  public $log: ILogService;
  public $http: IHttpService;
  public restService: RestService;
  public errorService: ErrorService;
  public hasExistingAAOs:boolean;
  public uploader: any;
  public url: string;
  public isImporting = false;
  public deleteOldAAO:boolean = false;

  constructor($scope: angular.IScope, $rootScope: angular.IRootScopeService, $uibModalInstance: any, $log: angular.ILogService, $http: angular.IHttpService, restService: RestService, errorService: ErrorService, hasExistingAAOs:boolean, uploader, url: string) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.$http = $http;
    this.restService = restService;
    this.errorService = errorService;
    this.uploader = uploader;
    this.url = url;
    this.hasExistingAAOs = hasExistingAAOs;

    this.uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };
    this.uploader.onBeforeUploadItem = (item) => {
      this.$log.info('Changing URL before uploading...');
      // Update URL for upload
      item.url = this.restService.getBaseUrl() + url+'?deleteAao=' + this.deleteOldAAO;

    };


    this.uploader.onCompleteAll = () => {
      this.$log.info('onCompleteAll');
      this.$uibModalInstance.close();
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.$log.info('OnSuccessItem: ' + status);
    };
  }



  import() {

    this.isImporting = true;
    this.uploader.onErrorItem = (item, response, status) => {
      this.errorService.notifyWithText(response.message);
      this.$uibModalInstance.close();

    };

    this.uploader.uploadAll();
  };

  cancel() {
    this.$uibModalInstance.close();
  }
}