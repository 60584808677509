'use strict';

/* @ngInject */
export default class TestService {

    test: HelloWorld;
    $rootScope: angular.IRootScopeService;

    constructor($rootScope: angular.IRootScopeService) {
        this.test = {
            name: 'works'
        } as HelloWorld;

        this.$rootScope = $rootScope;

    }

    sayHello(say: string): void {
        this.$rootScope.$emit('blub', this.test);
    }

}

export interface HelloWorld {
    name: string;
}