export interface VehicleGeoTrackingStateResponse{
  vehiclePositionProcessingState: EVehiclePositionProcessingState;
  requestTime: number
}

export enum EVehiclePositionProcessingState {
  RUNNING='RUNNING',
  STOPPED= 'STOPPED',
  ERROR='ERROR'
}

export interface InputTrackingToggle {
  inputClassSimpleName: string,
  translation: string,
  trackingActive: boolean
}