'use strict';

import { ILogService, IRootScopeService } from "angular";
import { EFE2Features, UserAccount } from "../data/account.data";

/* @ngInject */
export default class AccountService {

  private account: UserAccount;

  constructor(private dataService: any, 
    $rootScope: IRootScopeService) {

    this.dataService = dataService;
    this.init();

    $rootScope.$on('new.account', () => {
      // Init controller, because we have a new account
      this.init();
    });
  }

  private init() {
    if (this.dataService.hasAccount()) {
      this.account = this.dataService.getAccount();
    }
  }

  public isFeatureEnabled(feature: EFE2Features): boolean {
    //try re-init if account empty
    if (!this.account) {
        return false;
    }

    return this.account.features.indexOf(feature) !== -1;
  }

}
