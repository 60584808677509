'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import angular = require("angular");
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

//imageAssetRow
export default class ImageAssetRowComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./image.asset.row.component.html');
    this.scope = {
      asset: '=',
      base: '=',
      auth: '=',
      uploader: '='
    };
    this.controller = ImageAssetRowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ImageAssetRowComponentController {
  public $log: ILogService;
  public $scope: IScope;
  public dataService: any;
  public restService: RestService;
  public errorService: any;
  public imageUrl: string;
  public base: any;
  public asset: any;
  public auth: any;
  public isDeleting = false;
  public isUploading = false;
  public uploader: any;

  constructor($scope: IScope, $log: ILogService, restService: RestService, dataService, errorService, public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$scope = $scope;
    this.dataService = dataService;
    this.restService = restService;
    this.errorService = errorService;

    this.$scope.$watch('ctrl.asset', () => {
      // Type change
      this.imageUrl = this.base + '/objects/icons/' + this.asset.id + '?Authorization=' + this.auth;
    });
  }


  /**
   * Delete image asset
   */
  delete() {
    this.isDeleting = true;
    this.dataService.deleteImageAsset(this.asset, () => {
      this.isDeleting = false;
    }, () => {
      this.isDeleting = false;
    });
  };

  /**
   * Update image
   */
  updateImageAsset() {
    this.$log.info('Queue: ' + this.uploader.queue.length);
    if (this.uploader.queue.length === 0) {
      return;
    }
    this.isUploading = true;

    this.uploader.onBeforeUploadItem = (item) => {
      //Change upload path
      item.url = item.url + '/' + this.asset.id;
    };

    //Success
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.$log.info('Upload completed: ' + status);
      this.$log.info(response);
      angular.copy(response, this.asset);
      this.isUploading = false;
      // Add timestamp to force reload
      this.imageUrl = this.base + '/objects/icons/' + this.asset.id + '?Authorization=' + this.auth + '&dummy=' + new Date().getTime();
    };
    //Error
    this.uploader.onErrorItem = (item, response, status) => {
      this.$log.error('Upload failed: ' + status);
      this.$log.error(response);
      this.isUploading = false;
      this.errorService.notifyWithText(response.message);
    };
    this.uploader.uploadAll();
  };


}